import { Datum } from "plotly.js";
import Plot from "react-plotly.js";

type PlotlyScatterPlotProps = {
  title?: string;
  x?: Datum[];
  y?: number[];
  height?: number;
};

const PlotlyScatterPlot = ({
  title = "Title",
  x = [],
  y = [],
  height,
}: PlotlyScatterPlotProps) => {
  return (
    <Plot
      data={[
        {
          type: "scatter",
          mode: "text+lines+markers",
          y,
          x,
          marker: {
            symbol: "square",
            size: 10,
          },
          text: y.map((val) => `${(val * 100).toFixed(2)}%`),
          textposition: "top center",
        },
      ]}
      layout={{
        height,
        title: {
          text: title,
          font: {
            family: "PP Neue Machina",
            size: 16,
          },
        },
        xaxis: {
          showgrid: false,
        },
        yaxis: {
          showticklabels: false,
        },
        font: {
          family: "Motiva Sans",
        },
      }}
      config={{
        displaylogo: false, // Remove the Plotly logo
        responsive: true,
      }}
    />
  );
};

export default PlotlyScatterPlot;
