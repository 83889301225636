import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Alert, ConfigProvider, theme } from "antd";
import Location from "./utils/location";
import Home from "./containers/Home";
import LoginPage from "./containers/LoginPage";
import LoginSuccess from "./services/LoginSuccess";
import { initialExtraMenu, initialMainMenu } from "./services/MainMenu";
import { setContainer } from "./utils/containers";
import Emitter from "./utils/emitter";
import { socket } from "./utils/socket";
import NoAccess from "./containers/NoAccess";
import { permissions_access, accessToken } from "./utils/_exports";
import ProtectedRoute from "../src/services/ProtectedRoute";
export const Mode = `${process.env.REACT_APP_MODE}`;
export let Tenant = `elemental`;

const { defaultAlgorithm, darkAlgorithm } = theme;

if (localStorage.getItem("theme")) {
  const theme = localStorage.getItem("theme");
  if (theme == "dark") {
    document.body.classList.toggle("dark-mode", true);
  } else if (theme == "light") {
    document.body.classList.toggle("dark-mode", false);
  }
} else {
  localStorage.setItem("theme", Mode);
  if (Mode == "dark") {
    document.body.classList.toggle("dark-mode", true);
  } else if (Mode == "light") {
    document.body.classList.toggle("dark-mode", false);
  }
}

let tokenCheck: any = null;
const restricted = ["/", "loginsuccess", "loginfailed", "logout"];
const App = () => {
  //console.log("Check");
  let isLogin = false;
  let shouldRedirect = false;
  let currentDate: any = Date.now() / 1000;
  let timeout: any = null;

  const restricted = [
    "",
    "/",
    "/loginsuccess",
    "/loginsuccess/",
    "/loginfailed/",
    "/loginfailed",
    "/logout",
    "/logout/",
  ];

  const [user, setUser] = useState<any>(null);
  const [userRole, setUserRole] = useState<any>(null);
  const [menu, setMenu] = useState<[]>([]);
  const [menuRoutes, setMenuRoutes] = useState<[]>([]);
  const [adminMenuRoutes, setAdminMenuRoutes] = useState<[]>([]);
  const [currentLocation, setCurrentLocation] = useState<any>(
    window.location.pathname
  );

  const [alertmsg, setAlert] = useState<any>(null);

  // This is to define the current url location of the user
  // This is mostly use to define
  const onLocationChange = (location: Location) => {
    setCurrentLocation(location.pathname);
  };

  useEffect(() => {
    setUser({
      fullname_nric: "admin",
      modified_on: 1722308610411,
    });
    setUserRole("admin");
    socketController();
  }, []);

  if (accessToken) {
    isLogin = true;
  }

  // Emitter controller - Control events within the app itself
  const emitterController = () => {
    Emitter.on("alert", (payload: any) => {
      if (payload) {
        if (payload.timeout) {
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
          timeout = setTimeout(() => {
            setAlert(null);
          }, payload.timeout);
        }
        setAlert({
          type: payload.type,
          message: payload.message,
          description: payload.description,
          top: payload.top,
          closeable: payload.closable,
        });
      } else {
        setAlert(null);
      }
    });
  };

  useEffect(() => {
    // Optional: Listen to the connect event
    socket.on("connect", () => {
      socket.emit("custom_event", `Hello from React frontend! ${socket.id}`);
      // console.log("Connected with socket ID:", socket.id);
    });
  }, []);

  // Socket Controller - control events with the back-end server and will conduct all socket io messages
  const socketController = () => {
    const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
    // const socketIDToken: any = localStorage.getItem(`${Tenant}:idToken`);
    socket.on("connect", () => {
      socket.emit("custom_event", `Hello from React frontend! ${socket.id}`);
    });
    socket.on("connect_error", (err: any) => {
      // the reason of the error, for example "xhr poll error"
      console.log(err.message);

      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);

      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    });
  };

  useEffect(() => {
    emitterController();
  }, [currentLocation]);

  const loadMenu = () => {
    if (userRole) {
      const mainMenu: any = initialMainMenu;
      const visibleMenu = mainMenu.filter((item: any) =>
        permissions_access.includes(item.permissions)
      );
      const extraMenu: any = initialExtraMenu;
      let allMenu: any = [...visibleMenu, ...extraMenu];

      const menuRoutes: any = [];
      const adminMenuRoutes: any = [];
      let params: any = { user, userRole };
      const traverse = (menuItems: any, parentPath: any = "") => {
        menuItems.forEach((item: any) => {
          let route = null;
          if (item.to) {
            route = (
              <Route
                key={item.key}
                path={item.to.split("/").pop()}
                element={setContainer(
                  item.container,
                  item.propTitle,
                  item.key,
                  { ...params, ...item, userRole },
                  userRole,
                  user
                )}
              />
            );

            menuRoutes.push({
              item: item,
              route: route,
            });
          }
          if (item.children && Array.isArray(item.children)) {
            traverse(item.children, parentPath + (item.to || ""));
          }
        });
      };
      traverse(allMenu);
      menuRoutes.push({
        item: null,
        route: <Route path="*" element={<NoAccess />} />,
      });
      adminMenuRoutes.push({
        item: null,
        route: <Route path="*" element={<NoAccess />} />,
      });
      setMenuRoutes(menuRoutes);
      setAdminMenuRoutes(adminMenuRoutes);
    }
  };

  // Load Menus and populate routes
  useEffect(() => {
    loadMenu();
  }, [userRole, user]);

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        algorithm: defaultAlgorithm,
        token: { fontFamily: "Motiva Sans", fontSize: 14 },
      }}
    >
      <div className="main-page">
        <Router>
          <Location onChange={onLocationChange}></Location>
          {shouldRedirect ? <Navigate to="" /> : <></>}
          <Routes>
            <Route path="" element={<LoginPage />} />
            <Route path="loginsuccess" element={<LoginSuccess />} />

            <Route
              path=""
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <Home
                    userInfo={{ user, userRole }}
                    currentLocation={currentLocation}
                    menu={[...menuRoutes, ...adminMenuRoutes]}
                  />
                </ProtectedRoute>
              }
            >
              {menuRoutes.map((menuItem: any) => {
                return menuItem.route;
              })}
              {adminMenuRoutes.map((menuItem: any) => {
                return menuItem.route;
              })}
            </Route>
          </Routes>
        </Router>
        {alertmsg && (
          <Alert
            className={
              alertmsg?.top ? "alert-message-box-top" : "alert-message-box"
            }
            type={alertmsg?.type}
            message={alertmsg?.message}
            description={alertmsg?.description}
            showIcon
            closable={alertmsg?.closable || false}
            afterClose={() => setAlert(null)}
            style={{
              fontFamily: "Motiva Sans",
            }}
          />
        )}
      </div>
    </ConfigProvider>
  );
};

export default App;
