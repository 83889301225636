import React, { useEffect } from "react";
import Cookies from "js-cookie";

const LoginSuccess = (props: any) => {
  const redirect = () => {
    if (Cookies.get("accessToken_en")) {
      window.location.href = `/dashboard`;
    } else {
      window.location.href = `/`;
    }
  };

  useEffect(() => {
    redirect();
  }, []);

  return <></>;
};

export default LoginSuccess;
