import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Tabs,
  Tag,
  Dropdown,
  Modal,
  Form,
  Spin,
  Select,
  Progress,
  Tooltip,
  Popconfirm,
  Alert,
} from "antd";
import Cookies from "js-cookie";
import TextArea from "antd/es/input/TextArea";
import { useForm, useWatch } from "antd/es/form/Form";

import type { ColumnsType } from "antd/es/table";
import { getColumnList, getLookupItem } from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { useNavigate } from "react-router";
import {
  ConsoleSqlOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { uploadFile, downloadFile } from "../services/api-server/filetransfer";
import { daysCalculation, getAlertStyle } from "../utils/utils";
import { getLoanListByField } from "../services/api-server/loan";
import {
  generateLoanReport,
  deleteLoanItem,
} from "../services/api-server/loan";
import {
  updateExcelItem,
  deleteExcelItem,
} from "../services/api-server/excel_file";
import moment from "moment";
import { sendEmail } from "../services/api-server/usertoken";
import { socket } from "../utils/socket";
import { isMobile } from "react-device-detect";
import CustomForm from "../components/CustomForm";
import dayjs from "dayjs";
import { validateRoute } from "../services/api-server/login";
import {
  permissions_access,
  company_access,
  branch_access,
} from "../utils/_exports";

const LookupManager = (props: any) => {
  const [alertmsg, setAlertmsg] = useState<any>(null);
  const context: any = useContext(HomeContext);
  const navigate: any = useNavigate();
  const { Option } = Select;
  const [formRef]: any = useForm();
  const [tabKey, setTabKey] = useState<any>(props.tabKey);
  const [subTabKey, setSubTabKey] = useState<any>(props.subTabKey || null);
  const containerTitle = props?.containerTitle;
  const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dbData, setDbData] = useState<any>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedExcel, setSelectedExcels] = useState([]);
  const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
  const [formReset, setFormReset] = useState<boolean>(true);
  const [initialForm, setInitialForm] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [sideContainerOpen, setSideContainerOpen] = useState<any>(
    props.sideContainerOpen || false
  );
  const [activeRowKey, setActiveRowKey] = useState<any>(
    props.activeRowKey || null
  );
  const [activeRecord, setActiveRecord] = useState<any>(
    props.activeRecord || null
  );
  const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
  const [holidayData, setHolidayData] = useState<any>(
    props.holidayData || null
  );
  const [workdayData, setWorkdayData] = useState<any>(
    props.workdayData || null
  );
  const [reportungroupcompanyData, setReportUngroupCompanyData] = useState<any>(
    []
  );
  const [reportcompanyData, setReportCompanyData] = useState<any>([]);
  const [reportproductData, setReportProductData] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);
  const [addButtonProp, setAddButtonProp] = useState<any>(null);
  const [searchRecord, setSearchRecord] = useState<any>([]);
  const [tempRecord, setTempRecord] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState<any>(null);
  const [ready, setReady] = useState<any>(false);
  const [resetSearch, setResetSearch] = useState<any>(false);
  const [generatereportform] = Form.useForm();

  const [activeGroupKey, setActiveGroupKey] = useState<any>(null);

  const [generate_reports_modal_visible, setGenerateReportsModalVisible] =
    useState(false);
  const [delete_excel_modal_visible, setDeleteExcelModalVisible] =
    useState(false);

  const currentDate = new Date();
  const hasPermission: boolean = permissions_access?.includes(
    "report upload raw data"
  )
    ? true
    : false;

  useEffect(() => {
    if (searchRecord && tempRecord) {
      let recordIndex = searchRecord.findIndex((item: any) => {
        return item.columnKey === tempRecord.columnKey;
      });
      if (recordIndex !== -1) {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record[recordIndex] = tempRecord;
          setSearchRecord(_record);
        } else {
          let _record = [...searchRecord];
          _record.splice(recordIndex, 1);
          setSearchRecord(_record);
        }
      } else {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record.push(tempRecord);
          setSearchRecord(_record);
        }
      }
    }
  }, [tempRecord]);

  useEffect(() => {
    const onLogout = () => {
      setAlertmsg({
        type: "warning",
        message: "Login session expired, please login again",
        top: true,
        closeable: true,
      });

      setTimeout(() => {
        removeAllCookies();
        // First, navigate to a temporary route (or directly to login)
        navigate(`/`, { replace: true });
      }, 3000);
    };

    // Listen for the logout event
    Emitter.on("logout", onLogout);

    // Cleanup function to remove the listener on component unmount
    return () => {
      Emitter.off("logout", onLogout);
    };
  }, []); // Empty dependency array means this runs once on mount
  const removeAllCookies = (): void => {
    // Get all cookies
    const allCookies = Cookies.get();

    // Iterate over all cookies and remove each one
    for (const cookieName in allCookies) {
      Cookies.remove(cookieName);
    }
  };

  useEffect(() => {
    let companygroupby = [
      "company_name",
      "branch_name",
      "report_date",
      "companyid",
      "branchid",
      "sub_acct_type",
    ];
    let companytarget: { [key: string]: string } = {};
    companytarget["groupby"] = companygroupby.join(",");

    getLoanListByField(companytarget)
      .then((data: any) => {
        const reformattedData = data
          .map((item: any) => ({
            company_name: item._id.company_name,
            branch_name: item._id.branch_name,
            sub_acct_type: item._id.sub_acct_type,
            report_date: item._id.report_date,
            companyid: item._id.companyid,
            branchid: item._id.branchid,
          }))
          .filter(
            (item: any) =>
              company_access.includes(item.companyid) &&
              branch_access.includes(item.branchid)
          );
        setReportUngroupCompanyData(reformattedData);
        const groupedData: { key: string } = reformattedData.reduce(
          (acc: any, entry: any) => {
            const date = entry.report_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
          },
          {} as { key: string }
        );
        setReportCompanyData(groupedData);
      })
      .catch((error: any) => console.log(error));

    let productgroupby = [
      "report_date",
      "companyid",
      "branchid",
      "sub_acct_type",
    ];
    let producttarget: { [key: string]: string } = {};
    producttarget["groupby"] = productgroupby.join(",");

    getLoanListByField(producttarget)
      .then((data: any) => {
        const reformattedData = data
          .map((item: any) => ({
            sub_acct_type: item._id.sub_acct_type,
            report_date: item._id.report_date,
            companyid: item._id.companyid,
            branchid: item._id.branchid,
          }))
          .filter(
            (item: any) =>
              company_access.includes(item.companyid) &&
              branch_access.includes(item.branchid)
          );

        const groupedData: { key: string } = reformattedData.reduce(
          (acc: any, entry: any) => {
            const date = entry.report_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
          },
          {} as { key: string }
        );

        setReportProductData(groupedData);
      })
      .catch((error: any) => console.log(error));
    // setLoading(false);
  }, []);

  useEffect(() => {
    refresh_excel_file();
  }, [searchRecord, resetSearch]);

  // Reset form
  const resetForm = (values: any = null, data: any = null) => {
    setFormReset(true);
    formRef.resetFields();
    setGenerateReportsModalVisible(false);
  };

  const handleSearch = (record: any) => {
    setTempRecord(record);
  };

  const calculateTableHeight = () => {
    const table: any = document.getElementById("table-container");
    if (table) {
      setTableHeight(table?.clientHeight - 65 - 64);
    }
  };

  const extractDateFromFilename = (filename: any) => {
    const match = filename.match(/\[(\d{4}-\d{2})\]/);
    return match ? match[1] : null; // Return the [YYYY-MM] format if found, otherwise null
  };

  const handleGenerateReportsModalCreate = () => {
    const matched_companyids: any = [];
    const matched_branchids: any = [];
    formRef
      .validateFields()
      .then((values: any) => {
        let report_generated_datetime = moment().toISOString();
        const formattedDate = dayjs(
          formRef.getFieldValue("report_date")
        ).format("MM-YYYY");
        const selectedCompany = formRef.getFieldValue("company_name");
        let filteredSelectedCompany = selectedCompany.filter(
          (company: any) =>
            company_access.includes(company) || branch_access.includes(company)
        );
        if (Array.isArray(selectedCompany) && selectedCompany.includes("all")) {
          reportungroupcompanyData.forEach((record: any) => {
            if (company_access.includes(record.companyid)) {
              matched_companyids.push(record.companyid);
            } else if (branch_access.includes(record.branchid)) {
              matched_branchids.push(record.branchid);
            }
          });
        } else {
          reportungroupcompanyData.forEach((record: any) => {
            if (filteredSelectedCompany.includes(record.companyid)) {
              matched_companyids.push(record.companyid);
            } else if (filteredSelectedCompany.includes(record.branchid)) {
              matched_branchids.push(record.branchid);
            }
          });
        }

        const unique_companyids = Array.from(
          new Map(matched_companyids.map((id: any) => [id, id])).values()
        );
        const unique_branchids = Array.from(
          new Map(matched_branchids.map((id: any) => [id, id])).values()
        );
        const payload = {
          ...formRef.getFieldsValue(),
          report_generated_date: report_generated_datetime,
          companyid: unique_companyids,
          branchid: unique_branchids,
        };
        // setLoading(true);
        setUploadPercent(0);
        setIsModalVisible(true);
        const fakeUpload = setInterval(() => {
          setUploadPercent((prev) => {
            const newPercent = prev + 1;
            if (newPercent >= 100) {
              clearInterval(fakeUpload);
            }
            return newPercent;
          });
        }, 3000); // Simulate upload progress
        generateLoanReport(formattedDate, payload)
          .then((data: any) => {
            // console.log(data);
            Emitter.emit("alert", {
              type: "success",
              message: "Excel has been generated.",
              description: "",
              top: true,
              closeable: false,
              timeout: 3000,
            });
            const updatedData: any = [
              {
                filter: {
                  _id: {
                    $in: dataTable
                      .filter(
                        (item: any) =>
                          moment(item.grouped_date).format("MM-YYYY") ===
                          formattedDate
                      )
                      .map((item: any) => item._id),
                  },
                },
                data: {
                  report_generated_date: report_generated_datetime,
                },
              },
            ];
            // console.log("updatedData", updatedData);
            updateExcelItem(updatedData[0])
              .then((data: any) => {
                socket.emit(
                  "excel_datatable_update",
                  `Hello from React frontend! ${socket.id}`
                );

                setUploadPercent(100); // Upload complete
                setTimeout(() => {
                  setIsModalVisible(false);
                }, 1000); // Delay for 5 seconds before hiding the modal
              })
              .catch((e: any) => {
                //console.log("error", e);
                // setLoading(false);
                setUploadPercent(100); // Upload complete
                // setGenerateReportsModalVisible(false);
                setTimeout(() => {
                  setIsModalVisible(false);
                }, 1000); // Delay for 5 seconds before hiding the modal
              })
              .finally(() => {
                // setLoading(false);
                setUploadPercent(100); // Upload complete
                setTimeout(() => {
                  setIsModalVisible(false);
                  resetForm();
                }, 1000); // Delay for 5 seconds before hiding the modal
                // setGenerateReportsModalVisible(false);
              });
          })
          .catch((errorInfo: any) => {
            console.log("Validation failed:", errorInfo);
          });
      })
      .catch((errorInfo: any) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleDeleteExcelConfirm = () => {
    if (selectedRowKeys.length > 0) {
      const uuidValue = `in.(${selectedRowKeys.join(",")})`;
      let deleted_excel_id = {
        excel_id: uuidValue,
      };
      setResetSearch(true);
      deleteExcelItem(deleted_excel_id)
        .then((data: any) => {
          if (data !== null) {
            Emitter.emit("alert", {
              type: "success",
              message: `${selectedRowKeys.length} file(s) has been successfully deleted.`,
              description: "",
              top: true,
              closeable: false,
              timeout: 3000,
            });
            socket.emit(
              "excel_datatable_update",
              `Hello from React frontend! ${socket.id}`
            );

            // setDeleteExcelModalVisible(false);
            setSelectedRowKeys([]);
          }
        })
        .catch((e: any) => {
          //console.log("error", e);
        });
    } else {
      Emitter.emit("alert", {
        type: "error",
        message: "There are no excel selected!",
        description: "",
        top: true,
        closeable: false,
        timeout: 3000,
      });
    }
  };

  const setSideContainer = () => {
    if (sideContainerOpen) {
      return (
        <div
          className="lookup-side-container"
          style={{
            width: isMobile ? "100%" : "35%",
            maxHeight: "100%",
            // margin: "10px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRadius: "3px",
          }}
        >
          <div className="lookup-side-header" style={{ height: "50px" }}>
            <span
              style={{
                marginRight: "auto",
                minHeight: "32px",
                display: "flex",
                alignItems: "center",
                color: "#ffffff",
              }}
            >
              Leave details
            </span>
            <button
              onClick={() => {
                setSideContainerOpen(false);
                setActiveRowKey(null);
                setActiveGroupKey(null);
              }}
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "15px",
                // fontWeight: "600",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
          <div
            className="generic-content"
            style={{
              flex: "1",
              gap: "0px",
              maxHeight: "100%",
              overflow: "auto",
              flexWrap: "nowrap",
              backgroundColor: "#ffffff",
              border: "solid 1px",
              borderLeftColor: "#A7A7A7",
              borderRightColor: "#A7A7A7",
            }}
          ></div>
        </div>
      );
    }
  };

  useEffect(() => {
    socket.on("update_report_datatable", () => {
      refresh_excel_file();
    });
  }, []);

  const refresh_excel_file = () => {
    calculateTableHeight();
    let companygroupby = [
      "company_name",
      "branch_name",
      "report_date",
      "companyid",
      "branchid",
      "sub_acct_type",
    ];
    let companytarget: { [key: string]: string } = {};
    companytarget["groupby"] = companygroupby.join(",");

    getLoanListByField(companytarget)
      .then((data: any) => {
        const reformattedData = data
          .map((item: any) => ({
            company_name: item._id.company_name,
            branch_name: item._id.branch_name,
            report_date: item._id.report_date,
            companyid: item._id.companyid,
            branchid: item._id.branchid,
            sub_acct_type: item._id.sub_acct_type,
          }))
          .filter(
            (item: any) =>
              company_access.includes(item.companyid) &&
              branch_access.includes(item.branchid)
          );

        setReportUngroupCompanyData(reformattedData);

        const groupedData: { key: string } = reformattedData.reduce(
          (acc: any, entry: any) => {
            const date = entry.report_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
          },
          {} as { key: string }
        );
        setReportCompanyData(groupedData);
      })
      .catch((error: any) => console.log(error));

    let productgroupby = [
      "report_date",
      "companyid",
      "branchid",
      "sub_acct_type",
    ];
    let producttarget: { [key: string]: string } = {};
    producttarget["groupby"] = productgroupby.join(",");

    getLoanListByField(producttarget)
      .then((data: any) => {
        const reformattedData = data
          .map((item: any) => ({
            sub_acct_type: item._id.sub_acct_type,
            report_date: item._id.report_date,
            companyid: item._id.companyid,
            branchid: item._id.branchid,
          }))
          .filter(
            (item: any) =>
              company_access.includes(item.companyid) &&
              branch_access.includes(item.branchid)
          );

        const groupedData: { key: string } = reformattedData.reduce(
          (acc: any, entry: any) => {
            const date = entry.report_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
          },
          {} as { key: string }
        );

        setReportProductData(groupedData);
      })
      .catch((error: any) => console.log(error));
    Promise.all([getLookupItem("excel")]).then((data: any) => {
      const object: any = {};
      setDbData(object);
    });
    setReady(false);
    setLoading(true);
    window.addEventListener("resize", () => {
      calculateTableHeight();
    });
    getLookupItem(tabKey)
      .then((_data: any) => {
        let full_data = _data
          ?.slice()
          .reverse()
          .map((data: any) => {
            return {
              ...data,
              key: data.uuid,
            };
          });
        setDataTable(full_data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error fetching data: ", error);
      });
    let columns: any = getColumnList(
      tabKey,
      handleSearch,
      subTabKey,
      resetSearch
    );
    let renderedColumns = [];
    if (columns) {
      renderedColumns = columns.map((element: any) => {
        if (element.isActive == true) {
          element.render = (text: any, record: any) => {
            if (text) {
              return (
                <a
                  onClick={() => {
                    downloadFile(record.filename, {
                      filename: record.uuid,
                      download_path: "../katimas_upload_excel",
                    });
                  }}
                  className="table-clickable-text"
                >
                  {text}
                </a>
              );
            }
          };
        }
        return element;
      });
      setTableColumns(renderedColumns);
    }
    if (searchRecord?.length > 0) {
      let data: any = [...dataTable];
      let filteredData = data
        .map((element: any) => {
          let bool = searchRecord?.every((item: any) => {
            return element[item.columnKey]
              ?.toString()
              ?.toLowerCase()
              .includes(item.value.toLowerCase());
          });
          if (bool) return element;
        })
        .filter((element: any) => element !== null && element !== undefined);
      setFilteredDataTable(filteredData);
    } else {
      setFilteredDataTable(null);
    }
    setReady(true);
  };

  const refresh_initial = () => {
    setReady(false);
    // setLoading(true);
    window.addEventListener("resize", () => {
      calculateTableHeight();
    });
    setLoading(true);
    getLookupItem(tabKey)
      .then((_data: any) => {
        let full_data = _data
          ?.slice()
          .reverse()
          .map((data: any) => {
            return {
              ...data,
              key: data.uuid,
            };
          });
        setDataTable(full_data);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error fetching data: ", error);
      });
    let columns: any = getColumnList(
      tabKey,
      handleSearch,
      subTabKey,
      resetSearch
    );
    let renderedColumns = [];
    if (columns) {
      renderedColumns = columns.map((element: any) => {
        if (element.isActive == true) {
          element.render = (text: any, record: any) => {
            if (text) {
              return (
                <a
                  onClick={() => {
                    // console.log(record.uuid);
                    downloadFile(record.filename, {
                      filename: record.uuid,
                      download_path: "../katimas_upload_excel",
                    });
                  }}
                  className="table-clickable-text"
                >
                  {text}
                </a>
              );
            }
          };
        }
        return element;
      });
      setTableColumns(renderedColumns);
    }
    setReady(true);
  };

  useEffect(() => {
    refresh_initial();
  }, []);

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedExcels(selectedRows);
    },
  };

  const handleGenerateReportsClick = (record: any) => {
    // setSelectedEmployee(employeeData);
    setGenerateReportsModalVisible(true);
  };

  // Triggers the useState or modal to close when 'Cancel' button is being clicked
  const handleGenerateReportsModalCancel = () => {
    setGenerateReportsModalVisible(false);
    resetForm();
  };

  const handleDeleteExcelClick = (record: any) => {
    // setSelectedEmployee(employeeData);
    setDeleteExcelModalVisible(true);
  };

  // Triggers the useState or modal to close when 'Cancel' button is being clicked
  const handleDeleteExcelModalCancel = () => {
    setDeleteExcelModalVisible(false);
  };

  return containerTitle !== "" ? (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      {setSideContainer()}
    </div>
  ) : (
    <div
      style={{
        flex: "1",
        height: "100%",
        display: "flex",
        maxWidth: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      {alertmsg && (
        <Alert
          className={
            alertmsg?.top ? "alert-message-box-top" : "alert-message-box"
          }
          type={alertmsg?.type}
          message={alertmsg?.message}
          description={alertmsg?.description}
          showIcon
          closable={alertmsg?.closeable}
          afterClose={() => setAlertmsg(null)}
          style={{
            fontFamily: "Motiva Sans",
          }}
        />
      )}{" "}
      <div
        className="generic-table-header-without-container"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <span
          style={{
            marginRight: "auto",
            minHeight: "32px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              // fontFamily: "PP Neue Machina",
              fontFamily: "Motiva Sans",
              fontWeight: "normal",
            }}
          >
            {subTabKey.charAt(0).toUpperCase() +
              subTabKey.split("_").join(" ").slice(1)}{" "}
            directory
          </span>
          <Tooltip title="If a file with the same name is uploaded, the newest file will replace the previous one.">
            <InfoCircleOutlined
              style={{ color: "#00000073", paddingLeft: "2px" }}
            />
          </Tooltip>
          {dataTable &&
          dataTable.length > 0 &&
          selectedRowKeys &&
          selectedRowKeys.length == 0 &&
          permissions_access?.includes("report generate") ? (
            <>
              <span style={{ paddingLeft: "15px" }}>
                <Button
                  type="primary"
                  className="login-form-button normal-login-button"
                  onClick={handleGenerateReportsClick}
                >
                  <FileTextOutlined style={{ color: "white" }} />
                  Generate report
                </Button>
              </span>
            </>
          ) : null}
          {selectedRowKeys && selectedRowKeys.length > 0 ? (
            <>
              <span style={{ paddingLeft: "15px" }}>
                <Popconfirm
                  title={null}
                  description="Permanently delete this file(s)? This action cannot be undone."
                  onConfirm={handleDeleteExcelConfirm}
                  okText={<span className="delete-span">Delete</span>}
                  okButtonProps={{
                    style: {
                      backgroundColor: "red", // Set the background color
                      color: "white", // Set the text color
                      border: "none", // Remove the border
                    },
                  }}
                  cancelText="Cancel"
                >
                  <Button style={{ width: "140px" }}>
                    <DeleteOutlined />
                    Delete
                  </Button>
                </Popconfirm>
              </span>
            </>
          ) : null}
        </span>
      </div>
      <div
        id="table-container"
        style={{
          flex: 1,
          display: "flex",
          maxHeight: "100%",
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        <Spin spinning={loading} tip="Loading...">
          {ready && (
            <Table
              rowClassName={(record: any) =>
                record?.uuid === activeRowKey ||
                `${record.group_uuid}-${record.tag_uuid}` === activeGroupKey ||
                (record.group_uuid === activeRowKey && !record.uuid)
                  ? "antd-table-row-active"
                  : ""
              }
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [20, 50, 100],
                showSizeChanger: true,
                position: ["bottomCenter"],
              }}
              rowSelection={hasPermission ? rowSelection : undefined}
              // rowSelection={rowSelection}
              columns={tableColumns}
              scroll={
                tableHeight && {
                  x: isMobile ? "100vw" : "100vw",
                  y: tableHeight,
                }
              }
              dataSource={filteredDataTable || dataTable}
            />
          )}
        </Spin>
      </div>
      <Modal
        maskClosable={false}
        open={generate_reports_modal_visible}
        title={
          <>
            <div
              style={{
                padding: "15px",
                backgroundColor: "#2C378E",
                color: "white",
                minHeight: "25px",
                maxHeight: "25px",
              }}
            >
              Report generation
            </div>
            <div
              style={{
                fontSize: "14px",
                padding: "5px 15px",
                color: "#00000066",
              }}
            >
              <InfoCircleOutlined
                style={{ color: "#00000073", paddingRight: "5px" }}
              />
              Enter year and month to enable filters based on the selected data.
            </div>
          </>
        }
        okText="Generate"
        onCancel={handleGenerateReportsModalCancel}
        onOk={handleGenerateReportsModalCreate}
        closable={false}
        className="modal-report"
        footer={[
          <div style={{ padding: "16px" }}>
            <Button
              key="back"
              onClick={handleGenerateReportsModalCancel}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              className=" normal-login-button"
              onClick={handleGenerateReportsModalCreate}
            >
              Generate and download
            </Button>
          </div>,
        ]}
      >
        <CustomForm
          formRef={formRef}
          setFormReset={setFormReset}
          tabKey={"excelReportGenerate"}
          reportcompanyData={reportcompanyData}
          reportproductData={reportproductData}
        />
      </Modal>
      <Modal
        footer={null}
        title={
          <div
            style={{
              padding: "15px",
              backgroundColor: "#2C378E",
              color: "white",
              minHeight: "25px",
              maxHeight: "25px",
              zIndex: "999",
            }}
          >
            Report generation
          </div>
        }
        open={isModalVisible}
        centered
        closable={false} // Disable closing during upload
        maskClosable={false} // Disable closing on outside click
      >
        <p
          style={{
            display: "inline",
            marginRight: "10px",
            marginLeft: "15px",
          }}
        >
          Generating report....
        </p>
        <Progress
          style={{ marginRight: "100px, 0", padding: "0 20px 0 20px" }}
          percent={uploadPercent}
          status={uploadPercent < 100 ? "active" : "success"}
        />
      </Modal>
    </div>
  );
  // </div>
};

export default LookupManager;
