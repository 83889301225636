import React, { useEffect, useState, useRef } from "react";
import {
  Space,
  Dropdown,
  Col,
  Row,
  Card,
  Statistic,
  DatePicker,
  DatePickerProps,
  Spin,
  Alert,
} from "antd";
import {
  DollarOutlined,
  PlusCircleOutlined,
  RiseOutlined,
  FallOutlined,
  DownOutlined,
  LineOutlined,
  ConsoleSqlOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import PieChart from "../components/Charts/PlotlyPieChart";
import BarStack from "../components/Charts/PlotlyBarStack";
import BarGroup from "../components/Charts/PlotlyBarGroup";
import PieLabel from "../components/Charts/PlotlyPieLabel";
import PieMultiChart from "../components/Charts/PlotlyPieMultiple";
import SingleLineChart from "../components/Charts/PlotlySingleLineChart";
import MultiLineChartSimple from "../components/Charts/PlotlyLineMultiple";
import MultiLineChart from "../components/Charts/PlotlyLineMultiples";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  getLoanListByField,
  getLoanListFilter,
} from "../services/api-server/loan";
import Emitter from "../utils/emitter";
import { validateRoute } from "../services/api-server/login";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { getAlertStyle } from "../utils/utils";
import { company_access, branch_access } from "../utils/_exports";
import PlotlyBarChart from "../components/Charts/PlotlyBarChart";
import PlotlyScatterPlot from "../components/Charts/PlotlyScatterPlot";
import PlotlyAgingBuckets from "../components/Charts/PlotlyAgingBuckets";
import PlotlyBarLineChart from "../components/Charts/PlotlyBarLineChart";
dayjs.extend(utc);

function ExplorerContainer(props: any) {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [alertmsg, setAlertmsg] = useState<any>(null);
  const navigate: any = useNavigate();
  const [selectedItem, setSelectedItem] = useState("Sales by product");
  const [selectedSubItem, setSelectedSubItem] = useState("Money Lending");
  const CurrentDate = dayjs().startOf("month").format("MMM YYYY");
  const PreviousDate = dayjs()
    .subtract(2, "month")
    .startOf("month")
    .format("YYYY-MM-DD");

  const PreviousDateISO = dayjs()
    .utc()
    .subtract(2, "month")
    // .add(1, "day")
    .startOf("month")
    .toISOString();

  const StartCurrentYear: any = dayjs().startOf("year").format("YYYY-MM-DD");
  const StartCurrentYearISO: any = dayjs()
    .startOf("year")
    .toDate()
    .toISOString();
  const EndCurrentYear: any = dayjs().endOf("year").format("YYYY-MM-DD");
  const EndCurrentYearISO = dayjs().endOf("year").toDate().toISOString();
  const defaultDate: any = dayjs()
    .subtract(1, "month")
    .startOf("month")
    .format("YYYY-MM-DD");
  const defaultDateISO = dayjs()
    .utc()
    .subtract(1, "month")
    // .add(1, "day")

    .startOf("month")
    // .toDate()
    .toISOString();
  const StartBarGroupYear: any = dayjs()
    .subtract(1, "year")
    .endOf("year")
    .format("YYYY-MM-DD");
  const StartBarGroupYearISO = dayjs()
    .subtract(1, "year")
    .endOf("year")
    .toDate()
    .toISOString();
  const EndBarGroupYear: any = dayjs().endOf("year").format("YYYY-MM-DD");
  const EndBarGroupYearISO = dayjs().endOf("year").toDate().toISOString();

  const today = dayjs();
  const currentMonth = today.month(); // 0 = January, 1 = February, ..., 10 = November
  const currentYear = today.year();
  const currentYearValue = today.year().toString().slice(-2);
  const previousYearValue = today
    .subtract(1, "year")
    .year()
    .toString()
    .slice(-2);

  let StartFinanceLastYearQ: any;
  let EndFinanceLastYearQ: any;
  let StartFinanceThisYearQ: any;
  let EndFinanceThisYearQ: any;
  let StartFinanceLastYearQISO: any;
  let EndFinanceLastYearQISO: any;
  let StartFinanceThisYearQISO: any;
  let EndFinanceThisYearQISO: any;
  let StartFinanceYearQ: any;
  let EndFinanceYearQ: any;
  let MonthFirst: any;
  let MonthSecond: any;
  let MonthThird: any;

  if (currentMonth >= 3 && currentMonth <= 5) {
    // Q1: April to June
    StartFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(0)
      .startOf("month")
      .format("YYYY-MM-DD"); // April 1st
    EndFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(2)
      .endOf("month")
      .format("YYYY-MM-DD"); // June 30th
    StartFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(0)
      .startOf("month")
      .format("YYYY-MM-DD"); // July 1st last year
    EndFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(2)
      .endOf("month")
      .format("YYYY-MM-DD");
    StartFinanceThisYearQISO = dayjs()
      .year(currentYear)
      .month(0)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceThisYearQISO = dayjs()
      .year(currentYear)
      .month(2)
      .endOf("month")
      .toDate()
      .toISOString();
    StartFinanceLastYearQISO = dayjs()
      .year(currentYear - 1)
      .month(0)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceLastYearQISO = dayjs()
      .year(currentYear - 1)
      .month(2)
      .endOf("month")
      .toDate()
      .toISOString();
    StartFinanceYearQ = `Q4 FYE${currentYearValue}`;
    EndFinanceYearQ = `Q4 FYE${previousYearValue}`;
    MonthFirst = `Jan-${previousYearValue}`;
    MonthSecond = `Feb-${previousYearValue}`;
    MonthThird = `Mar-${previousYearValue}`;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // Q2: July to September
    StartFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(3)
      .startOf("month")
      .format("YYYY-MM-DD"); // July 1st
    EndFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(5)
      .endOf("month")
      .format("YYYY-MM-DD"); // September 30th
    StartFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(3)
      .startOf("month")
      .format("YYYY-MM-DD"); // July 1st last year
    EndFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(5)
      .endOf("month")
      .format("YYYY-MM-DD");
    StartFinanceThisYearQISO = dayjs()
      .year(currentYear)
      .month(3)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceThisYearQISO = dayjs()
      .year(currentYear)
      .month(5)
      .endOf("month")
      .toDate()
      .toISOString();
    StartFinanceLastYearQISO = dayjs()
      .year(currentYear - 1)
      .month(3)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceLastYearQISO = dayjs()
      .year(currentYear - 1)
      .month(5)
      .endOf("month")
      .toDate()
      .toISOString();

    StartFinanceYearQ = `Q1 FYE${currentYearValue}`;
    EndFinanceYearQ = `Q1 FYE${previousYearValue}`;
    MonthFirst = `Apr-${previousYearValue}`;
    MonthSecond = `May-${previousYearValue}`;
    MonthThird = `Jun-${previousYearValue}`;
  } else if (currentMonth >= 9 && currentMonth <= 11) {
    // Q3: October to December
    StartFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(6)
      .startOf("month")
      .format("YYYY-MM-DD"); // October 1st
    EndFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(8)
      .endOf("month")
      .format("YYYY-MM-DD"); // December 31st
    StartFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(6)
      .startOf("month")
      .format("YYYY-MM-DD"); // July 1st last year
    EndFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(8)
      .endOf("month")
      .format("YYYY-MM-DD");
    StartFinanceThisYearQISO = dayjs()
      .year(currentYear)
      .month(6)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceThisYearQISO = dayjs()
      .year(currentYear)
      .month(8)
      .endOf("month")
      .toDate()
      .toISOString();
    StartFinanceLastYearQISO = dayjs()
      .year(currentYear - 1)
      .month(6)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceLastYearQISO = dayjs()
      .year(currentYear - 1)
      .month(8)
      .endOf("month")
      .toDate()
      .toISOString();
    StartFinanceYearQ = `Q2 FYE${currentYearValue}`;
    EndFinanceYearQ = `Q2 FYE${previousYearValue}`;
    MonthFirst = `Jul-${previousYearValue}`;
    MonthSecond = `Aug-${previousYearValue}`;
    MonthThird = `Sept-${previousYearValue}`;
  } else {
    // Q4: January to March
    StartFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(9)
      .startOf("month")
      .format("YYYY-MM-DD"); // January 1st
    EndFinanceThisYearQ = dayjs()
      .year(currentYear)
      .month(11)
      .endOf("month")
      .format("YYYY-MM-DD"); // March 31st
    StartFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(9)
      .startOf("month")
      .format("YYYY-MM-DD"); // July 1st last year
    EndFinanceLastYearQ = dayjs()
      .year(currentYear - 1)
      .month(11)
      .endOf("month")
      .format("YYYY-MM-DD");
    StartFinanceThisYearQISO = dayjs()
      .year(currentYear - 1)
      .month(9)
      .startOf("month")
      .toDate()
      .toISOString();

    EndFinanceThisYearQISO = dayjs()
      .year(currentYear - 1)
      .month(11)
      .endOf("month")
      .toDate()
      .toISOString();

    StartFinanceLastYearQISO = dayjs()
      .year(currentYear - 2)
      .month(9)
      .startOf("month")
      .toDate()
      .toISOString();
    EndFinanceLastYearQISO = dayjs()
      .year(currentYear - 2)
      .month(11)
      .endOf("month")
      .toDate()
      .toISOString();
    StartFinanceYearQ = `Q3 FYE${(Number(currentYearValue) - 1).toString()}`;
    EndFinanceYearQ = `Q3 FYE${(Number(previousYearValue) - 1).toString()}`;
    MonthFirst = `Oct-${(Number(previousYearValue) - 1).toString()}`;
    MonthSecond = `Nov-${(Number(previousYearValue) - 1).toString()}`;
    MonthThird = `Dec-${(Number(previousYearValue) - 1).toString()}`;
  }

  // const today = dayjs();
  const aprilFirst = dayjs().startOf("year").add(3, "months"); // April 1st of this year
  const isAfterAprilFirst =
    today.isAfter(aprilFirst) || today.isSame(aprilFirst);

  let StartFinanceLastYear: any;
  let EndFinanceLastYear: any;
  let StartFinanceThisYear: any;
  let EndFinanceThisYear: any;
  let FinanceYear: any;
  let StartFinanceLastYearISO: any;
  let EndFinanceLastYearISO: any;
  let StartFinanceThisYearISO: any;
  let EndFinanceThisYearISO: any;

  if (isAfterAprilFirst) {
    StartFinanceThisYear = dayjs()
      .subtract(1, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    EndFinanceThisYear = dayjs()
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    StartFinanceLastYear = dayjs()
      .subtract(2, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    EndFinanceLastYear = dayjs()
      .subtract(1, "year")
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    StartFinanceThisYearISO = dayjs()
      .subtract(1, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    EndFinanceThisYearISO = dayjs()
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    StartFinanceLastYearISO = dayjs()
      .subtract(2, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    EndFinanceLastYearISO = dayjs()
      .subtract(1, "year")
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    FinanceYear = today.subtract(1, "year").year().toString().slice(-2);
  } else {
    StartFinanceThisYear = dayjs()
      .subtract(2, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    EndFinanceThisYear = dayjs()
      .subtract(1, "year")
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    StartFinanceLastYear = dayjs()
      .subtract(3, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    EndFinanceLastYear = dayjs()
      .subtract(2, "year")
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .format("YYYY-MM-DD");
    StartFinanceThisYearISO = dayjs()
      .subtract(2, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    EndFinanceThisYearISO = dayjs()
      .subtract(1, "year")
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    StartFinanceLastYearISO = dayjs()
      .subtract(3, "year")
      .startOf("year")
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    EndFinanceLastYearISO = dayjs()
      .subtract(2, "year")
      .startOf("year") // Start of this year
      .add(3, "months") // Move to April
      .toDate()
      .toISOString();
    FinanceYear = today.subtract(2, "year").year().toString().slice(-2);
  }

  const [selectedDate, setSelectedDate]: any = useState(
    dayjs().subtract(1, "month").startOf("month")
  );
  const [selectFilteredDate, setSelectFilteredDate]: any =
    useState(defaultDateISO);
  const [selectPreviousFilteredDate, setSelectPreviousFilteredDate]: any =
    useState(PreviousDateISO);
  const [selectedYear, setSelectedYear]: any = useState(dayjs().year());
  const [loading, setLoading]: any = useState(false);
  const [monthloading, setMonthLoading]: any = useState(false);
  const [monthloanloading, setMonthLoanLoading]: any = useState(false);
  const [salesgrowthloading, setSalesGrowthLoading]: any = useState(false);
  const [financecardloading, setFinanceCardLoading]: any = useState(false);
  const [financePieloading, setFinancePieLoading]: any = useState(false);
  const [
    MLcollectionmonthstatisticloading,
    setMLCollectionMonthStatisticLoading,
  ]: any = useState(false);
  const [
    MLcollectionyearstatisticloading,
    setMLCollectionYearStatisticLoading,
  ]: any = useState(false);
  const [
    MLcollectionmonthcategoryloading,
    setMLCollectionMonthCollectionLoading,
  ]: any = useState(false);
  const [
    MLcollectionmonthagingbucketloading,
    setMLCollectionMonthAgingBucketLoading,
  ]: any = useState(false);
  const [
    MLcollectionyearcollectionrateloading,
    setMLCollectionYearCollectionRateLoading,
  ]: any = useState(false);
  const [
    MLcollectionyearmonthcollectionloading,
    setMLCollectionYearMonthCollectionLoading,
  ]: any = useState(false);
  const [
    MLcollectionyearnonperformingloanloading,
    setMLCollectionYearNonPerformingLoanLoading,
  ]: any = useState(false);
  const [
    HPcollectionmonthstatisticloading,
    setHPCollectionMonthStatisticLoading,
  ]: any = useState(false);
  const [
    HPcollectionyearstatisticloading,
    setHPCollectionYearStatisticLoading,
  ]: any = useState(false);
  const [
    HPcollectionmonthcategoryloading,
    setHPCollectionMonthCollectionLoading,
  ]: any = useState(false);
  const [
    HPcollectionmonthagingbucketloading,
    setHPCollectionMonthAgingBucketLoading,
  ]: any = useState(false);
  const [
    HPcollectionyearcollectionrateloading,
    setHPCollectionYearCollectionRateLoading,
  ]: any = useState(false);

  // sales by product statistic
  const [totalloan, setTotalLoan]: any = useState(0);
  const [newloan, setNewLoan]: any = useState(0);
  const [newcustomer, setNewCustomer]: any = useState(0);

  // collection by product statistic
  const [totalreceivables, setTotalReceivables]: any = useState(0);
  const [totalcollections, setTotalCollections]: any = useState(0);
  const [collectionrate, setCollectionRate]: any = useState(0);
  const [totalsettlement, setTotalSettlement]: any = useState(0);
  const [totaladvance, setTotalAdvance]: any = useState(0);
  const [totalrebate, setTotalRebate]: any = useState(0);

  const [totalloanpercentage, setTotalLoanPercentage]: any = useState(0);
  const [newloanpercentage, setNewLoanPercentage]: any = useState(0);
  const [newcustomerpercentage, setNewCustomerPercentage]: any = useState(0);
  const [totalloandifference, setTotalLoanDifference]: any = useState(0);

  // sales by product
  const [totalloanLT, setTotalLoanLT]: any = useState(0);
  const [totalloanPFI, setTotalLoanPFI]: any = useState(0);
  const [totalloanCLI, setTotalLoanCLI]: any = useState(0);
  const [totalloanHP, setTotalLoanHP]: any = useState(0);

  // collection by product
  const [totaladvanceLT, setTotalAdvanceLT]: any = useState(0);
  const [totaladvancePFI, setTotalAdvancePFI]: any = useState(0);
  const [totaladvanceCLI, setTotalAdvanceCLI]: any = useState(0);
  const [totaladvanceHP, setTotalAdvanceHP]: any = useState(0);
  const [totalsettlementLT, setTotalSettlementLT]: any = useState(0);
  const [totalsettlementPFI, setTotalSettlementPFI]: any = useState(0);
  const [totalsettlementCLI, setTotalSettlementCLI]: any = useState(0);
  const [totalsettlementHP, setTotalSettlementHP]: any = useState(0);

  const [totalloannewcustomer, setTotalLoanNewCustomer]: any = useState(0);
  const [totalloanreturningcustomer, setTotalLoanReturningCustomer]: any =
    useState(0);
  const [totalloanexistingcustomer, setTotalLoanExistingCustomer]: any =
    useState(0);
  const [totalloancustomer, setTotalLoanCustomer]: any = useState(0);
  const [totalcustomerpercentage, setTotalCustomerPercentage]: any =
    useState(0);

  const [monthly_due_to_receive, setMonthlyDuetoReceive]: any = useState(0);
  const [monthly_collected, setMonthlyCollected]: any = useState(0);
  const [monthly_collected_percentage, setMonthlyCollectedPercentage]: any =
    useState(0);
  const [monthly_collection_rate, setMonthlyCollectionRate]: any = useState(0);
  const [monthly_advance, setMonthlyAdvance]: any = useState(0);
  const [monthly_full_settlement, setMonthlyFullSettlement]: any = useState(0);
  const [monthly_collection_by_category, setMonthlyCollectionbyCategory]: any =
    useState({});
  const [monthly_aging_buckets, setMonthlyAgingBuckets]: any = useState([]);

  const [yearly_due_to_receive, setYearlyDuetoReceive]: any = useState(0);
  const [yearly_collected, setYearlyCollected]: any = useState(0);
  const [yearly_collection_rate, setYearlyCollectionRate]: any = useState(0);
  const [yearly_current_collection_rate, setYearlyCurrentCollectionRate]: any =
    useState([]);
  const [yearly_monthly_collection, setYearlyMonthlyCollection]: any = useState(
    []
  );
  const [yearly_non_performing_loan, setYearlyNonPerformingLoan]: any =
    useState([]);

  const [barstackData, setBarStackData]: any = useState([]);
  const [barGroupData, setBarGroupData]: any = useState([]);
  const [lineSingleData, setLineSingleData]: any = useState([]);
  const [lineMultipleData, setLineMultipleData]: any = useState([]);
  const [lineDoubleMultipleDataD1, setLineDoubleMultipleDataD1]: any = useState(
    []
  );
  const [lineDoubleMultipleDataD2, setLineDoubleMultipleDataD2]: any = useState(
    []
  );

  const [currentfinanceLT, setCurrentFinanceLT]: any = useState(0);
  const [currentfinancePFI, setCurrentFinancePFI]: any = useState(0);
  const [currentfinanceCLI, setCurrentFinanceCLI]: any = useState(0);
  const [currentfinanceHP, setCurrentFinanceHP]: any = useState(0);

  const [currentcollectionratio, setCurrentCollectionRatio]: any = useState(0);
  const [previouscollectionratio, setPreviousCollectionRatio]: any =
    useState(0);
  const [currentcollectionratiod1, setCurrentCollectionRatioD1]: any =
    useState(0);
  const [previouscollectionratiod1, setPreviousCollectionRatioD1]: any =
    useState(0);
  const [currentcollectionratiod2, setCurrentCollectionRatioD2]: any =
    useState(0);
  const [previouscollectionratiod2, setPreviousCollectionRatioD2]: any =
    useState(0);

  const [financeLTpercentage, setFinanceLTPercentage]: any = useState(0);
  const [financePFIpercentage, setFinancePFIPercentage]: any = useState(0);
  const [financeCLIpercentage, setFinanceCLIPercentage]: any = useState(0);
  const [financeHPpercentage, setFinanceHPPercentage]: any = useState(0);

  const [previousyearFinanceData, setPreviousYearFinanceData]: any = useState(
    []
  );
  const [currentyearFinanceData, setCurrentYearFinanceData]: any = useState([]);

  const [previous_year_total_PFIQ, setPreviousYearTotalPFIQ]: any = useState(0);
  const [previous_year_total_LTQ, setPreviousYearTotalLTQ]: any = useState(0);
  const [previous_year_total_CLIQ, setPreviousYearTotalCLIQ]: any = useState(0);
  const [current_year_total_PFIQ, setCurrentYearTotalPFIQ]: any = useState(0);
  const [current_year_total_LTQ, setCurrentYearTotalLTQ]: any = useState(0);
  const [current_year_total_CLIQ, setCurrentYearTotalCLIQ]: any = useState(0);

  const role = props.params.userRole[0];
  const userData = props.params.user;

  const customFormat: DatePickerProps["format"] = (value: any) =>
    `${value.format("MMM YYYY")}`;

  const maxMonth = dayjs(); // Set max month to today + 1 month

  const disabledDate = (current: any) => {
    return current && current >= dayjs().startOf("month");
  };

  const items = [
    { label: "Sales by product", key: "Sales by product" },
    { label: "Collection by product", key: "Collection by product" },
  ];

  const collection_items = [
    { label: "Money Lending", key: "Money Lending" },
    { label: "Hire Purchase", key: "Hire Purchase" },
  ];

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     validateRoute()
  //       .then((res: any) => {
  //         if (res) {
  //         } else {
  //           setAlertmsg({
  //             type: "warning",
  //             message: "Login session expired, please login again",
  //             top: true,
  //             closeable: true,
  //           });
  //           setTimeout(() => {
  //             removeAllCookies();
  //             // First, navigate to a temporary route
  //             navigate(`/`, { replace: true });
  //           }, 3000);
  //         }
  //       })
  //       .catch((error: any) => {
  //         setAlertmsg({
  //           type: "warning",
  //           message: "Login session expired, please login again",
  //           top: true,
  //           closeable: true,
  //         });
  //         setTimeout(() => {
  //           removeAllCookies();
  //           // First, navigate to a temporary route
  //           navigate(`/`, { replace: true });
  //         }, 3000);
  //       });
  //   }, 5000); // 5000 milliseconds = 5 seconds

  //   // Cleanup function to clear the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const onLogout = () => {
      setAlertmsg({
        type: "warning",
        message: "Login session expired, please login again",
        top: true,
        closeable: true,
      });

      setTimeout(() => {
        removeAllCookies();
        // First, navigate to a temporary route (or directly to login)
        navigate(`/`, { replace: true });
      }, 3000);
    };

    // Listen for the logout event
    Emitter.on("logout", onLogout);

    // Cleanup function to remove the listener on component unmount
    return () => {
      Emitter.off("logout", onLogout);
    };
  }, []); // Empty dependency array means this runs once on mount
  const removeAllCookies = (): void => {
    // Get all cookies
    const allCookies = Cookies.get();

    // Iterate over all cookies and remove each one
    for (const cookieName in allCookies) {
      Cookies.remove(cookieName);
    }
  };

  const calculateProfitPercentage = (
    lastMonthEarnings: number,
    currentMonthEarnings: number
  ) => {
    if (lastMonthEarnings === 0) {
      if (currentMonthEarnings === 0) {
        return 0;
      } else {
        return 100; // or return null; depending on your preference
      }
    }
    const profit = currentMonthEarnings - lastMonthEarnings;
    const profitPercentage = (profit / lastMonthEarnings) * 100;
    return parseFloat(profitPercentage.toFixed(2));
  };

  const handleYearChange = (date: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the current operation
    abortControllerRef.current = new AbortController();
    const year = date.year();
    setSelectedYear(year);
    const StartSelectedYear = dayjs(`${year}-01-01`)
      .startOf("year")
      .toDate()
      .toISOString(); // Convert to ISO format for MongoDB

    const EndSelectedYear = dayjs(`${year}-12-31`)
      .endOf("year")
      .toDate()
      .toISOString(); // Convert to ISO format for MongoDB

    let year_due_to_receive = 0;
    let year_collected = 0;
    let year_collection_rate = 0;

    if (selectedSubItem === "Money Lending") {
      setMLCollectionYearStatisticLoading(true);
      setMLCollectionYearCollectionRateLoading(true);
      setMLCollectionYearMonthCollectionLoading(true);
      setMLCollectionYearNonPerformingLoanLoading(true);

      let selectedYearQuery = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
          report_date: 1,
          sub_acct_type: 1,
        },
      };

      let currentYear = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
        },
      };

      let currentYearCurrentCollectionRate = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYearMonthlyCollectionbyType = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          sub_acct_type: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYearNonPerformingLoan = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
          report_date: 1,
        },
      };

      // Promise.all([
      //   getLoanListFilter(currentYear, abortControllerRef.current.signal),
      // ])
      //   .then(([currentYearlyData]: any) => {
      //     currentYearlyData.forEach((item: any) => {
      //       year_due_to_receive +=
      //         parseFloat(item.d0_principal_due_rm) +
      //         parseFloat(item.d0_profit_due_rm) +
      //         parseFloat(item.d1_principal_due_rm) +
      //         parseFloat(item.d1_profit_due_rm) +
      //         parseFloat(item.d2_principal_due_rm) +
      //         parseFloat(item.d2_profit_due_rm) +
      //         parseFloat(item.d3_principal_due_rm) +
      //         parseFloat(item.d3_profit_due_rm) +
      //         parseFloat(item.d4_principal_due_rm) +
      //         parseFloat(item.d4_profit_due_rm) +
      //         parseFloat(item.d5_principal_due_rm) +
      //         parseFloat(item.d5_profit_due_rm) +
      //         parseFloat(item.d6_principal_due_rm) +
      //         parseFloat(item.d6_profit_due_rm) +
      //         parseFloat(item.d7__principal_due_rm) +
      //         parseFloat(item.d7__profit_due_rm);

      //       year_collected +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       if (year_due_to_receive != 0) {
      //         year_collection_rate = year_collected / year_due_to_receive;
      //       } else {
      //         year_collection_rate = 0;
      //       }

      //       setYearlyDuetoReceive(year_due_to_receive);
      //       setYearlyCollected(year_collected);
      //       setYearlyCollectionRate(year_collection_rate);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearStatisticLoading(false);
      //   });

      // Promise.all([
      //   getLoanListFilter(
      //     currentYearCurrentCollectionRate,
      //     abortControllerRef.current.signal
      //   ),
      // ])
      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsSalesGrowth.set(month, {
      //         received_total: 0,
      //         collected_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       if (!totalsSalesGrowth.has(monthName)) {
      //         totalsSalesGrowth.set(monthName, {
      //           received_total: 0,
      //           collected_total: 0,
      //         });
      //       }

      //       const totals = totalsSalesGrowth.get(monthName);
      //       if (totals) {
      //         totals.received_total +=
      //           parseFloat(item.d0_principal_due_rm) +
      //           parseFloat(item.d0_profit_due_rm) +
      //           parseFloat(item.d1_principal_due_rm) +
      //           parseFloat(item.d1_profit_due_rm) +
      //           parseFloat(item.d2_principal_due_rm) +
      //           parseFloat(item.d2_profit_due_rm) +
      //           parseFloat(item.d3_principal_due_rm) +
      //           parseFloat(item.d3_profit_due_rm) +
      //           parseFloat(item.d4_principal_due_rm) +
      //           parseFloat(item.d4_profit_due_rm) +
      //           parseFloat(item.d5_principal_due_rm) +
      //           parseFloat(item.d5_profit_due_rm) +
      //           parseFloat(item.d6_principal_due_rm) +
      //           parseFloat(item.d6_profit_due_rm) +
      //           parseFloat(item.d7__principal_due_rm) +
      //           parseFloat(item.d7__profit_due_rm);
      //         totals.collected_total +=
      //           parseFloat(item.d0_collected_principal_rm) +
      //           parseFloat(item.d0_collected_profit_rm) +
      //           parseFloat(item.d1_collected_principal_rm) +
      //           parseFloat(item.d1_collected_profit_rm) +
      //           parseFloat(item.d2_collected_principal_rm) +
      //           parseFloat(item.d2_collected_profit_rm) +
      //           parseFloat(item.d3_collected_principal_rm) +
      //           parseFloat(item.d3_collected_profit_rm) +
      //           parseFloat(item.d4_collected_principal_rm) +
      //           parseFloat(item.d4_collected_profit_rm) +
      //           parseFloat(item.d5_collected_principal_rm) +
      //           parseFloat(item.d5_collected_profit_rm) +
      //           parseFloat(item.d6_collected_principal_rm) +
      //           parseFloat(item.d6_collected_profit_rm) +
      //           parseFloat(item.d7__collected_principal_rm) +
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       const salesgrowthresult: {
      //         month: string;
      //         ratio: number;
      //       }[] = Array.from(totalsSalesGrowth.entries()).map(
      //         ([month, { received_total, collected_total }]: any) => ({
      //           month,
      //           ratio:
      //             collected_total !== 0 ? collected_total / received_total : 0,
      //         })
      //       );

      //       setYearlyCurrentCollectionRate(salesgrowthresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearCollectionRateLoading(false);
      //   });

      // Promise.all([
      //   getLoanListFilter(
      //     currentYearMonthlyCollectionbyType,
      //     abortControllerRef.current.signal
      //   ),
      // ])
      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();
      //     const totalsMonthLoan: any = new Map();
      //     const totalsNPL: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const subAcctType = item.sub_acct_type;

      //       if (!totalsMonthLoan.has(subAcctType)) {
      //         totalsMonthLoan.set(subAcctType, Array(12).fill(0));
      //       }

      //       const currentTotals = totalsMonthLoan.get(subAcctType)!;
      //       currentTotals[monthIndex] +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       const monthloanresult: {
      //         sub_type_acct: string;
      //         total: number[];
      //       }[] = Array.from(totalsMonthLoan.entries()).map(
      //         ([sub_type_acct, total]: any) => ({
      //           sub_type_acct,
      //           total,
      //         })
      //       );
      //       monthloanresult.sort((a: any, b: any) =>
      //         a.sub_type_acct.localeCompare(b.sub_type_acct)
      //       );

      //       setYearlyMonthlyCollection(monthloanresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearMonthCollectionLoading(false);
      //   });

      // Promise.all([
      //   getLoanListFilter(
      //     currentYearNonPerformingLoan,
      //     abortControllerRef.current.signal
      //   ),
      // ])
      //   .then(([data]: any) => {
      //     const totalsNPL: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsNPL.set(month, {
      //         receivable_total: 0,
      //         balance_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       let year_d0_aging_principal_balance = 0;
      //       let year_d0_aging_profit_balance = 0;
      //       let year_d1_aging_principal_balance = 0;
      //       let year_d1_aging_profit_balance = 0;
      //       let year_d2_aging_principal_balance = 0;
      //       let year_d2_aging_profit_balance = 0;
      //       let year_d3_aging_principal_balance = 0;
      //       let year_d3_aging_profit_balance = 0;
      //       let year_d4_aging_principal_balance = 0;
      //       let year_d4_aging_profit_balance = 0;
      //       let year_d5_aging_principal_balance = 0;
      //       let year_d5_aging_profit_balance = 0;
      //       let year_d6_aging_principal_balance = 0;
      //       let year_d6_aging_profit_balance = 0;
      //       let year_d7_aging_principal_balance = 0;
      //       let year_d7_aging_profit_balance = 0;

      //       if (item.d1 == 1) {
      //         year_d1_aging_principal_balance =
      //           parseFloat(item.d1_principal_due_rm) -
      //           parseFloat(item.d1_collected_principal_rm);
      //         year_d1_aging_profit_balance =
      //           parseFloat(item.d1_profit_due_rm) -
      //           parseFloat(item.d1_collected_profit_rm);
      //       }

      //       if (item.d2 == 1) {
      //         year_d2_aging_principal_balance =
      //           parseFloat(item.d2_principal_due_rm) -
      //           parseFloat(item.d2_collected_principal_rm);
      //         year_d2_aging_profit_balance =
      //           parseFloat(item.d2_profit_due_rm) -
      //           parseFloat(item.d2_collected_profit_rm);
      //       }

      //       if (item.d3 == 1) {
      //         year_d3_aging_principal_balance =
      //           parseFloat(item.d3_principal_due_rm) -
      //           parseFloat(item.d3_collected_principal_rm);
      //         year_d3_aging_profit_balance =
      //           parseFloat(item.d3_profit_due_rm) -
      //           parseFloat(item.d3_collected_profit_rm);
      //       }

      //       if (item.d4 == 1) {
      //         year_d4_aging_principal_balance =
      //           parseFloat(item.d4_principal_due_rm) -
      //           parseFloat(item.d4_collected_principal_rm);
      //         year_d4_aging_profit_balance =
      //           parseFloat(item.d4_profit_due_rm) -
      //           parseFloat(item.d4_collected_profit_rm);
      //       }

      //       if (item.d5 == 1) {
      //         year_d5_aging_principal_balance =
      //           parseFloat(item.d5_principal_due_rm) -
      //           parseFloat(item.d5_collected_principal_rm);
      //         year_d5_aging_profit_balance =
      //           parseFloat(item.d5_profit_due_rm) -
      //           parseFloat(item.d5_collected_profit_rm);
      //       }

      //       if (item.d6 == 1) {
      //         year_d6_aging_principal_balance =
      //           parseFloat(item.d6_principal_due_rm) -
      //           parseFloat(item.d6_collected_principal_rm);
      //         year_d6_aging_profit_balance =
      //           parseFloat(item.d6_profit_due_rm) -
      //           parseFloat(item.d6_collected_profit_rm);
      //       }

      //       if (item.d7_ == 1) {
      //         year_d7_aging_principal_balance =
      //           parseFloat(item.d7__principal_due_rm) -
      //           parseFloat(item.d7__collected_principal_rm);
      //         year_d7_aging_profit_balance =
      //           parseFloat(item.d7__profit_due_rm) -
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       year_d0_aging_principal_balance =
      //         parseFloat(item.principal_balance_rm) -
      //         (parseFloat(item.d1_principal_due_rm) -
      //           parseFloat(item.d1_collected_principal_rm)) -
      //         (parseFloat(item.d2_principal_due_rm) -
      //           parseFloat(item.d2_collected_principal_rm)) -
      //         (parseFloat(item.d3_principal_due_rm) -
      //           parseFloat(item.d3_collected_principal_rm)) -
      //         (parseFloat(item.d4_principal_due_rm) -
      //           parseFloat(item.d4_collected_principal_rm)) -
      //         (parseFloat(item.d5_principal_due_rm) -
      //           parseFloat(item.d5_collected_principal_rm)) -
      //         (parseFloat(item.d6_principal_due_rm) -
      //           parseFloat(item.d6_collected_principal_rm)) -
      //         (parseFloat(item.d7__principal_due_rm) -
      //           parseFloat(item.d7__collected_principal_rm));

      //       year_d0_aging_profit_balance =
      //         parseFloat(item.profit_balance_rm) -
      //         (parseFloat(item.d1_profit_due_rm) -
      //           parseFloat(item.d1_collected_profit_rm)) -
      //         (parseFloat(item.d2_profit_due_rm) -
      //           parseFloat(item.d2_collected_profit_rm)) -
      //         (parseFloat(item.d3_profit_due_rm) -
      //           parseFloat(item.d3_collected_profit_rm)) -
      //         (parseFloat(item.d4_profit_due_rm) -
      //           parseFloat(item.d4_collected_profit_rm)) -
      //         (parseFloat(item.d5_profit_due_rm) -
      //           parseFloat(item.d5_collected_profit_rm)) -
      //         (parseFloat(item.d6_profit_due_rm) -
      //           parseFloat(item.d6_collected_profit_rm)) -
      //         (parseFloat(item.d7__profit_due_rm) -
      //           parseFloat(item.d7__collected_profit_rm));

      //       if (!totalsNPL.has(monthName)) {
      //         totalsNPL.set(monthName, {
      //           receivable_total: 0,
      //           balance_total: 0,
      //         });
      //       }

      //       const totalNPL = totalsNPL.get(monthName);
      //       if (totalNPL) {
      //         totalNPL.receivable_total +=
      //           year_d0_aging_principal_balance +
      //           year_d0_aging_profit_balance +
      //           year_d1_aging_principal_balance +
      //           year_d1_aging_profit_balance +
      //           year_d2_aging_principal_balance +
      //           year_d2_aging_profit_balance +
      //           year_d3_aging_principal_balance +
      //           year_d3_aging_profit_balance +
      //           year_d4_aging_principal_balance +
      //           year_d4_aging_profit_balance +
      //           year_d5_aging_principal_balance +
      //           year_d5_aging_profit_balance +
      //           year_d6_aging_principal_balance +
      //           year_d6_aging_profit_balance +
      //           year_d7_aging_principal_balance +
      //           year_d7_aging_profit_balance;
      //         totalNPL.balance_total +=
      //           year_d4_aging_principal_balance +
      //           year_d4_aging_profit_balance +
      //           year_d5_aging_principal_balance +
      //           year_d5_aging_profit_balance +
      //           year_d6_aging_principal_balance +
      //           year_d6_aging_profit_balance +
      //           year_d7_aging_principal_balance +
      //           year_d7_aging_profit_balance;
      //       }

      //       const monthNPL: {
      //         month: string;
      //         receivable_total: number;
      //         ratio: number;
      //       }[] = Array.from(totalsNPL.entries()).map(
      //         ([month, { receivable_total, balance_total }]: any) => ({
      //           month,
      //           receivable_total,
      //           ratio:
      //             balance_total !== 0 ? balance_total / receivable_total : 0,
      //         })
      //       );
      //       setYearlyNonPerformingLoan(monthNPL);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearNonPerformingLoanLoading(false);
      //   });
      getLoanListFilter(
        selectedYearQuery,
        abortControllerRef.current.signal
      ).then((data: any) => {
        if (data && Array.isArray(data)) {
          // Ensure that data is not null or undefined and is an array
          const YearlyStatisticsPromise = new Promise(
            (resolve: any, reject: any) => {
              data.forEach((item: any) => {
                year_due_to_receive +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm) +
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm) +
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm) +
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm) +
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm) +
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm) +
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm) +
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);

                year_collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                if (year_due_to_receive != 0) {
                  year_collection_rate = year_collected / year_due_to_receive;
                } else {
                  year_collection_rate = 0;
                }

                setYearlyDuetoReceive(year_due_to_receive);
                setYearlyCollected(year_collected);
                setYearlyCollectionRate(year_collection_rate);
              });
              resolve();
              setMLCollectionYearStatisticLoading(false);
            }
          );
          const YearlyCollectionRatesPromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsSalesGrowth: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              months.forEach((month) => {
                totalsSalesGrowth.set(month, {
                  received_total: 0,
                  collected_total: 0,
                });
              });
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const monthName = months[monthIndex];

                if (!totalsSalesGrowth.has(monthName)) {
                  totalsSalesGrowth.set(monthName, {
                    received_total: 0,
                    collected_total: 0,
                  });
                }

                const totals = totalsSalesGrowth.get(monthName);
                if (totals) {
                  totals.received_total +=
                    parseFloat(item.d0_principal_due_rm) +
                    parseFloat(item.d0_profit_due_rm) +
                    parseFloat(item.d1_principal_due_rm) +
                    parseFloat(item.d1_profit_due_rm) +
                    parseFloat(item.d2_principal_due_rm) +
                    parseFloat(item.d2_profit_due_rm) +
                    parseFloat(item.d3_principal_due_rm) +
                    parseFloat(item.d3_profit_due_rm) +
                    parseFloat(item.d4_principal_due_rm) +
                    parseFloat(item.d4_profit_due_rm) +
                    parseFloat(item.d5_principal_due_rm) +
                    parseFloat(item.d5_profit_due_rm) +
                    parseFloat(item.d6_principal_due_rm) +
                    parseFloat(item.d6_profit_due_rm) +
                    parseFloat(item.d7__principal_due_rm) +
                    parseFloat(item.d7__profit_due_rm);
                  totals.collected_total +=
                    parseFloat(item.d0_collected_principal_rm) +
                    parseFloat(item.d0_collected_profit_rm) +
                    parseFloat(item.d1_collected_principal_rm) +
                    parseFloat(item.d1_collected_profit_rm) +
                    parseFloat(item.d2_collected_principal_rm) +
                    parseFloat(item.d2_collected_profit_rm) +
                    parseFloat(item.d3_collected_principal_rm) +
                    parseFloat(item.d3_collected_profit_rm) +
                    parseFloat(item.d4_collected_principal_rm) +
                    parseFloat(item.d4_collected_profit_rm) +
                    parseFloat(item.d5_collected_principal_rm) +
                    parseFloat(item.d5_collected_profit_rm) +
                    parseFloat(item.d6_collected_principal_rm) +
                    parseFloat(item.d6_collected_profit_rm) +
                    parseFloat(item.d7__collected_principal_rm) +
                    parseFloat(item.d7__collected_profit_rm);
                }

                const salesgrowthresult: {
                  month: string;
                  ratio: number;
                }[] = Array.from(totalsSalesGrowth.entries()).map(
                  ([month, { received_total, collected_total }]: any) => ({
                    month,
                    ratio:
                      collected_total !== 0
                        ? collected_total / received_total
                        : 0,
                  })
                );

                setYearlyCurrentCollectionRate(salesgrowthresult);
              });
              resolve();
              setMLCollectionYearCollectionRateLoading(false);
            }
          );
          const YearlyCollectionTypePromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsSalesGrowth: any = new Map();
              const totalsMonthLoan: any = new Map();
              const totalsNPL: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const subAcctType = item.sub_acct_type;

                if (!totalsMonthLoan.has(subAcctType)) {
                  totalsMonthLoan.set(subAcctType, Array(12).fill(0));
                }

                const currentTotals = totalsMonthLoan.get(subAcctType)!;
                currentTotals[monthIndex] +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                const monthloanresult: {
                  sub_type_acct: string;
                  total: number[];
                }[] = Array.from(totalsMonthLoan.entries()).map(
                  ([sub_type_acct, total]: any) => ({
                    sub_type_acct,
                    total,
                  })
                );
                monthloanresult.sort((a: any, b: any) =>
                  a.sub_type_acct.localeCompare(b.sub_type_acct)
                );

                setYearlyMonthlyCollection(monthloanresult);
              });
              resolve();
              setMLCollectionYearMonthCollectionLoading(false);
            }
          );
          const YearlyNonPerformingLoanPromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsNPL: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              months.forEach((month) => {
                totalsNPL.set(month, {
                  receivable_total: 0,
                  balance_total: 0,
                });
              });
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const monthName = months[monthIndex];

                let year_d0_aging_principal_balance = 0;
                let year_d0_aging_profit_balance = 0;
                let year_d1_aging_principal_balance = 0;
                let year_d1_aging_profit_balance = 0;
                let year_d2_aging_principal_balance = 0;
                let year_d2_aging_profit_balance = 0;
                let year_d3_aging_principal_balance = 0;
                let year_d3_aging_profit_balance = 0;
                let year_d4_aging_principal_balance = 0;
                let year_d4_aging_profit_balance = 0;
                let year_d5_aging_principal_balance = 0;
                let year_d5_aging_profit_balance = 0;
                let year_d6_aging_principal_balance = 0;
                let year_d6_aging_profit_balance = 0;
                let year_d7_aging_principal_balance = 0;
                let year_d7_aging_profit_balance = 0;

                if (item.d1 === 1) {
                  year_d1_aging_principal_balance =
                    parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm);
                  year_d1_aging_profit_balance =
                    parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm);
                }

                if (item.d2 === 1) {
                  year_d2_aging_principal_balance =
                    parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm);
                  year_d2_aging_profit_balance =
                    parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm);
                }

                if (item.d3 === 1) {
                  year_d3_aging_principal_balance =
                    parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm);
                  year_d3_aging_profit_balance =
                    parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm);
                }

                if (item.d4 === 1) {
                  year_d4_aging_principal_balance =
                    parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm);
                  year_d4_aging_profit_balance =
                    parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm);
                }

                if (item.d5 === 1) {
                  year_d5_aging_principal_balance =
                    parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm);
                  year_d5_aging_profit_balance =
                    parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm);
                }

                if (item.d6 === 1) {
                  year_d6_aging_principal_balance =
                    parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm);
                  year_d6_aging_profit_balance =
                    parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm);
                }

                if (item.d7_ === 1) {
                  year_d7_aging_principal_balance =
                    parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm);
                  year_d7_aging_profit_balance =
                    parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm);
                }

                year_d0_aging_principal_balance =
                  parseFloat(item.principal_balance_rm) -
                  (parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm)) -
                  (parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm)) -
                  (parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm)) -
                  (parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm)) -
                  (parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm)) -
                  (parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm)) -
                  (parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm));

                year_d0_aging_profit_balance =
                  parseFloat(item.profit_balance_rm) -
                  (parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm)) -
                  (parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm)) -
                  (parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm)) -
                  (parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm)) -
                  (parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm)) -
                  (parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm)) -
                  (parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm));

                if (!totalsNPL.has(monthName)) {
                  totalsNPL.set(monthName, {
                    receivable_total: 0,
                    balance_total: 0,
                  });
                }

                const totalNPL = totalsNPL.get(monthName);
                if (totalNPL) {
                  totalNPL.receivable_total +=
                    year_d0_aging_principal_balance +
                    year_d0_aging_profit_balance +
                    year_d1_aging_principal_balance +
                    year_d1_aging_profit_balance +
                    year_d2_aging_principal_balance +
                    year_d2_aging_profit_balance +
                    year_d3_aging_principal_balance +
                    year_d3_aging_profit_balance +
                    year_d4_aging_principal_balance +
                    year_d4_aging_profit_balance +
                    year_d5_aging_principal_balance +
                    year_d5_aging_profit_balance +
                    year_d6_aging_principal_balance +
                    year_d6_aging_profit_balance +
                    year_d7_aging_principal_balance +
                    year_d7_aging_profit_balance;
                  totalNPL.balance_total +=
                    year_d4_aging_principal_balance +
                    year_d4_aging_profit_balance +
                    year_d5_aging_principal_balance +
                    year_d5_aging_profit_balance +
                    year_d6_aging_principal_balance +
                    year_d6_aging_profit_balance +
                    year_d7_aging_principal_balance +
                    year_d7_aging_profit_balance;
                }

                const monthNPL: {
                  month: string;
                  receivable_total: number;
                  ratio: number;
                }[] = Array.from(totalsNPL.entries()).map(
                  ([month, { receivable_total, balance_total }]: any) => ({
                    month,
                    receivable_total,
                    ratio:
                      balance_total !== 0
                        ? balance_total / receivable_total
                        : 0,
                  })
                );
                setYearlyNonPerformingLoan(monthNPL);
              });
              resolve();
              setMLCollectionYearNonPerformingLoanLoading(false);
            }
          );
          Promise.all([
            YearlyStatisticsPromise,
            YearlyCollectionRatesPromise,
            YearlyCollectionTypePromise,
            YearlyNonPerformingLoanPromise,
          ])
            .then(() => {})
            .catch((error: any) => {
              // Handle any errors from any of the groups
              console.error(error);
            })
            .finally(() => {});
        } else {
          console.error("Invalid data received");
        }
      });
    } else if (selectedSubItem === "Hire Purchase") {
      setHPCollectionYearStatisticLoading(true);
      setHPCollectionYearCollectionRateLoading(true);

      let selectedYearQuery = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYear = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
        },
      };

      let currentYearCurrentCollectionRate = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYearMonthlyCollectionbyType = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          sub_acct_type: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYearNonPerformingLoan = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
        },
        project: {
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
          report_date: 1,
        },
      };

      // Promise.all([
      //   getLoanListFilter(currentYear, abortControllerRef.current.signal),
      // ])
      //   .then(([currentYearlyData]: any) => {
      //     currentYearlyData.forEach((item: any) => {
      //       year_due_to_receive +=
      //         parseFloat(item.d0_principal_due_rm) +
      //         parseFloat(item.d0_profit_due_rm) +
      //         parseFloat(item.d1_principal_due_rm) +
      //         parseFloat(item.d1_profit_due_rm) +
      //         parseFloat(item.d2_principal_due_rm) +
      //         parseFloat(item.d2_profit_due_rm) +
      //         parseFloat(item.d3_principal_due_rm) +
      //         parseFloat(item.d3_profit_due_rm) +
      //         parseFloat(item.d4_principal_due_rm) +
      //         parseFloat(item.d4_profit_due_rm) +
      //         parseFloat(item.d5_principal_due_rm) +
      //         parseFloat(item.d5_profit_due_rm) +
      //         parseFloat(item.d6_principal_due_rm) +
      //         parseFloat(item.d6_profit_due_rm) +
      //         parseFloat(item.d7__principal_due_rm) +
      //         parseFloat(item.d7__profit_due_rm);

      //       year_collected +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       if (year_due_to_receive != 0) {
      //         year_collection_rate = year_collected / year_due_to_receive;
      //       } else {
      //         year_collection_rate = 0;
      //       }

      //       setYearlyDuetoReceive(year_due_to_receive);
      //       setYearlyCollected(year_collected);
      //       setYearlyCollectionRate(year_collection_rate);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setHPCollectionYearStatisticLoading(false);
      //   });

      // Promise.all([
      //   getLoanListFilter(
      //     currentYearCurrentCollectionRate,
      //     abortControllerRef.current.signal
      //   ),
      // ])
      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsSalesGrowth.set(month, {
      //         received_total: 0,
      //         collected_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       if (!totalsSalesGrowth.has(monthName)) {
      //         totalsSalesGrowth.set(monthName, {
      //           received_total: 0,
      //           collected_total: 0,
      //         });
      //       }

      //       const totals = totalsSalesGrowth.get(monthName);
      //       if (totals) {
      //         totals.received_total +=
      //           parseFloat(item.d0_principal_due_rm) +
      //           parseFloat(item.d0_profit_due_rm) +
      //           parseFloat(item.d1_principal_due_rm) +
      //           parseFloat(item.d1_profit_due_rm) +
      //           parseFloat(item.d2_principal_due_rm) +
      //           parseFloat(item.d2_profit_due_rm) +
      //           parseFloat(item.d3_principal_due_rm) +
      //           parseFloat(item.d3_profit_due_rm) +
      //           parseFloat(item.d4_principal_due_rm) +
      //           parseFloat(item.d4_profit_due_rm) +
      //           parseFloat(item.d5_principal_due_rm) +
      //           parseFloat(item.d5_profit_due_rm) +
      //           parseFloat(item.d6_principal_due_rm) +
      //           parseFloat(item.d6_profit_due_rm) +
      //           parseFloat(item.d7__principal_due_rm) +
      //           parseFloat(item.d7__profit_due_rm);
      //         totals.collected_total +=
      //           parseFloat(item.d0_collected_principal_rm) +
      //           parseFloat(item.d0_collected_profit_rm) +
      //           parseFloat(item.d1_collected_principal_rm) +
      //           parseFloat(item.d1_collected_profit_rm) +
      //           parseFloat(item.d2_collected_principal_rm) +
      //           parseFloat(item.d2_collected_profit_rm) +
      //           parseFloat(item.d3_collected_principal_rm) +
      //           parseFloat(item.d3_collected_profit_rm) +
      //           parseFloat(item.d4_collected_principal_rm) +
      //           parseFloat(item.d4_collected_profit_rm) +
      //           parseFloat(item.d5_collected_principal_rm) +
      //           parseFloat(item.d5_collected_profit_rm) +
      //           parseFloat(item.d6_collected_principal_rm) +
      //           parseFloat(item.d6_collected_profit_rm) +
      //           parseFloat(item.d7__collected_principal_rm) +
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       const salesgrowthresult: {
      //         month: string;
      //         ratio: number;
      //       }[] = Array.from(totalsSalesGrowth.entries()).map(
      //         ([month, { received_total, collected_total }]: any) => ({
      //           month,
      //           ratio:
      //             collected_total !== 0 ? collected_total / received_total : 0,
      //         })
      //       );

      //       setYearlyCurrentCollectionRate(salesgrowthresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setHPCollectionYearCollectionRateLoading(false);
      //   });

      // Promise.all([
      //   getLoanListFilter(
      //     currentYearMonthlyCollectionbyType,
      //     abortControllerRef.current.signal
      //   ),
      // ])

      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();
      //     const totalsMonthLoan: any = new Map();
      //     const totalsNPL: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const subAcctType = item.sub_acct_type;

      //       if (!totalsMonthLoan.has(subAcctType)) {
      //         totalsMonthLoan.set(subAcctType, Array(12).fill(0));
      //       }

      //       const currentTotals = totalsMonthLoan.get(subAcctType)!;
      //       currentTotals[monthIndex] +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       const monthloanresult: {
      //         sub_type_acct: string;
      //         total: number[];
      //       }[] = Array.from(totalsMonthLoan.entries()).map(
      //         ([sub_type_acct, total]: any) => ({
      //           sub_type_acct,
      //           total,
      //         })
      //       );
      //       monthloanresult.sort((a: any, b: any) =>
      //         a.sub_type_acct.localeCompare(b.sub_type_acct)
      //       );

      //       setYearlyMonthlyCollection(monthloanresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setHPCollectionYearMonthCollectionLoading(false);
      //   });

      // Promise.all([
      //   getLoanListFilter(
      //     currentYearNonPerformingLoan,
      //     abortControllerRef.current.signal
      //   ),
      // ])
      //   .then(([data]: any) => {
      //     const totalsNPL: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsNPL.set(month, {
      //         receivable_total: 0,
      //         balance_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       let year_d0_aging_principal_balance = 0;
      //       let year_d0_aging_profit_balance = 0;
      //       let year_d1_aging_principal_balance = 0;
      //       let year_d1_aging_profit_balance = 0;
      //       let year_d2_aging_principal_balance = 0;
      //       let year_d2_aging_profit_balance = 0;
      //       let year_d3_aging_principal_balance = 0;
      //       let year_d3_aging_profit_balance = 0;
      //       let year_d4_aging_principal_balance = 0;
      //       let year_d4_aging_profit_balance = 0;
      //       let year_d5_aging_principal_balance = 0;
      //       let year_d5_aging_profit_balance = 0;
      //       let year_d6_aging_principal_balance = 0;
      //       let year_d6_aging_profit_balance = 0;
      //       let year_d7_aging_principal_balance = 0;
      //       let year_d7_aging_profit_balance = 0;

      //       if (item.d1 == 1) {
      //         year_d1_aging_principal_balance =
      //           parseFloat(item.d1_principal_due_rm) -
      //           parseFloat(item.d1_collected_principal_rm);
      //         year_d1_aging_profit_balance =
      //           parseFloat(item.d1_profit_due_rm) -
      //           parseFloat(item.d1_collected_profit_rm);
      //       }

      //       if (item.d2 == 1) {
      //         year_d2_aging_principal_balance =
      //           parseFloat(item.d2_principal_due_rm) -
      //           parseFloat(item.d2_collected_principal_rm);
      //         year_d2_aging_profit_balance =
      //           parseFloat(item.d2_profit_due_rm) -
      //           parseFloat(item.d2_collected_profit_rm);
      //       }

      //       if (item.d3 == 1) {
      //         year_d3_aging_principal_balance =
      //           parseFloat(item.d3_principal_due_rm) -
      //           parseFloat(item.d3_collected_principal_rm);
      //         year_d3_aging_profit_balance =
      //           parseFloat(item.d3_profit_due_rm) -
      //           parseFloat(item.d3_collected_profit_rm);
      //       }

      //       if (item.d4 == 1) {
      //         year_d4_aging_principal_balance =
      //           parseFloat(item.d4_principal_due_rm) -
      //           parseFloat(item.d4_collected_principal_rm);
      //         year_d4_aging_profit_balance =
      //           parseFloat(item.d4_profit_due_rm) -
      //           parseFloat(item.d4_collected_profit_rm);
      //       }

      //       if (item.d5 == 1) {
      //         year_d5_aging_principal_balance =
      //           parseFloat(item.d5_principal_due_rm) -
      //           parseFloat(item.d5_collected_principal_rm);
      //         year_d5_aging_profit_balance =
      //           parseFloat(item.d5_profit_due_rm) -
      //           parseFloat(item.d5_collected_profit_rm);
      //       }

      //       if (item.d6 == 1) {
      //         year_d6_aging_principal_balance =
      //           parseFloat(item.d6_principal_due_rm) -
      //           parseFloat(item.d6_collected_principal_rm);
      //         year_d6_aging_profit_balance =
      //           parseFloat(item.d6_profit_due_rm) -
      //           parseFloat(item.d6_collected_profit_rm);
      //       }

      //       if (item.d7_ == 1) {
      //         year_d7_aging_principal_balance =
      //           parseFloat(item.d7__principal_due_rm) -
      //           parseFloat(item.d7__collected_principal_rm);
      //         year_d7_aging_profit_balance =
      //           parseFloat(item.d7__profit_due_rm) -
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       year_d0_aging_principal_balance =
      //         parseFloat(item.principal_balance_rm) -
      //         (parseFloat(item.d1_principal_due_rm) -
      //           parseFloat(item.d1_collected_principal_rm)) -
      //         (parseFloat(item.d2_principal_due_rm) -
      //           parseFloat(item.d2_collected_principal_rm)) -
      //         (parseFloat(item.d3_principal_due_rm) -
      //           parseFloat(item.d3_collected_principal_rm)) -
      //         (parseFloat(item.d4_principal_due_rm) -
      //           parseFloat(item.d4_collected_principal_rm)) -
      //         (parseFloat(item.d5_principal_due_rm) -
      //           parseFloat(item.d5_collected_principal_rm)) -
      //         (parseFloat(item.d6_principal_due_rm) -
      //           parseFloat(item.d6_collected_principal_rm)) -
      //         (parseFloat(item.d7__principal_due_rm) -
      //           parseFloat(item.d7__collected_principal_rm));

      //       year_d0_aging_profit_balance =
      //         parseFloat(item.profit_balance_rm) -
      //         (parseFloat(item.d1_profit_due_rm) -
      //           parseFloat(item.d1_collected_profit_rm)) -
      //         (parseFloat(item.d2_profit_due_rm) -
      //           parseFloat(item.d2_collected_profit_rm)) -
      //         (parseFloat(item.d3_profit_due_rm) -
      //           parseFloat(item.d3_collected_profit_rm)) -
      //         (parseFloat(item.d4_profit_due_rm) -
      //           parseFloat(item.d4_collected_profit_rm)) -
      //         (parseFloat(item.d5_profit_due_rm) -
      //           parseFloat(item.d5_collected_profit_rm)) -
      //         (parseFloat(item.d6_profit_due_rm) -
      //           parseFloat(item.d6_collected_profit_rm)) -
      //         (parseFloat(item.d7__profit_due_rm) -
      //           parseFloat(item.d7__collected_profit_rm));

      //       if (!totalsNPL.has(monthName)) {
      //         totalsNPL.set(monthName, {
      //           receivable_total: 0,
      //           balance_total: 0,
      //         });
      //       }

      //       const totalNPL = totalsNPL.get(monthName);
      //       if (totalNPL) {
      //         totalNPL.receivable_total +=
      //           year_d0_aging_principal_balance +
      //           year_d0_aging_profit_balance +
      //           year_d1_aging_principal_balance +
      //           year_d1_aging_profit_balance +
      //           year_d2_aging_principal_balance +
      //           year_d2_aging_profit_balance +
      //           year_d3_aging_principal_balance +
      //           year_d3_aging_profit_balance +
      //           year_d4_aging_principal_balance +
      //           year_d4_aging_profit_balance +
      //           year_d5_aging_principal_balance +
      //           year_d5_aging_profit_balance +
      //           year_d6_aging_principal_balance +
      //           year_d6_aging_profit_balance +
      //           year_d7_aging_principal_balance +
      //           year_d7_aging_profit_balance;
      //         totalNPL.balance_total +=
      //           year_d4_aging_principal_balance +
      //           year_d4_aging_profit_balance +
      //           year_d5_aging_principal_balance +
      //           year_d5_aging_profit_balance +
      //           year_d6_aging_principal_balance +
      //           year_d6_aging_profit_balance +
      //           year_d7_aging_principal_balance +
      //           year_d7_aging_profit_balance;
      //       }

      //       const monthNPL: {
      //         month: string;
      //         receivable_total: number;
      //         ratio: number;
      //       }[] = Array.from(totalsNPL.entries()).map(
      //         ([month, { receivable_total, balance_total }]: any) => ({
      //           month,
      //           receivable_total,
      //           ratio:
      //             balance_total !== 0 ? balance_total / receivable_total : 0,
      //         })
      //       );
      //       setYearlyNonPerformingLoan(monthNPL);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setHPCollectionYearNonPerformingLoanLoading(false);
      //   });

      getLoanListFilter(
        selectedYearQuery,
        abortControllerRef.current.signal
      ).then((data: any) => {
        if (data && Array.isArray(data)) {
          // Ensure that data is not null or undefined and is an array
          const YearlyStatisticsPromise = new Promise(
            (resolve: any, reject: any) => {
              data.forEach((item: any) => {
                year_due_to_receive +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm) +
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm) +
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm) +
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm) +
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm) +
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm) +
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm) +
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);

                year_collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                if (year_due_to_receive != 0) {
                  year_collection_rate = year_collected / year_due_to_receive;
                } else {
                  year_collection_rate = 0;
                }

                setYearlyDuetoReceive(year_due_to_receive);
                setYearlyCollected(year_collected);
                setYearlyCollectionRate(year_collection_rate);
              });
              resolve();
              setHPCollectionYearStatisticLoading(false);
            }
          );
          const YearlyCollectionRatesPromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsSalesGrowth: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              months.forEach((month) => {
                totalsSalesGrowth.set(month, {
                  received_total: 0,
                  collected_total: 0,
                });
              });
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const monthName = months[monthIndex];

                if (!totalsSalesGrowth.has(monthName)) {
                  totalsSalesGrowth.set(monthName, {
                    received_total: 0,
                    collected_total: 0,
                  });
                }

                const totals = totalsSalesGrowth.get(monthName);
                if (totals) {
                  totals.received_total +=
                    parseFloat(item.d0_principal_due_rm) +
                    parseFloat(item.d0_profit_due_rm) +
                    parseFloat(item.d1_principal_due_rm) +
                    parseFloat(item.d1_profit_due_rm) +
                    parseFloat(item.d2_principal_due_rm) +
                    parseFloat(item.d2_profit_due_rm) +
                    parseFloat(item.d3_principal_due_rm) +
                    parseFloat(item.d3_profit_due_rm) +
                    parseFloat(item.d4_principal_due_rm) +
                    parseFloat(item.d4_profit_due_rm) +
                    parseFloat(item.d5_principal_due_rm) +
                    parseFloat(item.d5_profit_due_rm) +
                    parseFloat(item.d6_principal_due_rm) +
                    parseFloat(item.d6_profit_due_rm) +
                    parseFloat(item.d7__principal_due_rm) +
                    parseFloat(item.d7__profit_due_rm);
                  totals.collected_total +=
                    parseFloat(item.d0_collected_principal_rm) +
                    parseFloat(item.d0_collected_profit_rm) +
                    parseFloat(item.d1_collected_principal_rm) +
                    parseFloat(item.d1_collected_profit_rm) +
                    parseFloat(item.d2_collected_principal_rm) +
                    parseFloat(item.d2_collected_profit_rm) +
                    parseFloat(item.d3_collected_principal_rm) +
                    parseFloat(item.d3_collected_profit_rm) +
                    parseFloat(item.d4_collected_principal_rm) +
                    parseFloat(item.d4_collected_profit_rm) +
                    parseFloat(item.d5_collected_principal_rm) +
                    parseFloat(item.d5_collected_profit_rm) +
                    parseFloat(item.d6_collected_principal_rm) +
                    parseFloat(item.d6_collected_profit_rm) +
                    parseFloat(item.d7__collected_principal_rm) +
                    parseFloat(item.d7__collected_profit_rm);
                }

                const salesgrowthresult: {
                  month: string;
                  ratio: number;
                }[] = Array.from(totalsSalesGrowth.entries()).map(
                  ([month, { received_total, collected_total }]: any) => ({
                    month,
                    ratio:
                      collected_total !== 0
                        ? collected_total / received_total
                        : 0,
                  })
                );

                setYearlyCurrentCollectionRate(salesgrowthresult);
              });
              resolve();
              setHPCollectionYearCollectionRateLoading(false);
            }
          );
          Promise.all([YearlyStatisticsPromise, YearlyCollectionRatesPromise])
            .then(() => {})
            .catch((error: any) => {
              // Handle any errors from any of the groups
              console.error(error);
            })
            .finally(() => {});
        } else {
          console.error("Invalid data received");
        }
      });
    }
  };

  const handleBarStackYear = async (selected_year: any) => {
    setMonthLoanLoading(true);
    // const StartSelectedYear = dayjs(`${selected_year}-01-01`)
    //   .startOf("year")
    //   .format("YYYY-MM-DD");
    // const EndSelectedYear = dayjs(`${selected_year}-12-31`)
    //   .endOf("year")
    //   .format("YYYY-MM-DD");
    const StartSelectedYear = dayjs(`${selected_year}-01-01`)
      .startOf("year")
      .toDate()
      .toISOString(); // Convert to ISO format for MongoDB

    const EndSelectedYear = dayjs(`${selected_year}-12-31`)
      .endOf("year")
      .toDate()
      .toISOString(); // Convert to ISO format for MongoDB
    // let monthloangroupby = ["principal_rm", "sub_acct_type", "report_date"];
    // let monthlyloanquery = {
    //   select: monthloangroupby.join(","),
    //   report_date: `gte.Date('${StartSelectedYear}').lte.Date('${EndSelectedYear}')`,
    // };
    const monthloanquery = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: { $gte: StartSelectedYear, $lte: EndSelectedYear },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
        report_date: 1,
      },
    };

    getLoanListFilter(monthloanquery).then((monthloanData: any) => {
      if (monthloanData && Array.isArray(monthloanData)) {
        // Ensure that data is not null or undefined and is an array
        const totalsMonthLoan: any = new Map();

        // Process each entry in the API data
        monthloanData.forEach((entry: any) => {
          const subAcctType = entry.sub_acct_type;
          const principalRm = parseFloat(entry.principal_rm);
          const reportDate = new Date(entry.report_date);
          const monthIndex = reportDate.getMonth(); // 0 for January, 11 for December

          // Initialize the total array if it doesn't exist
          if (!totalsMonthLoan.has(subAcctType)) {
            totalsMonthLoan.set(subAcctType, Array(12).fill(0));
          }

          // Update the total for the corresponding month
          const currentTotals = totalsMonthLoan.get(subAcctType)!;
          currentTotals[monthIndex] += principalRm;
        });
        // Convert the totals map to the desired format
        const monthloanresult: { sub_type_acct: string; total: number[] }[] =
          Array.from(totalsMonthLoan.entries()).map(
            ([sub_type_acct, total]: any) => ({
              sub_type_acct,
              total,
            })
          );
        monthloanresult.sort((a: any, b: any) =>
          b.sub_type_acct.localeCompare(a.sub_type_acct)
        );
        setBarStackData(monthloanresult);
      } else {
        console.error("Invalid data received");
      }
      setMonthLoanLoading(false);
    });
  };

  const handleBarGroupYear = async (selected_year: any) => {
    setSalesGrowthLoading(true);
    const StartSelectedYear: any = dayjs(`${selected_year}-01-01`)
      .startOf("year")
      .format("YYYY-MM-DD");
    const EndSelectedYear: any = dayjs(`${selected_year}-12-31`)
      .endOf("year")
      .format("YYYY-MM-DD");
    // const StartSelectedYearISO: any = dayjs(`${selected_year}-01-01`)
    //   .startOf("year")
    //   .toDate()
    //   .toISOString();
    const StartSelectedYearISO: any = dayjs(`${selected_year - 1}-12-01`)
      .startOf("day")
      .toDate()
      .toISOString();

    const EndSelectedYearISO: any = dayjs(`${selected_year}-12-31`)
      .endOf("year")
      .toDate()
      .toISOString();

    let monthlyloanquery = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: {
          $gte: StartSelectedYearISO,
          $lte: EndSelectedYearISO,
        },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
        report_date: 1,
      },
    };

    getLoanListFilter(monthlyloanquery).then((salesgrowthData: any) => {
      if (salesgrowthData && Array.isArray(salesgrowthData)) {
        // Ensure that data is not null or undefined and is an array
        const totalsSalesGrowth: any = new Map();

        // Process each entry in the API data
        salesgrowthData.forEach((entry: any) => {
          const subAcctType = entry.sub_acct_type;
          const principalRm = parseFloat(entry.principal_rm);
          const reportDate = new Date(entry.report_date);
          const monthIndex = reportDate.getMonth(); // 0 for January, 11 for December
          const year = reportDate.getFullYear(); // Get the year of the report
          // Initialize the total array if it doesn't exist
          if (!totalsSalesGrowth.has(`${subAcctType}~${year}`)) {
            totalsSalesGrowth.set(`${subAcctType}~${year}`, Array(12).fill(0));
          }

          // Update the total for the corresponding month
          const currentTotals = totalsSalesGrowth.get(
            `${subAcctType}~${year}`
          )!;
          currentTotals[monthIndex] += principalRm;
        });
        // Convert the totals map to the desired format
        const salesgrowthresult: {
          sub_type_acct: string;
          total: number[];
        }[] = Array.from(totalsSalesGrowth.entries()).map(
          ([sub_type_acct, total]: any) => ({
            sub_type_acct,
            total,
          })
        );
        salesgrowthresult.sort((a: any, b: any) =>
          a.sub_type_acct.localeCompare(b.sub_type_acct)
        );

        // Add previous year's last value to the current year's first element
        salesgrowthresult.forEach(({ sub_type_acct, total }) => {
          // Extract the year from the sub_type_acct (e.g., 'PF-i-2023' -> '2023')
          const currentYear = sub_type_acct.split("~")[1]; // Get the year part (e.g., '2023' or '2024')
          const previousYear = (parseInt(currentYear) - 1).toString(); // Get the previous year
          const previousYearSubAcct = sub_type_acct.replace(
            currentYear,
            previousYear
          ); // Modify sub_type_acct for the previous year

          // Find the corresponding entry for the previous year
          const previousYearIndex = salesgrowthresult.findIndex(
            (entry) => entry.sub_type_acct === previousYearSubAcct
          );

          if (previousYearIndex !== -1) {
            const previousYearData = salesgrowthresult[previousYearIndex];
            const lastMonthValue =
              previousYearData.total[previousYearData.total.length - 1];

            // Prepend the last month's value of the previous year to the current year
            total.unshift(lastMonthValue);

            // Remove the previous year data after adding it
            salesgrowthresult.splice(previousYearIndex, 1);
          }
        });

        // Create a new array to hold the profit percentages
        const profitPercentageResults = salesgrowthresult.map(
          ({ sub_type_acct, total }) => {
            const profitPercentages = [];
            // Remove the year part (e.g., '-2024') from sub_type_acct
            const cleanedSubAcct = sub_type_acct.replace(/~\d{4}$/, ""); // This removes the last '-YYYY' part
            for (let month = 1; month < total.length; month++) {
              const lastMonthTotal = total[month - 1];
              const currentMonthTotal = total[month];
              const profitPercentage = calculateProfitPercentage(
                lastMonthTotal,
                currentMonthTotal
              );
              profitPercentages.push(profitPercentage);
            }

            return {
              sub_type_acct: cleanedSubAcct,
              profitPercentages,
            };
          }
        );
        setBarGroupData(profitPercentageResults);
      } else {
        console.error("Invalid data received");
      }
      setSalesGrowthLoading(false);
    });
  };

  const handleSelectedDate = (date: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the current operation
    abortControllerRef.current = new AbortController();
    setMonthLoading(true);
    const firstDayOfMonth: any = dayjs(date)
      .startOf("month")
      .format("YYYY-MM-DD");
    const firstDayofMonthISO = dayjs(date)
      .startOf("month")
      .add(1, "day")
      .utc()
      .startOf("day")
      .toDate()
      .toISOString();
    setSelectFilteredDate(firstDayofMonthISO);
    const firstDayOfPreviousMonth: any = dayjs(date)
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const firstDayOfPreviousMonthISO = dayjs(date)
      .subtract(1, "month")
      .add(1, "day")
      .utc()
      .startOf("day")
      .toDate()
      .toISOString();
    setSelectPreviousFilteredDate(firstDayOfPreviousMonthISO);
    setSelectedDate(dayjs(date).startOf("month"));
    if (selectedItem === "Sales by product") {
      setMonthLoading(true);
      let current_month_total_loan = 0;
      let current_month_new_loan = 0;
      let current_month_new_customer = 0;
      let current_month_total_LT = 0;
      let current_month_total_PFI = 0;
      let current_month_total_CLI = 0;
      let current_month_total_HP = 0;
      let current_month_total_new_customer = 0;
      let current_month_total_existing_customer = 0;
      let current_month_total_returning_customer = 0;
      let previous_month_total_loan = 0;
      let previous_month_new_loan = 0;
      let previous_month_new_customer = 0;
      let previous_month_existing_customer = 0;
      let previous_month_returning_customer = 0;
      let previous_month_total_customer = 0;
      let current_month_total_customer = 0;

      let latestcompanytarget = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: firstDayofMonthISO,
        },
        project: {
          principal_rm: 1,
          customer_status: 1,
          loan_status: 1,
          sub_acct_type: 1,
        },
      };

      let previouscompanytarget = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: firstDayOfPreviousMonthISO,
        },
        project: {
          principal_rm: 1,
          customer_status: 1,
          loan_status: 1,
          sub_acct_type: 1,
        },
      };

      Promise.all([
        getLoanListFilter(
          latestcompanytarget,
          abortControllerRef.current.signal
        ),
        getLoanListFilter(
          previouscompanytarget,
          abortControllerRef.current.signal
        ),
      ])
        .then(([currentData, previousData]: any) => {
          if (
            currentData &&
            Array.isArray(currentData) &&
            previousData &&
            Array.isArray(previousData)
          ) {
            // Ensure that data is not null or undefined and is an array
            currentData.forEach((item: any) => {
              current_month_total_loan += parseFloat(item.principal_rm);
              if (item.loan_status === "new") {
                current_month_new_loan += 1;
              }
              if (item.customer_status === "new") {
                current_month_new_customer += 1;
                current_month_total_new_customer += 1;
              } else if (item.customer_status === "existing") {
                current_month_total_existing_customer += 1;
              } else if (item.customer_status === "returning") {
                current_month_total_returning_customer += 1;
              }
              if (item.sub_acct_type === "CL-i") {
                current_month_total_CLI += parseFloat(item.principal_rm);
              } else if (item.sub_acct_type === "PF-i") {
                current_month_total_PFI += parseFloat(item.principal_rm);
              } else if (item.sub_acct_type === "LT") {
                current_month_total_LT += parseFloat(item.principal_rm);
              } else if (item.sub_acct_type === "HP") {
                current_month_total_HP += parseFloat(item.principal_rm);
              }
            });

            previousData.forEach((item: any) => {
              previous_month_total_loan += parseFloat(item.principal_rm);
              if (item.loan_status === "new") {
                previous_month_new_loan += 1;
              }
              if (item.customer_status === "new") {
                previous_month_new_customer += 1;
              } else if (item.customer_status === "existing") {
                previous_month_existing_customer += 1;
              } else if (item.customer_status === "returning") {
                previous_month_returning_customer += 1;
              }
            });

            previous_month_total_customer =
              previous_month_new_customer +
              previous_month_existing_customer +
              previous_month_returning_customer;

            current_month_total_customer =
              current_month_total_new_customer +
              current_month_total_existing_customer +
              current_month_total_returning_customer;

            setTotalLoanLT(current_month_total_LT);
            setTotalLoanPFI(current_month_total_PFI);
            setTotalLoanCLI(current_month_total_CLI);
            setTotalLoanHP(current_month_total_HP);
            setTotalLoanNewCustomer(current_month_total_new_customer);
            setTotalLoanReturningCustomer(
              current_month_total_returning_customer
            );
            setTotalLoanExistingCustomer(current_month_total_existing_customer);
            setTotalLoanCustomer(current_month_total_customer);

            setTotalLoan(current_month_total_loan);
            setNewLoan(current_month_new_loan);
            setNewCustomer(current_month_new_customer);

            // Calculate percentages after setting both current and previous month data
            setTotalLoanPercentage(
              calculateProfitPercentage(
                previous_month_total_loan,
                current_month_total_loan
              )
            );
            setNewLoanPercentage(
              calculateProfitPercentage(
                previous_month_new_loan,
                current_month_new_loan
              )
            );
            setNewCustomerPercentage(
              calculateProfitPercentage(
                previous_month_new_customer,
                current_month_new_customer
              )
            );
            setTotalLoanDifference(
              current_month_total_loan - previous_month_total_loan
            );

            setTotalCustomerPercentage(
              calculateProfitPercentage(
                previous_month_total_customer,
                current_month_total_customer
              )
            );
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error) => {})
        .finally(() => {
          setMonthLoading(false);
        });
    } else if (selectedItem === "Collection by product") {
      if (selectedSubItem === "Money Lending") {
        setMLCollectionMonthStatisticLoading(true);
        setMLCollectionMonthCollectionLoading(true);
        setMLCollectionMonthAgingBucketLoading(true);
        let due_to_receive = 0;
        let collected = 0;
        let previous_collected = 0;
        let collected_percentage = 0;
        let collection_rate = 0;
        let advance = 0;
        let full_settlement = 0;
        let d0_aging_principal_balance = 0;
        let d0_aging_profit_balance = 0;
        let d1_aging_principal_balance = 0;
        let d1_aging_profit_balance = 0;
        let d2_aging_principal_balance = 0;
        let d2_aging_profit_balance = 0;
        let d3_aging_principal_balance = 0;
        let d3_aging_profit_balance = 0;
        let d4_aging_principal_balance = 0;
        let d4_aging_profit_balance = 0;
        let d5_aging_principal_balance = 0;
        let d5_aging_profit_balance = 0;
        let d6_aging_principal_balance = 0;
        let d6_aging_profit_balance = 0;
        let d7_aging_principal_balance = 0;
        let d7_aging_profit_balance = 0;
        let collection_by_category: any = {
          D0: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D1: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D2: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D3: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D4: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D5: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D6: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          "D7+": { due_for_the_month: 0, collected: 0, collection_rate: 0 },
        };
        let upcoming = 0;
        let past_due_1_30 = 0;
        let past_due_31_60 = 0;
        let past_due_61_90 = 0;
        let more_than_91 = 0;

        let selectedMonthstatisics = {
          filter: {
            companyid: { $in: company_access },
            branchid: { $in: branch_access },
            sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
            report_date: firstDayofMonthISO,
          },
          project: {
            d0_principal_due_rm: 1,
            d0_profit_due_rm: 1,
            d0_collected_principal_rm: 1,
            d0_collected_profit_rm: 1,
            d1_principal_due_rm: 1,
            d1_profit_due_rm: 1,
            d1_collected_principal_rm: 1,
            d1_collected_profit_rm: 1,
            d2_principal_due_rm: 1,
            d2_profit_due_rm: 1,
            d2_collected_principal_rm: 1,
            d2_collected_profit_rm: 1,
            d3_principal_due_rm: 1,
            d3_profit_due_rm: 1,
            d3_collected_principal_rm: 1,
            d3_collected_profit_rm: 1,
            d4_principal_due_rm: 1,
            d4_profit_due_rm: 1,
            d4_collected_principal_rm: 1,
            d4_collected_profit_rm: 1,
            d5_principal_due_rm: 1,
            d5_profit_due_rm: 1,
            d5_collected_principal_rm: 1,
            d5_collected_profit_rm: 1,
            d6_principal_due_rm: 1,
            d6_profit_due_rm: 1,
            d6_collected_principal_rm: 1,
            d6_collected_profit_rm: 1,
            d7__principal_due_rm: 1,
            d7__profit_due_rm: 1,
            d7__collected_principal_rm: 1,
            d7__collected_profit_rm: 1,
            principal_collected_in_advance_rm: 1,
            profit_collected_in_advance_rm: 1,
            settlement_principal_rm: 1,
            settlement_profit_rm: 1,
          },
        };

        let selectedMonthCollectionbyCategory = {
          filter: {
            companyid: { $in: company_access },
            branchid: { $in: branch_access },
            sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
            report_date: firstDayofMonthISO,
          },
          project: {
            d0_principal_due_rm: 1,
            d0_profit_due_rm: 1,
            d0_collected_principal_rm: 1,
            d0_collected_profit_rm: 1,
            d1_principal_due_rm: 1,
            d1_profit_due_rm: 1,
            d1_collected_principal_rm: 1,
            d1_collected_profit_rm: 1,
            d2_principal_due_rm: 1,
            d2_profit_due_rm: 1,
            d2_collected_principal_rm: 1,
            d2_collected_profit_rm: 1,
            d3_principal_due_rm: 1,
            d3_profit_due_rm: 1,
            d3_collected_principal_rm: 1,
            d3_collected_profit_rm: 1,
            d4_principal_due_rm: 1,
            d4_profit_due_rm: 1,
            d4_collected_principal_rm: 1,
            d4_collected_profit_rm: 1,
            d5_principal_due_rm: 1,
            d5_profit_due_rm: 1,
            d5_collected_principal_rm: 1,
            d5_collected_profit_rm: 1,
            d6_principal_due_rm: 1,
            d6_profit_due_rm: 1,
            d6_collected_principal_rm: 1,
            d6_collected_profit_rm: 1,
            d7__principal_due_rm: 1,
            d7__profit_due_rm: 1,
            d7__collected_principal_rm: 1,
            d7__collected_profit_rm: 1,
          },
        };

        let selectedMonthAgingBucket = {
          filter: {
            companyid: { $in: company_access },
            branchid: { $in: branch_access },
            sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
            report_date: firstDayofMonthISO,
          },
          project: {
            principal_balance_rm: 1,
            profit_balance_rm: 1,
            d0_principal_due_rm: 1,
            d0_profit_due_rm: 1,
            d0_collected_principal_rm: 1,
            d0_collected_profit_rm: 1,
            d1_principal_due_rm: 1,
            d1_profit_due_rm: 1,
            d1_collected_principal_rm: 1,
            d1_collected_profit_rm: 1,
            d2_principal_due_rm: 1,
            d2_profit_due_rm: 1,
            d2_collected_principal_rm: 1,
            d2_collected_profit_rm: 1,
            d3_principal_due_rm: 1,
            d3_profit_due_rm: 1,
            d3_collected_principal_rm: 1,
            d3_collected_profit_rm: 1,
            d4_principal_due_rm: 1,
            d4_profit_due_rm: 1,
            d4_collected_principal_rm: 1,
            d4_collected_profit_rm: 1,
            d5_principal_due_rm: 1,
            d5_profit_due_rm: 1,
            d5_collected_principal_rm: 1,
            d5_collected_profit_rm: 1,
            d6_principal_due_rm: 1,
            d6_profit_due_rm: 1,
            d6_collected_principal_rm: 1,
            d6_collected_profit_rm: 1,
            d7__principal_due_rm: 1,
            d7__profit_due_rm: 1,
            d7__collected_principal_rm: 1,
            d7__collected_profit_rm: 1,
            d0: 1,
            d1: 1,
            d2: 1,
            d3: 1,
            d4: 1,
            d5: 1,
            d6: 1,
            d7_: 1,
          },
        };

        let previousMonth = {
          filter: {
            companyid: { $in: company_access },
            branchid: { $in: branch_access },
            sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
            report_date: firstDayOfPreviousMonthISO,
          },
          project: {
            d0_collected_principal_rm: 1,
            d0_collected_profit_rm: 1,
            d1_collected_principal_rm: 1,
            d1_collected_profit_rm: 1,
            d2_collected_principal_rm: 1,
            d2_collected_profit_rm: 1,
            d3_collected_principal_rm: 1,
            d3_collected_profit_rm: 1,
            d4_collected_principal_rm: 1,
            d4_collected_profit_rm: 1,
            d5_collected_principal_rm: 1,
            d5_collected_profit_rm: 1,
            d6_collected_principal_rm: 1,
            d6_collected_profit_rm: 1,
            d7__collected_principal_rm: 1,
            d7__collected_profit_rm: 1,
          },
        };

        Promise.all([
          getLoanListFilter(
            selectedMonthstatisics,
            abortControllerRef.current.signal
          ),
          getLoanListFilter(previousMonth, abortControllerRef.current.signal),
        ])
          .then(([monthlyData, previousmonthlyData]: any) => {
            if (
              monthlyData &&
              Array.isArray(monthlyData) &&
              previousmonthlyData &&
              Array.isArray(previousmonthlyData)
            ) {
              // Ensure that data is not null or undefined and is an array

              monthlyData.forEach((item: any) => {
                due_to_receive +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm) +
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm) +
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm) +
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm) +
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm) +
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm) +
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm) +
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);

                collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                advance +=
                  parseFloat(item.principal_collected_in_advance_rm) +
                  parseFloat(item.profit_collected_in_advance_rm);

                full_settlement +=
                  parseFloat(item.settlement_principal_rm) +
                  parseFloat(item.settlement_profit_rm);
              });

              previousmonthlyData.forEach((item: any) => {
                previous_collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);
              });

              collected_percentage = calculateProfitPercentage(
                previous_collected,
                collected
              );

              if (due_to_receive != 0) {
                collection_rate = collected / due_to_receive;
              } else {
                collection_rate = 0;
              }

              setMonthlyDuetoReceive(due_to_receive);
              setMonthlyCollected(collected);
              setMonthlyCollectionRate(collection_rate);
              setMonthlyCollectedPercentage(collected_percentage);
              setMonthlyAdvance(advance);
              setMonthlyFullSettlement(full_settlement);
            } else {
              console.error("Invalid data received");
            }
          })
          .catch((error: any) => {})
          .finally(() => {
            setMLCollectionMonthStatisticLoading(false);
          });

        Promise.all([
          getLoanListFilter(
            selectedMonthCollectionbyCategory,
            abortControllerRef.current.signal
          ),
        ])
          .then(([data]: any) => {
            if (data && Array.isArray(data)) {
              // Ensure that data is not null or undefined and is an array
              data.forEach((item: any) => {
                collection_by_category.D0.due_for_the_month +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm);
                collection_by_category.D0.collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm);
                collection_by_category.D1.due_for_the_month +=
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm);
                collection_by_category.D1.collected +=
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm);
                collection_by_category.D2.due_for_the_month +=
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm);
                collection_by_category.D2.collected +=
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm);
                collection_by_category.D3.due_for_the_month +=
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm);
                collection_by_category.D3.collected +=
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm);
                collection_by_category.D4.due_for_the_month +=
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm);
                collection_by_category.D4.collected +=
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm);
                collection_by_category.D5.due_for_the_month +=
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm);
                collection_by_category.D5.collected +=
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm);
                collection_by_category.D6.due_for_the_month +=
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm);
                collection_by_category.D6.collected +=
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm);
                collection_by_category["D7+"].due_for_the_month +=
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);
                collection_by_category["D7+"].collected +=
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);
              });

              for (const key in collection_by_category) {
                if (collection_by_category[key].due_for_the_month > 0) {
                  collection_by_category[key].collection_rate =
                    collection_by_category[key].collected /
                    collection_by_category[key].due_for_the_month;
                }
                collection_by_category[key].collected = Math.round(
                  collection_by_category[key].collected
                );
                collection_by_category[key].due_for_the_month = Math.round(
                  collection_by_category[key].due_for_the_month
                );
              }
            } else {
              console.error("Invalid data received");
            }
            setMonthlyCollectionbyCategory(collection_by_category);
          })
          .catch((error: any) => {})
          .finally(() => {
            setMLCollectionMonthCollectionLoading(false);
          });

        Promise.all([
          getLoanListFilter(
            selectedMonthAgingBucket,
            abortControllerRef.current.signal
          ),
        ])
          .then(([data]: any) => {
            if (data && Array.isArray(data)) {
              // Ensure that data is not null or undefined and is an array
              data.forEach((item: any) => {
                if (item.d1 === 1) {
                  d1_aging_principal_balance +=
                    parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm);
                  d1_aging_profit_balance +=
                    parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm);
                }

                if (item.d2 === 1) {
                  d2_aging_principal_balance +=
                    parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm);
                  d2_aging_profit_balance +=
                    parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm);
                }

                if (item.d3 === 1) {
                  d3_aging_principal_balance +=
                    parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm);
                  d3_aging_profit_balance +=
                    parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm);
                }

                if (item.d4 === 1) {
                  d4_aging_principal_balance +=
                    parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm);
                  d4_aging_profit_balance +=
                    parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm);
                }

                if (item.d5 === 1) {
                  d5_aging_principal_balance +=
                    parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm);
                  d5_aging_profit_balance +=
                    parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm);
                }

                if (item.d6 === 1) {
                  d6_aging_principal_balance +=
                    parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm);
                  d6_aging_profit_balance +=
                    parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm);
                }

                if (item.d7_ === 1) {
                  d7_aging_principal_balance +=
                    parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm);
                  d7_aging_profit_balance +=
                    parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm);
                }

                d0_aging_principal_balance +=
                  parseFloat(item.principal_balance_rm) -
                  (parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm)) -
                  (parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm)) -
                  (parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm)) -
                  (parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm)) -
                  (parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm)) -
                  (parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm)) -
                  (parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm));

                d0_aging_profit_balance +=
                  parseFloat(item.profit_balance_rm) -
                  (parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm)) -
                  (parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm)) -
                  (parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm)) -
                  (parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm)) -
                  (parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm)) -
                  (parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm)) -
                  (parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm));
              });

              upcoming = Math.round(
                d0_aging_principal_balance + d0_aging_profit_balance
              );
              past_due_1_30 = Math.round(
                d1_aging_principal_balance + d1_aging_profit_balance
              );
              past_due_31_60 = Math.round(
                d2_aging_principal_balance + d2_aging_profit_balance
              );
              past_due_61_90 = Math.round(
                d3_aging_principal_balance + d3_aging_profit_balance
              );
              more_than_91 = Math.round(
                d4_aging_principal_balance +
                  d4_aging_profit_balance +
                  d5_aging_principal_balance +
                  d5_aging_profit_balance +
                  d6_aging_principal_balance +
                  d6_aging_profit_balance +
                  d7_aging_principal_balance +
                  d7_aging_profit_balance
              );

              let aging_bucket = [
                upcoming,
                past_due_1_30,
                past_due_31_60,
                past_due_61_90,
                more_than_91,
              ];

              setMonthlyAgingBuckets(aging_bucket);
            } else {
              console.error("Invalid data received");
            }
          })
          .catch((error: any) => {})
          .finally(() => {
            setMLCollectionMonthAgingBucketLoading(false);
          });
      } else if (selectedSubItem === "Hire Purchase") {
        setHPCollectionMonthStatisticLoading(true);
        setHPCollectionMonthCollectionLoading(true);
        setHPCollectionMonthAgingBucketLoading(true);
        let due_to_receive = 0;
        let collected = 0;
        let previous_collected = 0;
        let collected_percentage = 0;
        let collection_rate = 0;
        let advance = 0;
        let full_settlement = 0;
        let d0_aging_principal_balance = 0;
        let d0_aging_profit_balance = 0;
        let d1_aging_principal_balance = 0;
        let d1_aging_profit_balance = 0;
        let d2_aging_principal_balance = 0;
        let d2_aging_profit_balance = 0;
        let d3_aging_principal_balance = 0;
        let d3_aging_profit_balance = 0;
        let d4_aging_principal_balance = 0;
        let d4_aging_profit_balance = 0;
        let d5_aging_principal_balance = 0;
        let d5_aging_profit_balance = 0;
        let d6_aging_principal_balance = 0;
        let d6_aging_profit_balance = 0;
        let d7_aging_principal_balance = 0;
        let d7_aging_profit_balance = 0;
        let collection_by_category: any = {
          D0: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D1: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D2: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D3: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D4: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D5: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          D6: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
          "D7+": { due_for_the_month: 0, collected: 0, collection_rate: 0 },
        };
        let upcoming = 0;
        let past_due_1_30 = 0;
        let past_due_31_60 = 0;
        let past_due_61_90 = 0;
        let more_than_91 = 0;

        let selectedMonth = {
          filter: {
            companyid: { $in: company_access },
            branchid: { $in: branch_access },
            sub_acct_type: { $in: ["HP"] },
            report_date: firstDayofMonthISO,
          },
          project: {
            principal_balance_rm: 1,
            profit_balance_rm: 1,
            d0_principal_due_rm: 1,
            d0_profit_due_rm: 1,
            d0_collected_principal_rm: 1,
            d0_collected_profit_rm: 1,
            d1_principal_due_rm: 1,
            d1_profit_due_rm: 1,
            d1_collected_principal_rm: 1,
            d1_collected_profit_rm: 1,
            d2_principal_due_rm: 1,
            d2_profit_due_rm: 1,
            d2_collected_principal_rm: 1,
            d2_collected_profit_rm: 1,
            d3_principal_due_rm: 1,
            d3_profit_due_rm: 1,
            d3_collected_principal_rm: 1,
            d3_collected_profit_rm: 1,
            d4_principal_due_rm: 1,
            d4_profit_due_rm: 1,
            d4_collected_principal_rm: 1,
            d4_collected_profit_rm: 1,
            d5_principal_due_rm: 1,
            d5_profit_due_rm: 1,
            d5_collected_principal_rm: 1,
            d5_collected_profit_rm: 1,
            d6_principal_due_rm: 1,
            d6_profit_due_rm: 1,
            d6_collected_principal_rm: 1,
            d6_collected_profit_rm: 1,
            d7__principal_due_rm: 1,
            d7__profit_due_rm: 1,
            d7__collected_principal_rm: 1,
            d7__collected_profit_rm: 1,
            principal_collected_in_advance_rm: 1,
            profit_collected_in_advance_rm: 1,
            settlement_principal_rm: 1,
            settlement_profit_rm: 1,
            d0: 1,
            d1: 1,
            d2: 1,
            d3: 1,
            d4: 1,
            d5: 1,
            d6: 1,
            d7_: 1,
          },
        };

        let previousMonth = {
          filter: {
            companyid: { $in: company_access },
            branchid: { $in: branch_access },
            sub_acct_type: { $in: ["HP"] },
            report_date: firstDayOfPreviousMonthISO,
          },
          project: {
            d0_collected_principal_rm: 1,
            d0_collected_profit_rm: 1,
            d1_collected_principal_rm: 1,
            d1_collected_profit_rm: 1,
            d2_collected_principal_rm: 1,
            d2_collected_profit_rm: 1,
            d3_collected_principal_rm: 1,
            d3_collected_profit_rm: 1,
            d4_collected_principal_rm: 1,
            d4_collected_profit_rm: 1,
            d5_collected_principal_rm: 1,
            d5_collected_profit_rm: 1,
            d6_collected_principal_rm: 1,
            d6_collected_profit_rm: 1,
            d7__collected_principal_rm: 1,
            d7__collected_profit_rm: 1,
          },
        };

        Promise.all([
          getLoanListFilter(selectedMonth, abortControllerRef.current.signal),
          getLoanListFilter(previousMonth, abortControllerRef.current.signal),
        ])
          .then(([monthlyData, previousmonthlyData]: any) => {
            if (
              monthlyData &&
              Array.isArray(monthlyData) &&
              previousmonthlyData &&
              Array.isArray(previousmonthlyData)
            ) {
              // Ensure that data is not null or undefined and is an array
              monthlyData.forEach((item: any) => {
                due_to_receive +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm) +
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm) +
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm) +
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm) +
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm) +
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm) +
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm) +
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);

                collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                advance +=
                  parseFloat(item.principal_collected_in_advance_rm) +
                  parseFloat(item.profit_collected_in_advance_rm);

                full_settlement +=
                  parseFloat(item.settlement_principal_rm) +
                  parseFloat(item.settlement_profit_rm);

                if (item.d1 === 1) {
                  d1_aging_principal_balance +=
                    parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm);
                  d1_aging_profit_balance +=
                    parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm);
                }

                if (item.d2 === 1) {
                  d2_aging_principal_balance +=
                    parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm);
                  d2_aging_profit_balance +=
                    parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm);
                }

                if (item.d3 === 1) {
                  d3_aging_principal_balance +=
                    parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm);
                  d3_aging_profit_balance +=
                    parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm);
                }

                if (item.d4 === 1) {
                  d4_aging_principal_balance +=
                    parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm);
                  d4_aging_profit_balance +=
                    parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm);
                }

                if (item.d5 === 1) {
                  d5_aging_principal_balance +=
                    parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm);
                  d5_aging_profit_balance +=
                    parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm);
                }

                if (item.d6 === 1) {
                  d6_aging_principal_balance +=
                    parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm);
                  d6_aging_profit_balance +=
                    parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm);
                }

                if (item.d7_ === 1) {
                  d7_aging_principal_balance +=
                    parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm);
                  d7_aging_profit_balance +=
                    parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm);
                }

                d0_aging_principal_balance +=
                  parseFloat(item.principal_balance_rm) -
                  (parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm)) -
                  (parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm)) -
                  (parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm)) -
                  (parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm)) -
                  (parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm)) -
                  (parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm)) -
                  (parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm));

                d0_aging_profit_balance +=
                  parseFloat(item.profit_balance_rm) -
                  (parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm)) -
                  (parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm)) -
                  (parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm)) -
                  (parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm)) -
                  (parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm)) -
                  (parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm)) -
                  (parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm));

                collection_by_category.D0.due_for_the_month +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm);
                collection_by_category.D0.collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm);
                collection_by_category.D1.due_for_the_month +=
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm);
                collection_by_category.D1.collected +=
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm);
                collection_by_category.D2.due_for_the_month +=
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm);
                collection_by_category.D2.collected +=
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm);
                collection_by_category.D3.due_for_the_month +=
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm);
                collection_by_category.D3.collected +=
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm);
                collection_by_category.D4.due_for_the_month +=
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm);
                collection_by_category.D4.collected +=
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm);
                collection_by_category.D5.due_for_the_month +=
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm);
                collection_by_category.D5.collected +=
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm);
                collection_by_category.D6.due_for_the_month +=
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm);
                collection_by_category.D6.collected +=
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm);
                collection_by_category["D7+"].due_for_the_month +=
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);
                collection_by_category["D7+"].collected +=
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);
              });

              upcoming = Math.round(
                d0_aging_principal_balance + d0_aging_profit_balance
              );
              past_due_1_30 = Math.round(
                d1_aging_principal_balance + d1_aging_profit_balance
              );
              past_due_31_60 = Math.round(
                d2_aging_principal_balance + d2_aging_profit_balance
              );
              past_due_61_90 = Math.round(
                d3_aging_principal_balance + d3_aging_profit_balance
              );
              more_than_91 = Math.round(
                d4_aging_principal_balance +
                  d4_aging_profit_balance +
                  d5_aging_principal_balance +
                  d5_aging_profit_balance +
                  d6_aging_principal_balance +
                  d6_aging_profit_balance +
                  d7_aging_principal_balance +
                  d7_aging_profit_balance
              );

              let aging_bucket = [
                upcoming,
                past_due_1_30,
                past_due_31_60,
                past_due_61_90,
                more_than_91,
              ];

              setMonthlyAgingBuckets(aging_bucket);

              for (const key in collection_by_category) {
                if (collection_by_category[key].due_for_the_month > 0) {
                  collection_by_category[key].collection_rate =
                    collection_by_category[key].collected /
                    collection_by_category[key].due_for_the_month;
                }
                collection_by_category[key].collected = Math.round(
                  collection_by_category[key].collected
                );
                collection_by_category[key].due_for_the_month = Math.round(
                  collection_by_category[key].due_for_the_month
                );
              }

              setMonthlyCollectionbyCategory(collection_by_category);

              previousmonthlyData.forEach((item: any) => {
                previous_collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);
              });

              collected_percentage = calculateProfitPercentage(
                previous_collected,
                collected
              );

              if (due_to_receive != 0) {
                collection_rate = collected / due_to_receive;
              } else {
                collection_rate = 0;
              }

              setMonthlyDuetoReceive(due_to_receive);
              setMonthlyCollected(collected);
              setMonthlyCollectionRate(collection_rate);
              setMonthlyCollectedPercentage(collected_percentage);
              setMonthlyAdvance(advance);
              setMonthlyFullSettlement(full_settlement);
            } else {
              console.error("Invalid data received");
            }
          })
          .catch((error: any) => {})
          .finally(() => {
            setHPCollectionMonthStatisticLoading(false);
            setHPCollectionMonthCollectionLoading(false);
            setHPCollectionMonthAgingBucketLoading(false);
          });
      }
    }
  };

  const refresh_sales_by_product = async (
    currentdate: any,
    previousdate: any,
    signal: AbortSignal
  ) => {
    setMonthLoading(true);
    setMonthLoanLoading(true);
    setSalesGrowthLoading(true);
    setFinanceCardLoading(true);
    setFinancePieLoading(true);
    let current_month_total_loan = 0;
    let current_month_new_loan = 0;
    let current_month_new_customer = 0;
    let current_month_total_LT = 0;
    let current_month_total_PFI = 0;
    let current_month_total_CLI = 0;
    let current_month_total_HP = 0;
    let current_month_total_new_customer = 0;
    let current_month_total_existing_customer = 0;
    let current_month_total_returning_customer = 0;
    let previous_month_total_loan = 0;
    let previous_month_new_loan = 0;
    let previous_month_new_customer = 0;
    let previous_month_existing_customer = 0;
    let previous_month_returning_customer = 0;
    let previous_month_total_customer = 0;
    let current_month_total_customer = 0;
    let current_year_total_LTQ = 0;
    let current_year_total_PFIQ = 0;
    let current_year_total_CLIQ = 0;
    let current_year_total_HPQ = 0;
    let previous_year_total_LTQ = 0;
    let previous_year_total_PFIQ = 0;
    let previous_year_total_CLIQ = 0;
    let previous_year_total_HPQ = 0;
    let current_year_total_LT = 0;
    let current_year_total_PFI = 0;
    let current_year_total_CLI = 0;
    let current_year_total_HP = 0;
    let previous_year_total_LT = 0;
    let previous_year_total_PFI = 0;
    let previous_year_total_CLI = 0;
    let previous_year_total_HP = 0;

    let latestcompanytarget = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: currentdate,
      },
      project: {
        principal_rm: 1,
        customer_status: 1,
        sub_acct_type: 1,
        report_date: 1,
      },
    };

    let previouscompanytarget = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: previousdate,
      },
      project: {
        principal_rm: 1,
        customer_status: 1,
        sub_acct_type: 1,
        report_date: 1,
      },
    };

    let monthlyloanquery = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: { $gte: StartCurrentYearISO, $lte: EndCurrentYearISO },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
        report_date: 1,
      },
    };

    let salesgrowthquery = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: { $gte: StartBarGroupYearISO, $lte: EndBarGroupYearISO },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
        report_date: 1,
      },
    };

    let currentyearfinanceVolumeQ = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: {
          $gte: StartFinanceThisYearQISO,
          $lte: EndFinanceThisYearQISO,
        },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
      },
    };

    let lastyearfinanceVolumeQ = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: {
          $gte: StartFinanceLastYearQISO,
          $lte: EndFinanceLastYearQISO,
        },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
      },
    };

    let currentyearfinanceVolume = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: {
          $gte: StartFinanceThisYearISO,
          $lte: EndFinanceThisYearISO,
        },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
      },
    };

    let lastyearfinanceVolume = {
      filter: {
        companyid: { $in: company_access },
        branchid: { $in: branch_access },
        sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
        report_date: {
          $gte: StartFinanceLastYearISO,
          $lte: EndFinanceLastYearISO,
        },
      },
      project: {
        principal_rm: 1,
        sub_acct_type: 1,
      },
    };

    Promise.all([
      getLoanListFilter(latestcompanytarget, signal),
      getLoanListFilter(previouscompanytarget, signal),
    ])
      .then(([currentData, previousData]: any) => {
        if (
          currentData &&
          Array.isArray(currentData) &&
          previousData &&
          Array.isArray(previousData)
        ) {
          // Ensure that data is not null or undefined and is an array
          currentData.forEach((item: any) => {
            current_month_total_loan += parseFloat(item.principal_rm);
            if (item.loan_status === "new") {
              current_month_new_loan += 1;
            }
            if (item.customer_status === "new") {
              current_month_new_customer += 1;
              current_month_total_new_customer += 1;
            } else if (item.customer_status === "existing") {
              current_month_total_existing_customer += 1;
            } else if (item.customer_status === "returning") {
              current_month_total_returning_customer += 1;
            }
            if (item.sub_acct_type === "CL-i") {
              current_month_total_CLI += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "PF-i") {
              current_month_total_PFI += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "LT") {
              current_month_total_LT += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "HP") {
              current_month_total_HP += parseFloat(item.principal_rm);
            }
          });

          previousData.forEach((item: any) => {
            previous_month_total_loan += parseFloat(item.principal_rm);
            if (item.loan_status === "new") {
              previous_month_new_loan += 1;
            }
            if (item.customer_status === "new") {
              previous_month_new_customer += 1;
            } else if (item.customer_status === "existing") {
              previous_month_existing_customer += 1;
            } else if (item.customer_status === "returning") {
              previous_month_returning_customer += 1;
            }
          });

          previous_month_total_customer =
            previous_month_new_customer +
            previous_month_existing_customer +
            previous_month_returning_customer;

          current_month_total_customer =
            current_month_total_new_customer +
            current_month_total_existing_customer +
            current_month_total_returning_customer;

          setTotalLoanLT(current_month_total_LT);
          setTotalLoanPFI(current_month_total_PFI);
          setTotalLoanCLI(current_month_total_CLI);
          setTotalLoanHP(current_month_total_HP);
          setTotalLoanNewCustomer(current_month_total_new_customer);
          setTotalLoanReturningCustomer(current_month_total_returning_customer);
          setTotalLoanExistingCustomer(current_month_total_existing_customer);
          setTotalLoanCustomer(current_month_total_customer);

          setTotalLoan(current_month_total_loan);
          setNewLoan(current_month_new_loan);
          setNewCustomer(current_month_new_customer);

          // Calculate percentages after setting both current and previous month data
          setTotalLoanPercentage(
            calculateProfitPercentage(
              previous_month_total_loan,
              current_month_total_loan
            )
          );
          setNewLoanPercentage(
            calculateProfitPercentage(
              previous_month_new_loan,
              current_month_new_loan
            )
          );
          setNewCustomerPercentage(
            calculateProfitPercentage(
              previous_month_new_customer,
              current_month_new_customer
            )
          );
          setTotalLoanDifference(
            current_month_total_loan - previous_month_total_loan
          );

          setTotalCustomerPercentage(
            calculateProfitPercentage(
              previous_month_total_customer,
              current_month_total_customer
            )
          );
        } else {
          console.error("Invalid data received");
        }
        // setLoading(false);
      })
      .catch((error) => {})
      .finally(() => {
        setMonthLoading(false);
      });

    Promise.all([getLoanListFilter(monthlyloanquery, signal)])
      .then(([monthloanData]: any) => {
        if (monthloanData && Array.isArray(monthloanData)) {
          // Ensure that data is not null or undefined and is an array
          const totalsMonthLoan: any = new Map();

          // Process each entry in the API data
          monthloanData.forEach((entry: any) => {
            const subAcctType = entry.sub_acct_type;
            const principalRm = parseFloat(entry.principal_rm);
            const reportDate = new Date(entry.report_date);
            const monthIndex = reportDate.getMonth(); // 0 for January, 11 for December

            // Initialize the total array if it doesn't exist
            if (!totalsMonthLoan.has(subAcctType)) {
              totalsMonthLoan.set(subAcctType, Array(12).fill(0));
            }

            // Update the total for the corresponding month
            const currentTotals = totalsMonthLoan.get(subAcctType)!;
            currentTotals[monthIndex] += principalRm;
          });
          // Convert the totals map to the desired format
          const monthloanresult: { sub_type_acct: string; total: number[] }[] =
            Array.from(totalsMonthLoan.entries()).map(
              ([sub_type_acct, total]: any) => ({
                sub_type_acct,
                total,
              })
            );
          monthloanresult.sort((a: any, b: any) =>
            b.sub_type_acct.localeCompare(a.sub_type_acct)
          );

          setBarStackData(monthloanresult);
        } else {
          console.error("Invalid data received");
        }
      })
      .catch((error) => {})
      .finally(() => {
        setMonthLoanLoading(false);
      });

    Promise.all([getLoanListFilter(salesgrowthquery, signal)])
      .then(([salesgrowthData]: any) => {
        if (salesgrowthData && Array.isArray(salesgrowthData)) {
          // Ensure that data is not null or undefined and is an array
          const totalsSalesGrowth: any = new Map();

          // Process each entry in the API data
          salesgrowthData.forEach((entry: any) => {
            const subAcctType = entry.sub_acct_type;
            const principalRm = parseFloat(entry.principal_rm);
            const reportDate = new Date(entry.report_date);
            const monthIndex = reportDate.getMonth(); // 0 for January, 11 for December
            const year = reportDate.getFullYear(); // Get the year of the report
            // Initialize the total array if it doesn't exist
            if (!totalsSalesGrowth.has(`${subAcctType}~${year}`)) {
              totalsSalesGrowth.set(
                `${subAcctType}~${year}`,
                Array(12).fill(0)
              );
            }

            // Update the total for the corresponding month
            const currentTotals = totalsSalesGrowth.get(
              `${subAcctType}~${year}`
            )!;
            currentTotals[monthIndex] += principalRm;
          });
          // Convert the totals map to the desired format
          const salesgrowthresult: {
            sub_type_acct: string;
            total: number[];
          }[] = Array.from(totalsSalesGrowth.entries()).map(
            ([sub_type_acct, total]: any) => ({
              sub_type_acct,
              total,
            })
          );
          salesgrowthresult.sort((a: any, b: any) =>
            a.sub_type_acct.localeCompare(b.sub_type_acct)
          );

          // Add previous year's last value to the current year's first element
          salesgrowthresult.forEach(({ sub_type_acct, total }) => {
            // Extract the year from the sub_type_acct (e.g., 'PF-i-2023' -> '2023')
            const currentYear = sub_type_acct.split("~")[1]; // Get the year part (e.g., '2023' or '2024')
            const previousYear = (parseInt(currentYear) - 1).toString(); // Get the previous year
            const previousYearSubAcct = sub_type_acct.replace(
              currentYear,
              previousYear
            ); // Modify sub_type_acct for the previous year

            // Find the corresponding entry for the previous year
            const previousYearIndex = salesgrowthresult.findIndex(
              (entry) => entry.sub_type_acct === previousYearSubAcct
            );

            if (previousYearIndex !== -1) {
              const previousYearData = salesgrowthresult[previousYearIndex];
              const lastMonthValue =
                previousYearData.total[previousYearData.total.length - 1];

              // Prepend the last month's value of the previous year to the current year
              total.unshift(lastMonthValue);

              // Remove the previous year data after adding it
              salesgrowthresult.splice(previousYearIndex, 1);
            }
          });

          // Create a new array to hold the profit percentages
          const profitPercentageResults = salesgrowthresult.map(
            ({ sub_type_acct, total }) => {
              const profitPercentages = [];
              // Remove the year part (e.g., '-2024') from sub_type_acct
              const cleanedSubAcct = sub_type_acct.replace(/~\d{4}$/, ""); // This removes the last '-YYYY' part
              for (let month = 1; month < total.length; month++) {
                const lastMonthTotal = total[month - 1];
                const currentMonthTotal = total[month];
                const profitPercentage = calculateProfitPercentage(
                  lastMonthTotal,
                  currentMonthTotal
                );
                profitPercentages.push(profitPercentage);
              }

              return {
                sub_type_acct: cleanedSubAcct,
                profitPercentages,
              };
            }
          );

          // Sort the profitPercentageResults array based on sub_type_acct

          setBarGroupData(profitPercentageResults);
        } else {
          console.error("Invalid data received");
        }
      })
      .catch((error) => {})
      .finally(() => {
        setSalesGrowthLoading(false);
      });

    Promise.all([
      getLoanListFilter(currentyearfinanceVolumeQ, signal),
      getLoanListFilter(lastyearfinanceVolumeQ, signal),
    ])
      .then(([currentyearfinanceQData, lastyearfinanceQData]: any) => {
        if (
          (currentyearfinanceQData && Array.isArray(currentyearfinanceQData)) ||
          (lastyearfinanceQData && Array.isArray(lastyearfinanceQData))
        ) {
          // Ensure that data is not null or undefined and is an array
          currentyearfinanceQData.forEach((item: any) => {
            if (item.sub_acct_type === "CL-i") {
              current_year_total_CLIQ += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "PF-i") {
              current_year_total_PFIQ += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "LT") {
              current_year_total_LTQ += parseFloat(item.principal_rm);
            }
          });

          lastyearfinanceQData.forEach((item: any) => {
            if (item.sub_acct_type === "CL-i") {
              previous_year_total_CLIQ += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "PF-i") {
              previous_year_total_PFIQ += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "LT") {
              previous_year_total_LTQ += parseFloat(item.principal_rm);
            }
          });

          setCurrentYearTotalPFIQ(current_year_total_PFIQ);
          setCurrentYearTotalLTQ(current_year_total_LTQ);
          setCurrentYearTotalCLIQ(current_year_total_CLIQ);
          setPreviousYearTotalPFIQ(previous_year_total_PFIQ);
          setPreviousYearTotalLTQ(previous_year_total_LTQ);
          setPreviousYearTotalCLIQ(previous_year_total_CLIQ);
        } else {
          console.error("Invalid data received");
        }
      })
      .catch((error) => {})
      .finally(() => {
        setFinancePieLoading(false);
      });

    Promise.all([
      getLoanListFilter(currentyearfinanceVolume, signal),
      getLoanListFilter(lastyearfinanceVolume, signal),
    ])
      .then(([currentyearfinanceData, lastyearfinanceData]: any) => {
        if (
          currentyearfinanceData &&
          Array.isArray(currentyearfinanceData) &&
          lastyearfinanceData &&
          Array.isArray(lastyearfinanceData)
        ) {
          // Ensure that data is not null or undefined and is an array
          currentyearfinanceData.forEach((item: any) => {
            if (item.sub_acct_type === "CL-i") {
              current_year_total_CLI += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "PF-i") {
              current_year_total_PFI += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "LT") {
              current_year_total_LT += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "HP") {
              current_year_total_HP += parseFloat(item.principal_rm);
            }
          });

          lastyearfinanceData.forEach((item: any) => {
            if (item.sub_acct_type === "CL-i") {
              previous_year_total_CLI += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "PF-i") {
              previous_year_total_PFI += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "LT") {
              previous_year_total_LT += parseFloat(item.principal_rm);
            } else if (item.sub_acct_type === "HP") {
              previous_year_total_HP += parseFloat(item.principal_rm);
            }
          });

          setCurrentFinanceLT(current_year_total_LT);
          setCurrentFinancePFI(current_year_total_PFI);
          setCurrentFinanceCLI(current_year_total_CLI);
          setCurrentFinanceHP(current_year_total_HP);

          setFinanceLTPercentage(
            calculateProfitPercentage(
              previous_year_total_LT,
              current_year_total_LT
            )
          );
          setFinancePFIPercentage(
            calculateProfitPercentage(
              previous_year_total_PFI,
              current_year_total_PFI
            )
          );
          setFinanceCLIPercentage(
            calculateProfitPercentage(
              previous_year_total_CLI,
              current_year_total_CLI
            )
          );
          setFinanceHPPercentage(
            calculateProfitPercentage(
              previous_year_total_HP,
              current_year_total_HP
            )
          );
        } else {
          console.error("Invalid data received");
        }
      })
      .catch((error) => {})
      .finally(() => {
        setFinanceCardLoading(false);
      });
  };

  const refresh_collections_by_product = async (
    value: any,
    currentdate: any,
    previousdate: any,
    signal: AbortSignal
  ) => {
    let due_to_receive = 0;
    let collected = 0;
    let previous_collected = 0;
    let collected_percentage = 0;
    let collection_rate = 0;
    let advance = 0;
    let full_settlement = 0;
    let d0_aging_principal_balance = 0;
    let d0_aging_profit_balance = 0;
    let d1_aging_principal_balance = 0;
    let d1_aging_profit_balance = 0;
    let d2_aging_principal_balance = 0;
    let d2_aging_profit_balance = 0;
    let d3_aging_principal_balance = 0;
    let d3_aging_profit_balance = 0;
    let d4_aging_principal_balance = 0;
    let d4_aging_profit_balance = 0;
    let d5_aging_principal_balance = 0;
    let d5_aging_profit_balance = 0;
    let d6_aging_principal_balance = 0;
    let d6_aging_profit_balance = 0;
    let d7_aging_principal_balance = 0;
    let d7_aging_profit_balance = 0;
    let collection_by_category: any = {
      D0: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      D1: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      D2: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      D3: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      D4: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      D5: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      D6: { due_for_the_month: 0, collected: 0, collection_rate: 0 },
      "D7+": { due_for_the_month: 0, collected: 0, collection_rate: 0 },
    };
    let upcoming = 0;
    let past_due_1_30 = 0;
    let past_due_31_60 = 0;
    let past_due_61_90 = 0;
    let more_than_91 = 0;

    let year_due_to_receive = 0;
    let year_collected = 0;
    let year_collection_rate = 0;

    const StartSelectedYearISO: any = dayjs(`${selectedYear}-01-01`)
      .startOf("year")
      .toDate()
      .toISOString();
    const EndSelectedYearISO: any = dayjs(`${selectedYear}-12-31`)
      .endOf("year")
      .toDate()
      .toISOString();

    if (value === "Money Lending") {
      setMLCollectionMonthStatisticLoading(true);
      setMLCollectionYearStatisticLoading(true);
      setMLCollectionMonthCollectionLoading(true);
      setMLCollectionMonthAgingBucketLoading(true);
      setMLCollectionYearCollectionRateLoading(true);
      setMLCollectionYearMonthCollectionLoading(true);
      setMLCollectionYearNonPerformingLoanLoading(true);

      let selectedMonthQuery = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: currentdate,
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
          principal_balance_rm: 1,
          profit_balance_rm: 1,
        },
      };

      let selectedYearQuery = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
          report_date: 1,
          sub_acct_type: 1,
        },
      };

      let selectedMonthstatisics = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: currentdate,
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
        },
      };

      let selectedMonthCollectionbyCategory = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: currentdate,
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
        },
      };

      let selectedMonthAgingBucket = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: currentdate,
        },
        project: {
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
        },
      };

      let previousMonth = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: previousdate,
        },
        project: {
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
        },
      };

      let currentYear = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
        },
      };

      let currentYearCurrentCollectionRate = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYearMonthlyCollectionbyType = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          sub_acct_type: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      let currentYearNonPerformingLoan = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["CL-i", "LT", "PF-i"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
          report_date: 1,
        },
      };

      const MonthlyStatisticsPromise = Promise.all([
        getLoanListFilter(selectedMonthstatisics, signal),
        getLoanListFilter(previousMonth, signal),
      ])
        .then(([monthlyData, previousmonthlyData]: any) => {
          if (
            monthlyData &&
            Array.isArray(monthlyData) &&
            previousmonthlyData &&
            Array.isArray(previousmonthlyData)
          ) {
            // Ensure that data is not null or undefined and is an array
            monthlyData.forEach((item: any) => {
              due_to_receive +=
                parseFloat(item.d0_principal_due_rm) +
                parseFloat(item.d0_profit_due_rm) +
                parseFloat(item.d1_principal_due_rm) +
                parseFloat(item.d1_profit_due_rm) +
                parseFloat(item.d2_principal_due_rm) +
                parseFloat(item.d2_profit_due_rm) +
                parseFloat(item.d3_principal_due_rm) +
                parseFloat(item.d3_profit_due_rm) +
                parseFloat(item.d4_principal_due_rm) +
                parseFloat(item.d4_profit_due_rm) +
                parseFloat(item.d5_principal_due_rm) +
                parseFloat(item.d5_profit_due_rm) +
                parseFloat(item.d6_principal_due_rm) +
                parseFloat(item.d6_profit_due_rm) +
                parseFloat(item.d7__principal_due_rm) +
                parseFloat(item.d7__profit_due_rm);

              collected +=
                parseFloat(item.d0_collected_principal_rm) +
                parseFloat(item.d0_collected_profit_rm) +
                parseFloat(item.d1_collected_principal_rm) +
                parseFloat(item.d1_collected_profit_rm) +
                parseFloat(item.d2_collected_principal_rm) +
                parseFloat(item.d2_collected_profit_rm) +
                parseFloat(item.d3_collected_principal_rm) +
                parseFloat(item.d3_collected_profit_rm) +
                parseFloat(item.d4_collected_principal_rm) +
                parseFloat(item.d4_collected_profit_rm) +
                parseFloat(item.d5_collected_principal_rm) +
                parseFloat(item.d5_collected_profit_rm) +
                parseFloat(item.d6_collected_principal_rm) +
                parseFloat(item.d6_collected_profit_rm) +
                parseFloat(item.d7__collected_principal_rm) +
                parseFloat(item.d7__collected_profit_rm);

              advance +=
                parseFloat(item.principal_collected_in_advance_rm) +
                parseFloat(item.profit_collected_in_advance_rm);

              full_settlement +=
                parseFloat(item.settlement_principal_rm) +
                parseFloat(item.settlement_profit_rm);
            });

            previousmonthlyData.forEach((item: any) => {
              previous_collected +=
                parseFloat(item.d0_collected_principal_rm) +
                parseFloat(item.d0_collected_profit_rm) +
                parseFloat(item.d1_collected_principal_rm) +
                parseFloat(item.d1_collected_profit_rm) +
                parseFloat(item.d2_collected_principal_rm) +
                parseFloat(item.d2_collected_profit_rm) +
                parseFloat(item.d3_collected_principal_rm) +
                parseFloat(item.d3_collected_profit_rm) +
                parseFloat(item.d4_collected_principal_rm) +
                parseFloat(item.d4_collected_profit_rm) +
                parseFloat(item.d5_collected_principal_rm) +
                parseFloat(item.d5_collected_profit_rm) +
                parseFloat(item.d6_collected_principal_rm) +
                parseFloat(item.d6_collected_profit_rm) +
                parseFloat(item.d7__collected_principal_rm) +
                parseFloat(item.d7__collected_profit_rm);
            });

            collected_percentage = calculateProfitPercentage(
              previous_collected,
              collected
            );

            if (due_to_receive != 0) {
              collection_rate = collected / due_to_receive;
            } else {
              collection_rate = 0;
            }

            setMonthlyDuetoReceive(due_to_receive);
            setMonthlyCollected(collected);
            setMonthlyCollectionRate(collection_rate);
            setMonthlyCollectedPercentage(collected_percentage);
            setMonthlyAdvance(advance);
            setMonthlyFullSettlement(full_settlement);
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setMLCollectionMonthStatisticLoading(false);
        });

      const MonthlyCollectionByCategoryPromise = Promise.all([
        getLoanListFilter(selectedMonthCollectionbyCategory, signal),
      ])
        .then(([data]: any) => {
          if (data && Array.isArray(data)) {
            // Ensure that data is not null or undefined and is an array
            data.forEach((item: any) => {
              collection_by_category.D0.due_for_the_month +=
                parseFloat(item.d0_principal_due_rm) +
                parseFloat(item.d0_profit_due_rm);
              collection_by_category.D0.collected +=
                parseFloat(item.d0_collected_principal_rm) +
                parseFloat(item.d0_collected_profit_rm);
              collection_by_category.D1.due_for_the_month +=
                parseFloat(item.d1_principal_due_rm) +
                parseFloat(item.d1_profit_due_rm);
              collection_by_category.D1.collected +=
                parseFloat(item.d1_collected_principal_rm) +
                parseFloat(item.d1_collected_profit_rm);
              collection_by_category.D2.due_for_the_month +=
                parseFloat(item.d2_principal_due_rm) +
                parseFloat(item.d2_profit_due_rm);
              collection_by_category.D2.collected +=
                parseFloat(item.d2_collected_principal_rm) +
                parseFloat(item.d2_collected_profit_rm);
              collection_by_category.D3.due_for_the_month +=
                parseFloat(item.d3_principal_due_rm) +
                parseFloat(item.d3_profit_due_rm);
              collection_by_category.D3.collected +=
                parseFloat(item.d3_collected_principal_rm) +
                parseFloat(item.d3_collected_profit_rm);
              collection_by_category.D4.due_for_the_month +=
                parseFloat(item.d4_principal_due_rm) +
                parseFloat(item.d4_profit_due_rm);
              collection_by_category.D4.collected +=
                parseFloat(item.d4_collected_principal_rm) +
                parseFloat(item.d4_collected_profit_rm);
              collection_by_category.D5.due_for_the_month +=
                parseFloat(item.d5_principal_due_rm) +
                parseFloat(item.d5_profit_due_rm);
              collection_by_category.D5.collected +=
                parseFloat(item.d5_collected_principal_rm) +
                parseFloat(item.d5_collected_profit_rm);
              collection_by_category.D6.due_for_the_month +=
                parseFloat(item.d6_principal_due_rm) +
                parseFloat(item.d6_profit_due_rm);
              collection_by_category.D6.collected +=
                parseFloat(item.d6_collected_principal_rm) +
                parseFloat(item.d6_collected_profit_rm);
              collection_by_category["D7+"].due_for_the_month +=
                parseFloat(item.d7__principal_due_rm) +
                parseFloat(item.d7__profit_due_rm);
              collection_by_category["D7+"].collected +=
                parseFloat(item.d7__collected_principal_rm) +
                parseFloat(item.d7__collected_profit_rm);
            });

            for (const key in collection_by_category) {
              if (collection_by_category[key].due_for_the_month > 0) {
                collection_by_category[key].collection_rate =
                  collection_by_category[key].collected /
                  collection_by_category[key].due_for_the_month;
              }
              collection_by_category[key].collected = Math.round(
                collection_by_category[key].collected
              );
              collection_by_category[key].due_for_the_month = Math.round(
                collection_by_category[key].due_for_the_month
              );
            }

            setMonthlyCollectionbyCategory(collection_by_category);
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setMLCollectionMonthCollectionLoading(false);
        });

      const MonthlyAgingBucketPromise = Promise.all([
        getLoanListFilter(selectedMonthAgingBucket, signal),
      ])
        .then(([data]: any) => {
          if (data && Array.isArray(data)) {
            // Ensure that data is not null or undefined and is an array
            data.forEach((item: any) => {
              if (item.d1 === 1) {
                d1_aging_principal_balance +=
                  parseFloat(item.d1_principal_due_rm) -
                  parseFloat(item.d1_collected_principal_rm);
                d1_aging_profit_balance +=
                  parseFloat(item.d1_profit_due_rm) -
                  parseFloat(item.d1_collected_profit_rm);
              }

              if (item.d2 === 1) {
                d2_aging_principal_balance +=
                  parseFloat(item.d2_principal_due_rm) -
                  parseFloat(item.d2_collected_principal_rm);
                d2_aging_profit_balance +=
                  parseFloat(item.d2_profit_due_rm) -
                  parseFloat(item.d2_collected_profit_rm);
              }

              if (item.d3 === 1) {
                d3_aging_principal_balance +=
                  parseFloat(item.d3_principal_due_rm) -
                  parseFloat(item.d3_collected_principal_rm);
                d3_aging_profit_balance +=
                  parseFloat(item.d3_profit_due_rm) -
                  parseFloat(item.d3_collected_profit_rm);
              }

              if (item.d4 === 1) {
                d4_aging_principal_balance +=
                  parseFloat(item.d4_principal_due_rm) -
                  parseFloat(item.d4_collected_principal_rm);
                d4_aging_profit_balance +=
                  parseFloat(item.d4_profit_due_rm) -
                  parseFloat(item.d4_collected_profit_rm);
              }

              if (item.d5 === 1) {
                d5_aging_principal_balance +=
                  parseFloat(item.d5_principal_due_rm) -
                  parseFloat(item.d5_collected_principal_rm);
                d5_aging_profit_balance +=
                  parseFloat(item.d5_profit_due_rm) -
                  parseFloat(item.d5_collected_profit_rm);
              }

              if (item.d6 === 1) {
                d6_aging_principal_balance +=
                  parseFloat(item.d6_principal_due_rm) -
                  parseFloat(item.d6_collected_principal_rm);
                d6_aging_profit_balance +=
                  parseFloat(item.d6_profit_due_rm) -
                  parseFloat(item.d6_collected_profit_rm);
              }

              if (item.d7_ === 1) {
                d7_aging_principal_balance +=
                  parseFloat(item.d7__principal_due_rm) -
                  parseFloat(item.d7__collected_principal_rm);
                d7_aging_profit_balance +=
                  parseFloat(item.d7__profit_due_rm) -
                  parseFloat(item.d7__collected_profit_rm);
              }

              d0_aging_principal_balance +=
                parseFloat(item.principal_balance_rm) -
                (parseFloat(item.d1_principal_due_rm) -
                  parseFloat(item.d1_collected_principal_rm)) -
                (parseFloat(item.d2_principal_due_rm) -
                  parseFloat(item.d2_collected_principal_rm)) -
                (parseFloat(item.d3_principal_due_rm) -
                  parseFloat(item.d3_collected_principal_rm)) -
                (parseFloat(item.d4_principal_due_rm) -
                  parseFloat(item.d4_collected_principal_rm)) -
                (parseFloat(item.d5_principal_due_rm) -
                  parseFloat(item.d5_collected_principal_rm)) -
                (parseFloat(item.d6_principal_due_rm) -
                  parseFloat(item.d6_collected_principal_rm)) -
                (parseFloat(item.d7__principal_due_rm) -
                  parseFloat(item.d7__collected_principal_rm));

              d0_aging_profit_balance +=
                parseFloat(item.profit_balance_rm) -
                (parseFloat(item.d1_profit_due_rm) -
                  parseFloat(item.d1_collected_profit_rm)) -
                (parseFloat(item.d2_profit_due_rm) -
                  parseFloat(item.d2_collected_profit_rm)) -
                (parseFloat(item.d3_profit_due_rm) -
                  parseFloat(item.d3_collected_profit_rm)) -
                (parseFloat(item.d4_profit_due_rm) -
                  parseFloat(item.d4_collected_profit_rm)) -
                (parseFloat(item.d5_profit_due_rm) -
                  parseFloat(item.d5_collected_profit_rm)) -
                (parseFloat(item.d6_profit_due_rm) -
                  parseFloat(item.d6_collected_profit_rm)) -
                (parseFloat(item.d7__profit_due_rm) -
                  parseFloat(item.d7__collected_profit_rm));
            });

            upcoming = Math.round(
              d0_aging_principal_balance + d0_aging_profit_balance
            );
            past_due_1_30 = Math.round(
              d1_aging_principal_balance + d1_aging_profit_balance
            );
            past_due_31_60 = Math.round(
              d2_aging_principal_balance + d2_aging_profit_balance
            );
            past_due_61_90 = Math.round(
              d3_aging_principal_balance + d3_aging_profit_balance
            );
            more_than_91 = Math.round(
              d4_aging_principal_balance +
                d4_aging_profit_balance +
                d5_aging_principal_balance +
                d5_aging_profit_balance +
                d6_aging_principal_balance +
                d6_aging_profit_balance +
                d7_aging_principal_balance +
                d7_aging_profit_balance
            );

            let aging_bucket = [
              upcoming,
              past_due_1_30,
              past_due_31_60,
              past_due_61_90,
              more_than_91,
            ];

            setMonthlyAgingBuckets(aging_bucket);
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setMLCollectionMonthAgingBucketLoading(false);
        });

      getLoanListFilter(selectedYearQuery, signal).then((data: any) => {
        if (data && Array.isArray(data)) {
          // Ensure that data is not null or undefined and is an array
          const YearlyStatisticsPromise = new Promise(
            (resolve: any, reject: any) => {
              data.forEach((item: any) => {
                year_due_to_receive +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm) +
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm) +
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm) +
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm) +
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm) +
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm) +
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm) +
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);

                year_collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                if (year_due_to_receive != 0) {
                  year_collection_rate = year_collected / year_due_to_receive;
                } else {
                  year_collection_rate = 0;
                }

                setYearlyDuetoReceive(year_due_to_receive);
                setYearlyCollected(year_collected);
                setYearlyCollectionRate(year_collection_rate);
              });
              resolve();
              setMLCollectionYearStatisticLoading(false);
            }
          );
          const YearlyCollectionRatesPromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsSalesGrowth: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              months.forEach((month) => {
                totalsSalesGrowth.set(month, {
                  received_total: 0,
                  collected_total: 0,
                });
              });
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const monthName = months[monthIndex];

                if (!totalsSalesGrowth.has(monthName)) {
                  totalsSalesGrowth.set(monthName, {
                    received_total: 0,
                    collected_total: 0,
                  });
                }

                const totals = totalsSalesGrowth.get(monthName);
                if (totals) {
                  totals.received_total +=
                    parseFloat(item.d0_principal_due_rm) +
                    parseFloat(item.d0_profit_due_rm) +
                    parseFloat(item.d1_principal_due_rm) +
                    parseFloat(item.d1_profit_due_rm) +
                    parseFloat(item.d2_principal_due_rm) +
                    parseFloat(item.d2_profit_due_rm) +
                    parseFloat(item.d3_principal_due_rm) +
                    parseFloat(item.d3_profit_due_rm) +
                    parseFloat(item.d4_principal_due_rm) +
                    parseFloat(item.d4_profit_due_rm) +
                    parseFloat(item.d5_principal_due_rm) +
                    parseFloat(item.d5_profit_due_rm) +
                    parseFloat(item.d6_principal_due_rm) +
                    parseFloat(item.d6_profit_due_rm) +
                    parseFloat(item.d7__principal_due_rm) +
                    parseFloat(item.d7__profit_due_rm);
                  totals.collected_total +=
                    parseFloat(item.d0_collected_principal_rm) +
                    parseFloat(item.d0_collected_profit_rm) +
                    parseFloat(item.d1_collected_principal_rm) +
                    parseFloat(item.d1_collected_profit_rm) +
                    parseFloat(item.d2_collected_principal_rm) +
                    parseFloat(item.d2_collected_profit_rm) +
                    parseFloat(item.d3_collected_principal_rm) +
                    parseFloat(item.d3_collected_profit_rm) +
                    parseFloat(item.d4_collected_principal_rm) +
                    parseFloat(item.d4_collected_profit_rm) +
                    parseFloat(item.d5_collected_principal_rm) +
                    parseFloat(item.d5_collected_profit_rm) +
                    parseFloat(item.d6_collected_principal_rm) +
                    parseFloat(item.d6_collected_profit_rm) +
                    parseFloat(item.d7__collected_principal_rm) +
                    parseFloat(item.d7__collected_profit_rm);
                }

                const salesgrowthresult: {
                  month: string;
                  ratio: number;
                }[] = Array.from(totalsSalesGrowth.entries()).map(
                  ([month, { received_total, collected_total }]: any) => ({
                    month,
                    ratio:
                      collected_total !== 0
                        ? collected_total / received_total
                        : 0,
                  })
                );

                setYearlyCurrentCollectionRate(salesgrowthresult);
              });
              resolve();
              setMLCollectionYearCollectionRateLoading(false);
            }
          );
          const YearlyCollectionTypePromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsSalesGrowth: any = new Map();
              const totalsMonthLoan: any = new Map();
              const totalsNPL: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const subAcctType = item.sub_acct_type;

                if (!totalsMonthLoan.has(subAcctType)) {
                  totalsMonthLoan.set(subAcctType, Array(12).fill(0));
                }

                const currentTotals = totalsMonthLoan.get(subAcctType)!;
                currentTotals[monthIndex] +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                const monthloanresult: {
                  sub_type_acct: string;
                  total: number[];
                }[] = Array.from(totalsMonthLoan.entries()).map(
                  ([sub_type_acct, total]: any) => ({
                    sub_type_acct,
                    total,
                  })
                );
                monthloanresult.sort((a: any, b: any) =>
                  a.sub_type_acct.localeCompare(b.sub_type_acct)
                );

                setYearlyMonthlyCollection(monthloanresult);
              });
              resolve();
              setMLCollectionYearMonthCollectionLoading(false);
            }
          );
          const YearlyNonPerformingLoanPromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsNPL: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              months.forEach((month) => {
                totalsNPL.set(month, {
                  receivable_total: 0,
                  balance_total: 0,
                });
              });
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const monthName = months[monthIndex];

                let year_d0_aging_principal_balance = 0;
                let year_d0_aging_profit_balance = 0;
                let year_d1_aging_principal_balance = 0;
                let year_d1_aging_profit_balance = 0;
                let year_d2_aging_principal_balance = 0;
                let year_d2_aging_profit_balance = 0;
                let year_d3_aging_principal_balance = 0;
                let year_d3_aging_profit_balance = 0;
                let year_d4_aging_principal_balance = 0;
                let year_d4_aging_profit_balance = 0;
                let year_d5_aging_principal_balance = 0;
                let year_d5_aging_profit_balance = 0;
                let year_d6_aging_principal_balance = 0;
                let year_d6_aging_profit_balance = 0;
                let year_d7_aging_principal_balance = 0;
                let year_d7_aging_profit_balance = 0;

                if (item.d1 === 1) {
                  year_d1_aging_principal_balance =
                    parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm);
                  year_d1_aging_profit_balance =
                    parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm);
                }

                if (item.d2 === 1) {
                  year_d2_aging_principal_balance =
                    parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm);
                  year_d2_aging_profit_balance =
                    parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm);
                }

                if (item.d3 === 1) {
                  year_d3_aging_principal_balance =
                    parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm);
                  year_d3_aging_profit_balance =
                    parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm);
                }

                if (item.d4 === 1) {
                  year_d4_aging_principal_balance =
                    parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm);
                  year_d4_aging_profit_balance =
                    parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm);
                }

                if (item.d5 === 1) {
                  year_d5_aging_principal_balance =
                    parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm);
                  year_d5_aging_profit_balance =
                    parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm);
                }

                if (item.d6 === 1) {
                  year_d6_aging_principal_balance =
                    parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm);
                  year_d6_aging_profit_balance =
                    parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm);
                }

                if (item.d7_ === 1) {
                  year_d7_aging_principal_balance =
                    parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm);
                  year_d7_aging_profit_balance =
                    parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm);
                }

                year_d0_aging_principal_balance =
                  parseFloat(item.principal_balance_rm) -
                  (parseFloat(item.d1_principal_due_rm) -
                    parseFloat(item.d1_collected_principal_rm)) -
                  (parseFloat(item.d2_principal_due_rm) -
                    parseFloat(item.d2_collected_principal_rm)) -
                  (parseFloat(item.d3_principal_due_rm) -
                    parseFloat(item.d3_collected_principal_rm)) -
                  (parseFloat(item.d4_principal_due_rm) -
                    parseFloat(item.d4_collected_principal_rm)) -
                  (parseFloat(item.d5_principal_due_rm) -
                    parseFloat(item.d5_collected_principal_rm)) -
                  (parseFloat(item.d6_principal_due_rm) -
                    parseFloat(item.d6_collected_principal_rm)) -
                  (parseFloat(item.d7__principal_due_rm) -
                    parseFloat(item.d7__collected_principal_rm));

                year_d0_aging_profit_balance =
                  parseFloat(item.profit_balance_rm) -
                  (parseFloat(item.d1_profit_due_rm) -
                    parseFloat(item.d1_collected_profit_rm)) -
                  (parseFloat(item.d2_profit_due_rm) -
                    parseFloat(item.d2_collected_profit_rm)) -
                  (parseFloat(item.d3_profit_due_rm) -
                    parseFloat(item.d3_collected_profit_rm)) -
                  (parseFloat(item.d4_profit_due_rm) -
                    parseFloat(item.d4_collected_profit_rm)) -
                  (parseFloat(item.d5_profit_due_rm) -
                    parseFloat(item.d5_collected_profit_rm)) -
                  (parseFloat(item.d6_profit_due_rm) -
                    parseFloat(item.d6_collected_profit_rm)) -
                  (parseFloat(item.d7__profit_due_rm) -
                    parseFloat(item.d7__collected_profit_rm));

                if (!totalsNPL.has(monthName)) {
                  totalsNPL.set(monthName, {
                    receivable_total: 0,
                    balance_total: 0,
                  });
                }

                const totalNPL = totalsNPL.get(monthName);
                if (totalNPL) {
                  totalNPL.receivable_total +=
                    year_d0_aging_principal_balance +
                    year_d0_aging_profit_balance +
                    year_d1_aging_principal_balance +
                    year_d1_aging_profit_balance +
                    year_d2_aging_principal_balance +
                    year_d2_aging_profit_balance +
                    year_d3_aging_principal_balance +
                    year_d3_aging_profit_balance +
                    year_d4_aging_principal_balance +
                    year_d4_aging_profit_balance +
                    year_d5_aging_principal_balance +
                    year_d5_aging_profit_balance +
                    year_d6_aging_principal_balance +
                    year_d6_aging_profit_balance +
                    year_d7_aging_principal_balance +
                    year_d7_aging_profit_balance;
                  totalNPL.balance_total +=
                    year_d4_aging_principal_balance +
                    year_d4_aging_profit_balance +
                    year_d5_aging_principal_balance +
                    year_d5_aging_profit_balance +
                    year_d6_aging_principal_balance +
                    year_d6_aging_profit_balance +
                    year_d7_aging_principal_balance +
                    year_d7_aging_profit_balance;
                }

                const monthNPL: {
                  month: string;
                  receivable_total: number;
                  ratio: number;
                }[] = Array.from(totalsNPL.entries()).map(
                  ([month, { receivable_total, balance_total }]: any) => ({
                    month,
                    receivable_total,
                    ratio:
                      balance_total !== 0
                        ? balance_total / receivable_total
                        : 0,
                  })
                );
                setYearlyNonPerformingLoan(monthNPL);
              });
              resolve();
              setMLCollectionYearNonPerformingLoanLoading(false);
            }
          );
          Promise.all([
            MonthlyStatisticsPromise,
            MonthlyCollectionByCategoryPromise,
            MonthlyAgingBucketPromise,
          ])
            .then(() => {
              // Chain the Yearly promises sequentially
              return YearlyStatisticsPromise.then(
                () => YearlyCollectionRatesPromise
              )
                .then(() => YearlyCollectionTypePromise)
                .then(() => YearlyNonPerformingLoanPromise);
            })
            .catch((error: any) => {
              // Handle any errors from any of the groups
              console.error(error);
            })
            .finally(() => {});
        } else {
          console.error("Invalid data received");
        }
      });

      // const YearlyStatisticsPromise = Promise.all([
      //   getLoanListFilter(currentYear, signal),
      // ])
      //   .then(([currentYearlyData]: any) => {
      //     currentYearlyData.forEach((item: any) => {
      //       year_due_to_receive +=
      //         parseFloat(item.d0_principal_due_rm) +
      //         parseFloat(item.d0_profit_due_rm) +
      //         parseFloat(item.d1_principal_due_rm) +
      //         parseFloat(item.d1_profit_due_rm) +
      //         parseFloat(item.d2_principal_due_rm) +
      //         parseFloat(item.d2_profit_due_rm) +
      //         parseFloat(item.d3_principal_due_rm) +
      //         parseFloat(item.d3_profit_due_rm) +
      //         parseFloat(item.d4_principal_due_rm) +
      //         parseFloat(item.d4_profit_due_rm) +
      //         parseFloat(item.d5_principal_due_rm) +
      //         parseFloat(item.d5_profit_due_rm) +
      //         parseFloat(item.d6_principal_due_rm) +
      //         parseFloat(item.d6_profit_due_rm) +
      //         parseFloat(item.d7__principal_due_rm) +
      //         parseFloat(item.d7__profit_due_rm);

      //       year_collected +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       if (year_due_to_receive != 0) {
      //         year_collection_rate = year_collected / year_due_to_receive;
      //       } else {
      //         year_collection_rate = 0;
      //       }

      //       setYearlyDuetoReceive(year_due_to_receive);
      //       setYearlyCollected(year_collected);
      //       setYearlyCollectionRate(year_collection_rate);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearStatisticLoading(false);
      //   });

      // const YearlyCollectionRatesPromise = Promise.all([
      //   getLoanListFilter(currentYearCurrentCollectionRate, signal),
      // ])
      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsSalesGrowth.set(month, {
      //         received_total: 0,
      //         collected_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       if (!totalsSalesGrowth.has(monthName)) {
      //         totalsSalesGrowth.set(monthName, {
      //           received_total: 0,
      //           collected_total: 0,
      //         });
      //       }

      //       const totals = totalsSalesGrowth.get(monthName);
      //       if (totals) {
      //         totals.received_total +=
      //           parseFloat(item.d0_principal_due_rm) +
      //           parseFloat(item.d0_profit_due_rm) +
      //           parseFloat(item.d1_principal_due_rm) +
      //           parseFloat(item.d1_profit_due_rm) +
      //           parseFloat(item.d2_principal_due_rm) +
      //           parseFloat(item.d2_profit_due_rm) +
      //           parseFloat(item.d3_principal_due_rm) +
      //           parseFloat(item.d3_profit_due_rm) +
      //           parseFloat(item.d4_principal_due_rm) +
      //           parseFloat(item.d4_profit_due_rm) +
      //           parseFloat(item.d5_principal_due_rm) +
      //           parseFloat(item.d5_profit_due_rm) +
      //           parseFloat(item.d6_principal_due_rm) +
      //           parseFloat(item.d6_profit_due_rm) +
      //           parseFloat(item.d7__principal_due_rm) +
      //           parseFloat(item.d7__profit_due_rm);
      //         totals.collected_total +=
      //           parseFloat(item.d0_collected_principal_rm) +
      //           parseFloat(item.d0_collected_profit_rm) +
      //           parseFloat(item.d1_collected_principal_rm) +
      //           parseFloat(item.d1_collected_profit_rm) +
      //           parseFloat(item.d2_collected_principal_rm) +
      //           parseFloat(item.d2_collected_profit_rm) +
      //           parseFloat(item.d3_collected_principal_rm) +
      //           parseFloat(item.d3_collected_profit_rm) +
      //           parseFloat(item.d4_collected_principal_rm) +
      //           parseFloat(item.d4_collected_profit_rm) +
      //           parseFloat(item.d5_collected_principal_rm) +
      //           parseFloat(item.d5_collected_profit_rm) +
      //           parseFloat(item.d6_collected_principal_rm) +
      //           parseFloat(item.d6_collected_profit_rm) +
      //           parseFloat(item.d7__collected_principal_rm) +
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       const salesgrowthresult: {
      //         month: string;
      //         ratio: number;
      //       }[] = Array.from(totalsSalesGrowth.entries()).map(
      //         ([month, { received_total, collected_total }]: any) => ({
      //           month,
      //           ratio:
      //             collected_total !== 0 ? collected_total / received_total : 0,
      //         })
      //       );

      //       setYearlyCurrentCollectionRate(salesgrowthresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearCollectionRateLoading(false);
      //   });

      // const YearlyCollectionTypePromise = Promise.all([
      //   getLoanListFilter(currentYearMonthlyCollectionbyType, signal),
      // ])
      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();
      //     const totalsMonthLoan: any = new Map();
      //     const totalsNPL: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const subAcctType = item.sub_acct_type;

      //       if (!totalsMonthLoan.has(subAcctType)) {
      //         totalsMonthLoan.set(subAcctType, Array(12).fill(0));
      //       }

      //       const currentTotals = totalsMonthLoan.get(subAcctType)!;
      //       currentTotals[monthIndex] +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       const monthloanresult: {
      //         sub_type_acct: string;
      //         total: number[];
      //       }[] = Array.from(totalsMonthLoan.entries()).map(
      //         ([sub_type_acct, total]: any) => ({
      //           sub_type_acct,
      //           total,
      //         })
      //       );
      //       monthloanresult.sort((a: any, b: any) =>
      //         a.sub_type_acct.localeCompare(b.sub_type_acct)
      //       );

      //       setYearlyMonthlyCollection(monthloanresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearMonthCollectionLoading(false);
      //   });

      // const YearlyNonPerformingLoanPromise = Promise.all([
      //   getLoanListFilter(currentYearNonPerformingLoan, signal),
      // ])
      //   .then(([data]: any) => {
      //     const totalsNPL: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsNPL.set(month, {
      //         receivable_total: 0,
      //         balance_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       let year_d0_aging_principal_balance = 0;
      //       let year_d0_aging_profit_balance = 0;
      //       let year_d1_aging_principal_balance = 0;
      //       let year_d1_aging_profit_balance = 0;
      //       let year_d2_aging_principal_balance = 0;
      //       let year_d2_aging_profit_balance = 0;
      //       let year_d3_aging_principal_balance = 0;
      //       let year_d3_aging_profit_balance = 0;
      //       let year_d4_aging_principal_balance = 0;
      //       let year_d4_aging_profit_balance = 0;
      //       let year_d5_aging_principal_balance = 0;
      //       let year_d5_aging_profit_balance = 0;
      //       let year_d6_aging_principal_balance = 0;
      //       let year_d6_aging_profit_balance = 0;
      //       let year_d7_aging_principal_balance = 0;
      //       let year_d7_aging_profit_balance = 0;

      //       if (item.d1 == 1) {
      //         year_d1_aging_principal_balance =
      //           parseFloat(item.d1_principal_due_rm) -
      //           parseFloat(item.d1_collected_principal_rm);
      //         year_d1_aging_profit_balance =
      //           parseFloat(item.d1_profit_due_rm) -
      //           parseFloat(item.d1_collected_profit_rm);
      //       }

      //       if (item.d2 == 1) {
      //         year_d2_aging_principal_balance =
      //           parseFloat(item.d2_principal_due_rm) -
      //           parseFloat(item.d2_collected_principal_rm);
      //         year_d2_aging_profit_balance =
      //           parseFloat(item.d2_profit_due_rm) -
      //           parseFloat(item.d2_collected_profit_rm);
      //       }

      //       if (item.d3 == 1) {
      //         year_d3_aging_principal_balance =
      //           parseFloat(item.d3_principal_due_rm) -
      //           parseFloat(item.d3_collected_principal_rm);
      //         year_d3_aging_profit_balance =
      //           parseFloat(item.d3_profit_due_rm) -
      //           parseFloat(item.d3_collected_profit_rm);
      //       }

      //       if (item.d4 == 1) {
      //         year_d4_aging_principal_balance =
      //           parseFloat(item.d4_principal_due_rm) -
      //           parseFloat(item.d4_collected_principal_rm);
      //         year_d4_aging_profit_balance =
      //           parseFloat(item.d4_profit_due_rm) -
      //           parseFloat(item.d4_collected_profit_rm);
      //       }

      //       if (item.d5 == 1) {
      //         year_d5_aging_principal_balance =
      //           parseFloat(item.d5_principal_due_rm) -
      //           parseFloat(item.d5_collected_principal_rm);
      //         year_d5_aging_profit_balance =
      //           parseFloat(item.d5_profit_due_rm) -
      //           parseFloat(item.d5_collected_profit_rm);
      //       }

      //       if (item.d6 == 1) {
      //         year_d6_aging_principal_balance =
      //           parseFloat(item.d6_principal_due_rm) -
      //           parseFloat(item.d6_collected_principal_rm);
      //         year_d6_aging_profit_balance =
      //           parseFloat(item.d6_profit_due_rm) -
      //           parseFloat(item.d6_collected_profit_rm);
      //       }

      //       if (item.d7_ == 1) {
      //         year_d7_aging_principal_balance =
      //           parseFloat(item.d7__principal_due_rm) -
      //           parseFloat(item.d7__collected_principal_rm);
      //         year_d7_aging_profit_balance =
      //           parseFloat(item.d7__profit_due_rm) -
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       year_d0_aging_principal_balance =
      //         parseFloat(item.principal_balance_rm) -
      //         (parseFloat(item.d1_principal_due_rm) -
      //           parseFloat(item.d1_collected_principal_rm)) -
      //         (parseFloat(item.d2_principal_due_rm) -
      //           parseFloat(item.d2_collected_principal_rm)) -
      //         (parseFloat(item.d3_principal_due_rm) -
      //           parseFloat(item.d3_collected_principal_rm)) -
      //         (parseFloat(item.d4_principal_due_rm) -
      //           parseFloat(item.d4_collected_principal_rm)) -
      //         (parseFloat(item.d5_principal_due_rm) -
      //           parseFloat(item.d5_collected_principal_rm)) -
      //         (parseFloat(item.d6_principal_due_rm) -
      //           parseFloat(item.d6_collected_principal_rm)) -
      //         (parseFloat(item.d7__principal_due_rm) -
      //           parseFloat(item.d7__collected_principal_rm));

      //       year_d0_aging_profit_balance =
      //         parseFloat(item.profit_balance_rm) -
      //         (parseFloat(item.d1_profit_due_rm) -
      //           parseFloat(item.d1_collected_profit_rm)) -
      //         (parseFloat(item.d2_profit_due_rm) -
      //           parseFloat(item.d2_collected_profit_rm)) -
      //         (parseFloat(item.d3_profit_due_rm) -
      //           parseFloat(item.d3_collected_profit_rm)) -
      //         (parseFloat(item.d4_profit_due_rm) -
      //           parseFloat(item.d4_collected_profit_rm)) -
      //         (parseFloat(item.d5_profit_due_rm) -
      //           parseFloat(item.d5_collected_profit_rm)) -
      //         (parseFloat(item.d6_profit_due_rm) -
      //           parseFloat(item.d6_collected_profit_rm)) -
      //         (parseFloat(item.d7__profit_due_rm) -
      //           parseFloat(item.d7__collected_profit_rm));

      //       if (!totalsNPL.has(monthName)) {
      //         totalsNPL.set(monthName, {
      //           receivable_total: 0,
      //           balance_total: 0,
      //         });
      //       }

      //       const totalNPL = totalsNPL.get(monthName);
      //       if (totalNPL) {
      //         totalNPL.receivable_total +=
      //           year_d0_aging_principal_balance +
      //           year_d0_aging_profit_balance +
      //           year_d1_aging_principal_balance +
      //           year_d1_aging_profit_balance +
      //           year_d2_aging_principal_balance +
      //           year_d2_aging_profit_balance +
      //           year_d3_aging_principal_balance +
      //           year_d3_aging_profit_balance +
      //           year_d4_aging_principal_balance +
      //           year_d4_aging_profit_balance +
      //           year_d5_aging_principal_balance +
      //           year_d5_aging_profit_balance +
      //           year_d6_aging_principal_balance +
      //           year_d6_aging_profit_balance +
      //           year_d7_aging_principal_balance +
      //           year_d7_aging_profit_balance;
      //         totalNPL.balance_total +=
      //           year_d4_aging_principal_balance +
      //           year_d4_aging_profit_balance +
      //           year_d5_aging_principal_balance +
      //           year_d5_aging_profit_balance +
      //           year_d6_aging_principal_balance +
      //           year_d6_aging_profit_balance +
      //           year_d7_aging_principal_balance +
      //           year_d7_aging_profit_balance;
      //       }

      //       const monthNPL: {
      //         month: string;
      //         receivable_total: number;
      //         ratio: number;
      //       }[] = Array.from(totalsNPL.entries()).map(
      //         ([month, { receivable_total, balance_total }]: any) => ({
      //           month,
      //           receivable_total,
      //           ratio:
      //             balance_total !== 0 ? balance_total / receivable_total : 0,
      //         })
      //       );
      //       setYearlyNonPerformingLoan(monthNPL);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setMLCollectionYearNonPerformingLoanLoading(false);
      //   });

      // Promise.all([
      //   MonthlyStatisticsPromise,
      //   MonthlyCollectionByCategoryPromise,
      //   MonthlyAgingBucketPromise,
      // ])
      //   .then(() => {
      //     // Chain the Yearly promises sequentially
      //     return YearlyStatisticsPromise.then(
      //       () => YearlyCollectionRatesPromise
      //     )
      //       .then(() => YearlyCollectionTypePromise)
      //       .then(() => YearlyNonPerformingLoanPromise);
      //   })
      //   .catch((error: any) => {
      //     // Handle any errors from any of the groups
      //     console.error(error);
      //   })
      //   .finally(() => {});
    } else if (value === "Hire Purchase") {
      setHPCollectionMonthStatisticLoading(true);
      setHPCollectionYearStatisticLoading(true);
      setHPCollectionMonthCollectionLoading(true);
      setHPCollectionMonthAgingBucketLoading(true);
      setHPCollectionYearCollectionRateLoading(true);

      let selectedYearQuery = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
          report_date: 1,
        },
      };
      let selectedMonthstatisics = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: currentdate,
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
        },
      };

      let selectedMonthCollectionbyCategory = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: currentdate,
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
        },
      };

      let selectedMonthAgingBucket = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: currentdate,
        },
        project: {
          principal_balance_rm: 1,
          profit_balance_rm: 1,
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          d0: 1,
          d1: 1,
          d2: 1,
          d3: 1,
          d4: 1,
          d5: 1,
          d6: 1,
          d7_: 1,
        },
      };

      let previousMonth = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: previousdate,
        },
        project: {
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
        },
      };

      let currentYear = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          principal_collected_in_advance_rm: 1,
          profit_collected_in_advance_rm: 1,
          settlement_principal_rm: 1,
          settlement_profit_rm: 1,
        },
      };

      let currentYearCurrentCollectionRate = {
        filter: {
          companyid: { $in: company_access },
          branchid: { $in: branch_access },
          sub_acct_type: { $in: ["HP"] },
          report_date: { $gte: StartSelectedYearISO, $lte: EndSelectedYearISO },
        },
        project: {
          d0_principal_due_rm: 1,
          d0_profit_due_rm: 1,
          d0_collected_principal_rm: 1,
          d0_collected_profit_rm: 1,
          d1_principal_due_rm: 1,
          d1_profit_due_rm: 1,
          d1_collected_principal_rm: 1,
          d1_collected_profit_rm: 1,
          d2_principal_due_rm: 1,
          d2_profit_due_rm: 1,
          d2_collected_principal_rm: 1,
          d2_collected_profit_rm: 1,
          d3_principal_due_rm: 1,
          d3_profit_due_rm: 1,
          d3_collected_principal_rm: 1,
          d3_collected_profit_rm: 1,
          d4_principal_due_rm: 1,
          d4_profit_due_rm: 1,
          d4_collected_principal_rm: 1,
          d4_collected_profit_rm: 1,
          d5_principal_due_rm: 1,
          d5_profit_due_rm: 1,
          d5_collected_principal_rm: 1,
          d5_collected_profit_rm: 1,
          d6_principal_due_rm: 1,
          d6_profit_due_rm: 1,
          d6_collected_principal_rm: 1,
          d6_collected_profit_rm: 1,
          d7__principal_due_rm: 1,
          d7__profit_due_rm: 1,
          d7__collected_principal_rm: 1,
          d7__collected_profit_rm: 1,
          report_date: 1,
        },
      };

      const MonthlyStatisticsPromise = Promise.all([
        getLoanListFilter(selectedMonthstatisics, signal),
        getLoanListFilter(previousMonth, signal),
      ])
        .then(([monthlyData, previousmonthlyData]: any) => {
          if (
            monthlyData &&
            Array.isArray(monthlyData) &&
            previousmonthlyData &&
            Array.isArray(previousmonthlyData)
          ) {
            // Ensure that data is not null or undefined and is an array
            monthlyData.forEach((item: any) => {
              due_to_receive +=
                parseFloat(item.d0_principal_due_rm) +
                parseFloat(item.d0_profit_due_rm) +
                parseFloat(item.d1_principal_due_rm) +
                parseFloat(item.d1_profit_due_rm) +
                parseFloat(item.d2_principal_due_rm) +
                parseFloat(item.d2_profit_due_rm) +
                parseFloat(item.d3_principal_due_rm) +
                parseFloat(item.d3_profit_due_rm) +
                parseFloat(item.d4_principal_due_rm) +
                parseFloat(item.d4_profit_due_rm) +
                parseFloat(item.d5_principal_due_rm) +
                parseFloat(item.d5_profit_due_rm) +
                parseFloat(item.d6_principal_due_rm) +
                parseFloat(item.d6_profit_due_rm) +
                parseFloat(item.d7__principal_due_rm) +
                parseFloat(item.d7__profit_due_rm);

              collected +=
                parseFloat(item.d0_collected_principal_rm) +
                parseFloat(item.d0_collected_profit_rm) +
                parseFloat(item.d1_collected_principal_rm) +
                parseFloat(item.d1_collected_profit_rm) +
                parseFloat(item.d2_collected_principal_rm) +
                parseFloat(item.d2_collected_profit_rm) +
                parseFloat(item.d3_collected_principal_rm) +
                parseFloat(item.d3_collected_profit_rm) +
                parseFloat(item.d4_collected_principal_rm) +
                parseFloat(item.d4_collected_profit_rm) +
                parseFloat(item.d5_collected_principal_rm) +
                parseFloat(item.d5_collected_profit_rm) +
                parseFloat(item.d6_collected_principal_rm) +
                parseFloat(item.d6_collected_profit_rm) +
                parseFloat(item.d7__collected_principal_rm) +
                parseFloat(item.d7__collected_profit_rm);

              advance +=
                parseFloat(item.principal_collected_in_advance_rm) +
                parseFloat(item.profit_collected_in_advance_rm);

              full_settlement +=
                parseFloat(item.settlement_principal_rm) +
                parseFloat(item.settlement_profit_rm);
            });

            previousmonthlyData.forEach((item: any) => {
              previous_collected +=
                parseFloat(item.d0_collected_principal_rm) +
                parseFloat(item.d0_collected_profit_rm) +
                parseFloat(item.d1_collected_principal_rm) +
                parseFloat(item.d1_collected_profit_rm) +
                parseFloat(item.d2_collected_principal_rm) +
                parseFloat(item.d2_collected_profit_rm) +
                parseFloat(item.d3_collected_principal_rm) +
                parseFloat(item.d3_collected_profit_rm) +
                parseFloat(item.d4_collected_principal_rm) +
                parseFloat(item.d4_collected_profit_rm) +
                parseFloat(item.d5_collected_principal_rm) +
                parseFloat(item.d5_collected_profit_rm) +
                parseFloat(item.d6_collected_principal_rm) +
                parseFloat(item.d6_collected_profit_rm) +
                parseFloat(item.d7__collected_principal_rm) +
                parseFloat(item.d7__collected_profit_rm);
            });

            collected_percentage = calculateProfitPercentage(
              previous_collected,
              collected
            );

            if (due_to_receive != 0) {
              collection_rate = collected / due_to_receive;
            } else {
              collection_rate = 0;
            }

            setMonthlyDuetoReceive(due_to_receive);
            setMonthlyCollected(collected);
            setMonthlyCollectionRate(collection_rate);
            setMonthlyCollectedPercentage(collected_percentage);
            setMonthlyAdvance(advance);
            setMonthlyFullSettlement(full_settlement);
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setHPCollectionMonthStatisticLoading(false);
        });

      const MonthlyCollectionByCategoryPromise = Promise.all([
        getLoanListFilter(selectedMonthCollectionbyCategory, signal),
      ])
        .then(([data]: any) => {
          if (data && Array.isArray(data)) {
            // Ensure that data is not null or undefined and is an array
            data.forEach((item: any) => {
              collection_by_category.D0.due_for_the_month +=
                parseFloat(item.d0_principal_due_rm) +
                parseFloat(item.d0_profit_due_rm);
              collection_by_category.D0.collected +=
                parseFloat(item.d0_collected_principal_rm) +
                parseFloat(item.d0_collected_profit_rm);
              collection_by_category.D1.due_for_the_month +=
                parseFloat(item.d1_principal_due_rm) +
                parseFloat(item.d1_profit_due_rm);
              collection_by_category.D1.collected +=
                parseFloat(item.d1_collected_principal_rm) +
                parseFloat(item.d1_collected_profit_rm);
              collection_by_category.D2.due_for_the_month +=
                parseFloat(item.d2_principal_due_rm) +
                parseFloat(item.d2_profit_due_rm);
              collection_by_category.D2.collected +=
                parseFloat(item.d2_collected_principal_rm) +
                parseFloat(item.d2_collected_profit_rm);
              collection_by_category.D3.due_for_the_month +=
                parseFloat(item.d3_principal_due_rm) +
                parseFloat(item.d3_profit_due_rm);
              collection_by_category.D3.collected +=
                parseFloat(item.d3_collected_principal_rm) +
                parseFloat(item.d3_collected_profit_rm);
              collection_by_category.D4.due_for_the_month +=
                parseFloat(item.d4_principal_due_rm) +
                parseFloat(item.d4_profit_due_rm);
              collection_by_category.D4.collected +=
                parseFloat(item.d4_collected_principal_rm) +
                parseFloat(item.d4_collected_profit_rm);
              collection_by_category.D5.due_for_the_month +=
                parseFloat(item.d5_principal_due_rm) +
                parseFloat(item.d5_profit_due_rm);
              collection_by_category.D5.collected +=
                parseFloat(item.d5_collected_principal_rm) +
                parseFloat(item.d5_collected_profit_rm);
              collection_by_category.D6.due_for_the_month +=
                parseFloat(item.d6_principal_due_rm) +
                parseFloat(item.d6_profit_due_rm);
              collection_by_category.D6.collected +=
                parseFloat(item.d6_collected_principal_rm) +
                parseFloat(item.d6_collected_profit_rm);
              collection_by_category["D7+"].due_for_the_month +=
                parseFloat(item.d7__principal_due_rm) +
                parseFloat(item.d7__profit_due_rm);
              collection_by_category["D7+"].collected +=
                parseFloat(item.d7__collected_principal_rm) +
                parseFloat(item.d7__collected_profit_rm);
            });

            for (const key in collection_by_category) {
              if (collection_by_category[key].due_for_the_month > 0) {
                collection_by_category[key].collection_rate =
                  collection_by_category[key].collected /
                  collection_by_category[key].due_for_the_month;
              }
              collection_by_category[key].collected = Math.round(
                collection_by_category[key].collected
              );
              collection_by_category[key].due_for_the_month = Math.round(
                collection_by_category[key].due_for_the_month
              );
            }

            setMonthlyCollectionbyCategory(collection_by_category);
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setHPCollectionMonthCollectionLoading(false);
        });

      const MonthlyAgingBucketPromise = Promise.all([
        getLoanListFilter(selectedMonthAgingBucket, signal),
      ])
        .then(([data]: any) => {
          if (data && Array.isArray(data)) {
            // Ensure that data is not null or undefined and is an array
            data.forEach((item: any) => {
              if (item.d1 === 1) {
                d1_aging_principal_balance +=
                  parseFloat(item.d1_principal_due_rm) -
                  parseFloat(item.d1_collected_principal_rm);
                d1_aging_profit_balance +=
                  parseFloat(item.d1_profit_due_rm) -
                  parseFloat(item.d1_collected_profit_rm);
              }

              if (item.d2 === 1) {
                d2_aging_principal_balance +=
                  parseFloat(item.d2_principal_due_rm) -
                  parseFloat(item.d2_collected_principal_rm);
                d2_aging_profit_balance +=
                  parseFloat(item.d2_profit_due_rm) -
                  parseFloat(item.d2_collected_profit_rm);
              }

              if (item.d3 === 1) {
                d3_aging_principal_balance +=
                  parseFloat(item.d3_principal_due_rm) -
                  parseFloat(item.d3_collected_principal_rm);
                d3_aging_profit_balance +=
                  parseFloat(item.d3_profit_due_rm) -
                  parseFloat(item.d3_collected_profit_rm);
              }

              if (item.d4 === 1) {
                d4_aging_principal_balance +=
                  parseFloat(item.d4_principal_due_rm) -
                  parseFloat(item.d4_collected_principal_rm);
                d4_aging_profit_balance +=
                  parseFloat(item.d4_profit_due_rm) -
                  parseFloat(item.d4_collected_profit_rm);
              }

              if (item.d5 === 1) {
                d5_aging_principal_balance +=
                  parseFloat(item.d5_principal_due_rm) -
                  parseFloat(item.d5_collected_principal_rm);
                d5_aging_profit_balance +=
                  parseFloat(item.d5_profit_due_rm) -
                  parseFloat(item.d5_collected_profit_rm);
              }

              if (item.d6 === 1) {
                d6_aging_principal_balance +=
                  parseFloat(item.d6_principal_due_rm) -
                  parseFloat(item.d6_collected_principal_rm);
                d6_aging_profit_balance +=
                  parseFloat(item.d6_profit_due_rm) -
                  parseFloat(item.d6_collected_profit_rm);
              }

              if (item.d7_ === 1) {
                d7_aging_principal_balance +=
                  parseFloat(item.d7__principal_due_rm) -
                  parseFloat(item.d7__collected_principal_rm);
                d7_aging_profit_balance +=
                  parseFloat(item.d7__profit_due_rm) -
                  parseFloat(item.d7__collected_profit_rm);
              }

              d0_aging_principal_balance +=
                parseFloat(item.principal_balance_rm) -
                (parseFloat(item.d1_principal_due_rm) -
                  parseFloat(item.d1_collected_principal_rm)) -
                (parseFloat(item.d2_principal_due_rm) -
                  parseFloat(item.d2_collected_principal_rm)) -
                (parseFloat(item.d3_principal_due_rm) -
                  parseFloat(item.d3_collected_principal_rm)) -
                (parseFloat(item.d4_principal_due_rm) -
                  parseFloat(item.d4_collected_principal_rm)) -
                (parseFloat(item.d5_principal_due_rm) -
                  parseFloat(item.d5_collected_principal_rm)) -
                (parseFloat(item.d6_principal_due_rm) -
                  parseFloat(item.d6_collected_principal_rm)) -
                (parseFloat(item.d7__principal_due_rm) -
                  parseFloat(item.d7__collected_principal_rm));

              d0_aging_profit_balance +=
                parseFloat(item.profit_balance_rm) -
                (parseFloat(item.d1_profit_due_rm) -
                  parseFloat(item.d1_collected_profit_rm)) -
                (parseFloat(item.d2_profit_due_rm) -
                  parseFloat(item.d2_collected_profit_rm)) -
                (parseFloat(item.d3_profit_due_rm) -
                  parseFloat(item.d3_collected_profit_rm)) -
                (parseFloat(item.d4_profit_due_rm) -
                  parseFloat(item.d4_collected_profit_rm)) -
                (parseFloat(item.d5_profit_due_rm) -
                  parseFloat(item.d5_collected_profit_rm)) -
                (parseFloat(item.d6_profit_due_rm) -
                  parseFloat(item.d6_collected_profit_rm)) -
                (parseFloat(item.d7__profit_due_rm) -
                  parseFloat(item.d7__collected_profit_rm));
            });

            upcoming = Math.round(
              d0_aging_principal_balance + d0_aging_profit_balance
            );
            past_due_1_30 = Math.round(
              d1_aging_principal_balance + d1_aging_profit_balance
            );
            past_due_31_60 = Math.round(
              d2_aging_principal_balance + d2_aging_profit_balance
            );
            past_due_61_90 = Math.round(
              d3_aging_principal_balance + d3_aging_profit_balance
            );
            more_than_91 = Math.round(
              d4_aging_principal_balance +
                d4_aging_profit_balance +
                d5_aging_principal_balance +
                d5_aging_profit_balance +
                d6_aging_principal_balance +
                d6_aging_profit_balance +
                d7_aging_principal_balance +
                d7_aging_profit_balance
            );

            let aging_bucket = [
              upcoming,
              past_due_1_30,
              past_due_31_60,
              past_due_61_90,
              more_than_91,
            ];

            setMonthlyAgingBuckets(aging_bucket);
          } else {
            console.error("Invalid data received");
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setHPCollectionMonthAgingBucketLoading(false);
        });

      // const YearlyStatisticsPromise = Promise.all([
      //   getLoanListFilter(currentYear, signal),
      // ])
      //   .then(([currentYearlyData]: any) => {
      //     currentYearlyData.forEach((item: any) => {
      //       year_due_to_receive +=
      //         parseFloat(item.d0_principal_due_rm) +
      //         parseFloat(item.d0_profit_due_rm) +
      //         parseFloat(item.d1_principal_due_rm) +
      //         parseFloat(item.d1_profit_due_rm) +
      //         parseFloat(item.d2_principal_due_rm) +
      //         parseFloat(item.d2_profit_due_rm) +
      //         parseFloat(item.d3_principal_due_rm) +
      //         parseFloat(item.d3_profit_due_rm) +
      //         parseFloat(item.d4_principal_due_rm) +
      //         parseFloat(item.d4_profit_due_rm) +
      //         parseFloat(item.d5_principal_due_rm) +
      //         parseFloat(item.d5_profit_due_rm) +
      //         parseFloat(item.d6_principal_due_rm) +
      //         parseFloat(item.d6_profit_due_rm) +
      //         parseFloat(item.d7__principal_due_rm) +
      //         parseFloat(item.d7__profit_due_rm);

      //       year_collected +=
      //         parseFloat(item.d0_collected_principal_rm) +
      //         parseFloat(item.d0_collected_profit_rm) +
      //         parseFloat(item.d1_collected_principal_rm) +
      //         parseFloat(item.d1_collected_profit_rm) +
      //         parseFloat(item.d2_collected_principal_rm) +
      //         parseFloat(item.d2_collected_profit_rm) +
      //         parseFloat(item.d3_collected_principal_rm) +
      //         parseFloat(item.d3_collected_profit_rm) +
      //         parseFloat(item.d4_collected_principal_rm) +
      //         parseFloat(item.d4_collected_profit_rm) +
      //         parseFloat(item.d5_collected_principal_rm) +
      //         parseFloat(item.d5_collected_profit_rm) +
      //         parseFloat(item.d6_collected_principal_rm) +
      //         parseFloat(item.d6_collected_profit_rm) +
      //         parseFloat(item.d7__collected_principal_rm) +
      //         parseFloat(item.d7__collected_profit_rm);

      //       if (year_due_to_receive != 0) {
      //         year_collection_rate = year_collected / year_due_to_receive;
      //       } else {
      //         year_collection_rate = 0;
      //       }

      //       setYearlyDuetoReceive(year_due_to_receive);
      //       setYearlyCollected(year_collected);
      //       setYearlyCollectionRate(year_collection_rate);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setHPCollectionYearStatisticLoading(false);
      //   });

      // const YearlyCollectionRatesPromise = Promise.all([
      //   getLoanListFilter(currentYearCurrentCollectionRate, signal),
      // ])
      //   .then(([data]: any) => {
      //     const totalsSalesGrowth: any = new Map();

      //     const months = [
      //       "January",
      //       "February",
      //       "March",
      //       "April",
      //       "May",
      //       "June",
      //       "July",
      //       "August",
      //       "September",
      //       "October",
      //       "November",
      //       "December",
      //     ];
      //     months.forEach((month) => {
      //       totalsSalesGrowth.set(month, {
      //         received_total: 0,
      //         collected_total: 0,
      //       });
      //     });
      //     data.forEach((item: any) => {
      //       const reportDate = new Date(item.report_date);
      //       const monthIndex = reportDate.getMonth();
      //       const monthName = months[monthIndex];

      //       if (!totalsSalesGrowth.has(monthName)) {
      //         totalsSalesGrowth.set(monthName, {
      //           received_total: 0,
      //           collected_total: 0,
      //         });
      //       }

      //       const totals = totalsSalesGrowth.get(monthName);
      //       if (totals) {
      //         totals.received_total +=
      //           parseFloat(item.d0_principal_due_rm) +
      //           parseFloat(item.d0_profit_due_rm) +
      //           parseFloat(item.d1_principal_due_rm) +
      //           parseFloat(item.d1_profit_due_rm) +
      //           parseFloat(item.d2_principal_due_rm) +
      //           parseFloat(item.d2_profit_due_rm) +
      //           parseFloat(item.d3_principal_due_rm) +
      //           parseFloat(item.d3_profit_due_rm) +
      //           parseFloat(item.d4_principal_due_rm) +
      //           parseFloat(item.d4_profit_due_rm) +
      //           parseFloat(item.d5_principal_due_rm) +
      //           parseFloat(item.d5_profit_due_rm) +
      //           parseFloat(item.d6_principal_due_rm) +
      //           parseFloat(item.d6_profit_due_rm) +
      //           parseFloat(item.d7__principal_due_rm) +
      //           parseFloat(item.d7__profit_due_rm);
      //         totals.collected_total +=
      //           parseFloat(item.d0_collected_principal_rm) +
      //           parseFloat(item.d0_collected_profit_rm) +
      //           parseFloat(item.d1_collected_principal_rm) +
      //           parseFloat(item.d1_collected_profit_rm) +
      //           parseFloat(item.d2_collected_principal_rm) +
      //           parseFloat(item.d2_collected_profit_rm) +
      //           parseFloat(item.d3_collected_principal_rm) +
      //           parseFloat(item.d3_collected_profit_rm) +
      //           parseFloat(item.d4_collected_principal_rm) +
      //           parseFloat(item.d4_collected_profit_rm) +
      //           parseFloat(item.d5_collected_principal_rm) +
      //           parseFloat(item.d5_collected_profit_rm) +
      //           parseFloat(item.d6_collected_principal_rm) +
      //           parseFloat(item.d6_collected_profit_rm) +
      //           parseFloat(item.d7__collected_principal_rm) +
      //           parseFloat(item.d7__collected_profit_rm);
      //       }

      //       const salesgrowthresult: {
      //         month: string;
      //         ratio: number;
      //       }[] = Array.from(totalsSalesGrowth.entries()).map(
      //         ([month, { received_total, collected_total }]: any) => ({
      //           month,
      //           ratio:
      //             collected_total !== 0 ? collected_total / received_total : 0,
      //         })
      //       );

      //       setYearlyCurrentCollectionRate(salesgrowthresult);
      //     });
      //   })
      //   .catch((error: any) => {})
      //   .finally(() => {
      //     setHPCollectionYearCollectionRateLoading(false);
      //   });

      getLoanListFilter(selectedYearQuery, signal).then((data: any) => {
        if (data && Array.isArray(data)) {
          // Ensure that data is not null or undefined and is an array
          const YearlyStatisticsPromise = new Promise(
            (resolve: any, reject: any) => {
              data.forEach((item: any) => {
                year_due_to_receive +=
                  parseFloat(item.d0_principal_due_rm) +
                  parseFloat(item.d0_profit_due_rm) +
                  parseFloat(item.d1_principal_due_rm) +
                  parseFloat(item.d1_profit_due_rm) +
                  parseFloat(item.d2_principal_due_rm) +
                  parseFloat(item.d2_profit_due_rm) +
                  parseFloat(item.d3_principal_due_rm) +
                  parseFloat(item.d3_profit_due_rm) +
                  parseFloat(item.d4_principal_due_rm) +
                  parseFloat(item.d4_profit_due_rm) +
                  parseFloat(item.d5_principal_due_rm) +
                  parseFloat(item.d5_profit_due_rm) +
                  parseFloat(item.d6_principal_due_rm) +
                  parseFloat(item.d6_profit_due_rm) +
                  parseFloat(item.d7__principal_due_rm) +
                  parseFloat(item.d7__profit_due_rm);

                year_collected +=
                  parseFloat(item.d0_collected_principal_rm) +
                  parseFloat(item.d0_collected_profit_rm) +
                  parseFloat(item.d1_collected_principal_rm) +
                  parseFloat(item.d1_collected_profit_rm) +
                  parseFloat(item.d2_collected_principal_rm) +
                  parseFloat(item.d2_collected_profit_rm) +
                  parseFloat(item.d3_collected_principal_rm) +
                  parseFloat(item.d3_collected_profit_rm) +
                  parseFloat(item.d4_collected_principal_rm) +
                  parseFloat(item.d4_collected_profit_rm) +
                  parseFloat(item.d5_collected_principal_rm) +
                  parseFloat(item.d5_collected_profit_rm) +
                  parseFloat(item.d6_collected_principal_rm) +
                  parseFloat(item.d6_collected_profit_rm) +
                  parseFloat(item.d7__collected_principal_rm) +
                  parseFloat(item.d7__collected_profit_rm);

                if (year_due_to_receive != 0) {
                  year_collection_rate = year_collected / year_due_to_receive;
                } else {
                  year_collection_rate = 0;
                }

                setYearlyDuetoReceive(year_due_to_receive);
                setYearlyCollected(year_collected);
                setYearlyCollectionRate(year_collection_rate);
              });
              resolve();
              setHPCollectionYearStatisticLoading(false);
            }
          );
          const YearlyCollectionRatesPromise = new Promise(
            (resolve: any, reject: any) => {
              const totalsSalesGrowth: any = new Map();

              const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              months.forEach((month) => {
                totalsSalesGrowth.set(month, {
                  received_total: 0,
                  collected_total: 0,
                });
              });
              data.forEach((item: any) => {
                const reportDate = new Date(item.report_date);
                const monthIndex = reportDate.getMonth();
                const monthName = months[monthIndex];

                if (!totalsSalesGrowth.has(monthName)) {
                  totalsSalesGrowth.set(monthName, {
                    received_total: 0,
                    collected_total: 0,
                  });
                }

                const totals = totalsSalesGrowth.get(monthName);
                if (totals) {
                  totals.received_total +=
                    parseFloat(item.d0_principal_due_rm) +
                    parseFloat(item.d0_profit_due_rm) +
                    parseFloat(item.d1_principal_due_rm) +
                    parseFloat(item.d1_profit_due_rm) +
                    parseFloat(item.d2_principal_due_rm) +
                    parseFloat(item.d2_profit_due_rm) +
                    parseFloat(item.d3_principal_due_rm) +
                    parseFloat(item.d3_profit_due_rm) +
                    parseFloat(item.d4_principal_due_rm) +
                    parseFloat(item.d4_profit_due_rm) +
                    parseFloat(item.d5_principal_due_rm) +
                    parseFloat(item.d5_profit_due_rm) +
                    parseFloat(item.d6_principal_due_rm) +
                    parseFloat(item.d6_profit_due_rm) +
                    parseFloat(item.d7__principal_due_rm) +
                    parseFloat(item.d7__profit_due_rm);
                  totals.collected_total +=
                    parseFloat(item.d0_collected_principal_rm) +
                    parseFloat(item.d0_collected_profit_rm) +
                    parseFloat(item.d1_collected_principal_rm) +
                    parseFloat(item.d1_collected_profit_rm) +
                    parseFloat(item.d2_collected_principal_rm) +
                    parseFloat(item.d2_collected_profit_rm) +
                    parseFloat(item.d3_collected_principal_rm) +
                    parseFloat(item.d3_collected_profit_rm) +
                    parseFloat(item.d4_collected_principal_rm) +
                    parseFloat(item.d4_collected_profit_rm) +
                    parseFloat(item.d5_collected_principal_rm) +
                    parseFloat(item.d5_collected_profit_rm) +
                    parseFloat(item.d6_collected_principal_rm) +
                    parseFloat(item.d6_collected_profit_rm) +
                    parseFloat(item.d7__collected_principal_rm) +
                    parseFloat(item.d7__collected_profit_rm);
                }

                const salesgrowthresult: {
                  month: string;
                  ratio: number;
                }[] = Array.from(totalsSalesGrowth.entries()).map(
                  ([month, { received_total, collected_total }]: any) => ({
                    month,
                    ratio:
                      collected_total !== 0
                        ? collected_total / received_total
                        : 0,
                  })
                );

                setYearlyCurrentCollectionRate(salesgrowthresult);
              });
              resolve();
              setHPCollectionYearCollectionRateLoading(false);
            }
          );
          Promise.all([
            MonthlyStatisticsPromise,
            MonthlyCollectionByCategoryPromise,
            MonthlyAgingBucketPromise,
          ])
            .then(() => {
              // Chain the Yearly promises sequentially
              return YearlyStatisticsPromise.then(
                () => YearlyCollectionRatesPromise
              );
            })
            .catch((error: any) => {
              // Handle any errors from any of the groups
              console.error(error);
            })
            .finally(() => {});
        } else {
          console.error("Invalid data received");
        }
      });
    }
  };

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the current operation
    abortControllerRef.current = new AbortController();
    refresh_sales_by_product(
      defaultDateISO,
      PreviousDateISO,
      abortControllerRef.current.signal
    );
  }, []);

  const handleMenuClick = (e: any) => {
    setSelectedItem(e.key);
    // Abort any ongoing operation before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the current operation
    abortControllerRef.current = new AbortController();
    if (e.key === "Sales by product") {
      refresh_sales_by_product(
        defaultDateISO,
        PreviousDateISO,
        abortControllerRef.current.signal
      );
    } else if (e.key === "Collection by product") {
      refresh_collections_by_product(
        "Money Lending",
        defaultDateISO,
        PreviousDateISO,
        abortControllerRef.current.signal
      );
    }
    setSelectedSubItem("Money Lending");
  };

  const handleCollectionSubMenuClick = (e: any) => {
    setSelectedSubItem(e.key);
    // Abort any ongoing operation before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the current operation
    abortControllerRef.current = new AbortController();
    if (e.key === "Money Lending") {
      refresh_collections_by_product(
        "Money Lending",
        defaultDateISO,
        PreviousDateISO,
        abortControllerRef.current.signal
      );
    } else if (e.key === "Hire Purchase") {
      refresh_collections_by_product(
        "Hire Purchase",
        defaultDateISO,
        PreviousDateISO,
        abortControllerRef.current.signal
      );
    }
  };

  useEffect(() => {
    setSelectedDate(dayjs().subtract(1, "month").startOf("month"));
  }, [selectedItem, selectedSubItem]);

  return (
    <>
      {alertmsg && (
        <Alert
          className={
            alertmsg?.top ? "alert-message-box-top" : "alert-message-box"
          }
          type={alertmsg?.type}
          message={alertmsg?.message}
          description={alertmsg?.description}
          showIcon
          closable={alertmsg?.closeable}
          afterClose={() => setAlertmsg(null)}
          style={{
            fontFamily: "Motiva Sans",
          }}
        />
      )}

      <Space
        direction="horizontal"
        className="generic-dashboard-header"
        style={{
          width: "100%",
          gap: "20px",
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        <span
          style={{
            fontSize: "24px",
            // fontWeight: "600",
            width: "100%",
            fontFamily: "PP Neue Machina",
            fontWeight: "bold",
          }}
        >
          {props.params.propTitle} -{" "}
          <Dropdown
            menu={{
              items,
              onClick: handleMenuClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  {selectedItem}
                </span>
                <DownOutlined
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    fontSize: "14px",
                  }}
                />
              </Space>
            </a>
          </Dropdown>
          {selectedItem === "Collection by product" ? (
            <span style={{ paddingLeft: "20px" }}>
              <Dropdown
                menu={{
                  selectable: true,
                  items: collection_items,
                  onClick: handleCollectionSubMenuClick,
                  selectedKeys: [selectedSubItem], // Bind selectedKeys to state
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <FilterOutlined
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        fontSize: "14px",
                      }}
                    />
                  </Space>
                </a>
              </Dropdown>
            </span>
          ) : null}
        </span>
      </Space>
      {selectedItem === "Sales by product" ? (
        <>
          <div
            className="generic-dashboard"
            style={{ gap: "20px", display: "flex" }}
          >
            <div style={{ width: "65%" }}>
              <Card style={{ width: "100%" }}>
                <Spin spinning={monthloading}>
                  <span style={{ paddingBottom: "30px" }}>
                    Month to date
                    <DatePicker
                      size={"small"}
                      defaultValue={dayjs()
                        .subtract(1, "month")
                        .startOf("month")} // Set to the previous month
                      value={selectedDate}
                      disabledDate={disabledDate}
                      picker="month"
                      style={{
                        marginLeft: "10px",
                      }}
                      onChange={(date: any) => handleSelectedDate(date)} // Handle date change
                      allowClear={false}
                      format={customFormat}
                    ></DatePicker>
                  </span>
                  <Row gutter={8}>
                    <Col span={2}>
                      <DollarOutlined
                        style={{
                          fontSize: "45px",
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Total loans"
                        value={`RM${totalloan.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                        formatter={(value) => (
                          <span>
                            {value}{" "}
                            {totalloanpercentage > 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#D8FFE1CC",
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                <RiseOutlined />
                                {`+${totalloanpercentage.toFixed(2)}%`}
                              </span>
                            ) : totalloanpercentage < 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#FDAEAE",
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                <FallOutlined />
                                {`${totalloanpercentage.toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "rgba(128, 128, 128,0.1)",
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                <LineOutlined />
                                {` ${totalloanpercentage}%`}
                              </span>
                            )}
                            <br />
                            {totalloandifference > 0 ? (
                              <span
                                style={{
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                + RM
                                {totalloandifference.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            ) : totalloandifference < 0 ? (
                              <span
                                style={{
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                - RM
                                {Math.abs(totalloandifference).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                RM
                                {totalloandifference.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            )}
                          </span>
                        )}
                        valueStyle={{ fontSize: "14px" }}
                      />
                    </Col>
                    <Col span={2}>
                      <PlusCircleOutlined
                        style={{
                          fontSize: "45px",
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="New loans"
                        value={newloan}
                        formatter={(value) => (
                          <span>
                            {value}{" "}
                            {newloanpercentage > 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#D8FFE1CC",
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                <RiseOutlined />
                                {`+${newloanpercentage.toFixed(2)}%`}
                              </span>
                            ) : newloanpercentage < 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#FDAEAE",
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                <FallOutlined />
                                {`${newloanpercentage.toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "rgba(128, 128, 128,0.1)",
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                <LineOutlined />
                                {` ${newloanpercentage}%`}
                              </span>
                            )}
                          </span>
                        )}
                        valueStyle={{ fontSize: "14px" }}
                      />
                    </Col>
                    <Col span={2}>
                      <PlusCircleOutlined
                        style={{
                          fontSize: "45px",
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="New customer"
                        value={newcustomer}
                        formatter={(value) => (
                          <span>
                            {value}{" "}
                            {newcustomerpercentage > 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#D8FFE1CC",
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                <RiseOutlined />
                                {`+${newcustomerpercentage.toFixed(2)}%`}
                              </span>
                            ) : newcustomerpercentage < 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#FDAEAE",
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                <FallOutlined />
                                {`${newcustomerpercentage.toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "rgba(128, 128, 128,0.1)",
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                <LineOutlined />
                                {` ${newcustomerpercentage}%`}
                              </span>
                            )}
                          </span>
                        )}
                        valueStyle={{ fontSize: "14px" }}
                      />
                    </Col>
                  </Row>
                </Spin>
              </Card>

              <Card style={{ width: "100%", height: "100%" }}>
                <div style={{ display: "flex" }}>
                  <Card style={{ flex: 1 }}>
                    <Spin spinning={monthloading}>
                      <PieChart
                        totalloanLT={totalloanLT}
                        totalloanPFI={totalloanPFI}
                        totalloanCLI={totalloanCLI}
                        totalloanHP={totalloanHP}
                        totalloan={totalloan}
                        totalloanpercentage={totalloanpercentage}
                        title="Total loan by type"
                      />
                    </Spin>
                  </Card>

                  <Card style={{ flex: 1 }}>
                    <Spin spinning={monthloading}>
                      <PieLabel
                        totalloannewcustomer={totalloannewcustomer}
                        totalloanreturningcustomer={totalloanreturningcustomer}
                        totalloanexistingcustomer={totalloanexistingcustomer}
                        totalloancustomer={totalloancustomer}
                        totalloanpercentage={totalcustomerpercentage}
                        title="Customer type breakdown"
                      />
                    </Spin>
                  </Card>
                </div>

                <div style={{ display: "flex", height: "400px" }}>
                  <Card style={{ flex: 1 }}>
                    <Spin spinning={monthloanloading}>
                      <BarStack
                        barstackData={barstackData}
                        onYearChange={handleBarStackYear}
                        selectedItem={selectedItem}
                        title="Monthly loan overview by type"
                      />
                    </Spin>
                  </Card>
                  <Card style={{ flex: 1 }}>
                    <Spin spinning={salesgrowthloading}>
                      <BarGroup
                        barGroupData={barGroupData}
                        onYearChange={handleBarGroupYear}
                        title="Sales growth by type"
                      />
                    </Spin>
                  </Card>
                </div>
              </Card>
            </div>
            <div style={{ width: "35%" }}>
              <Space
                direction="vertical"
                style={{ width: "100%", height: "100%" }}
              >
                <Spin spinning={financecardloading}>
                  <Card style={{ width: "100%", height: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontFamily: "PP Neue Machina",
                          fontSize: "16px",
                        }}
                      >
                        Transaction & Financing Volume
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Card
                        title={
                          <div
                            style={{
                              paddingTop: "3%",
                              paddingBottom: "0%",
                              color: "#ffffff",
                            }}
                          >
                            RM {currentfinanceLT.toLocaleString()}
                            <br />
                            {financeLTpercentage > 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#D8FFE1CC",
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                <RiseOutlined />
                                {`+${financeLTpercentage.toFixed(2)}%`}
                              </span>
                            ) : financeLTpercentage < 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#FDAEAE",
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                <FallOutlined />
                                {`${financeLTpercentage.toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "rgba(128, 128, 128,0.1)",
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                <LineOutlined />
                                {` ${financeLTpercentage}%`}
                              </span>
                            )}
                            <span style={{ paddingLeft: "5px" }}>
                              vs. FYE{FinanceYear}
                            </span>
                          </div>
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          background:
                            "linear-gradient(180deg, #4C9AAF 0%, #33798C 100%)",
                          color: "#ffffff",
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                          Loan Term
                        </span>
                      </Card>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Card
                        title={
                          <div
                            style={{
                              paddingTop: "3%",
                              paddingBottom: "0%",
                              color: "#ffffff",
                            }}
                          >
                            RM {currentfinancePFI.toLocaleString()}
                            <br />
                            {financePFIpercentage > 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#D8FFE1CC",
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                <RiseOutlined />
                                {`+${financePFIpercentage.toFixed(2)}%`}
                              </span>
                            ) : financePFIpercentage < 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#FDAEAE",
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                <FallOutlined />
                                {`${financePFIpercentage.toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "rgba(128, 128, 128,0.1)",
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                <LineOutlined />
                                {` ${financePFIpercentage}%`}
                              </span>
                            )}
                            <span style={{ paddingLeft: "5px" }}>
                              vs. FYE{FinanceYear}
                            </span>
                          </div>
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          background:
                            "linear-gradient(180deg, #DA62C4 0%, #B1489E 100%)",
                          color: "#ffffff",
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                          Personal Financing-i
                        </span>
                      </Card>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Card
                        title={
                          <div
                            style={{
                              paddingTop: "3%",
                              paddingBottom: "0%",
                              color: "#ffffff",
                            }}
                          >
                            RM {currentfinanceCLI.toLocaleString()}
                            <br />
                            {financeCLIpercentage > 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#D8FFE1CC",
                                  color: "rgba(40, 167, 69, 0.85)",
                                }}
                              >
                                <RiseOutlined />
                                {`+${financeCLIpercentage.toFixed(2)}%`}
                              </span>
                            ) : financeCLIpercentage < 0 ? (
                              <span
                                style={{
                                  backgroundColor: "#FDAEAE",
                                  color: "rgb(229, 56, 61,0.85)",
                                }}
                              >
                                <FallOutlined />
                                {`${financeCLIpercentage.toFixed(2)}%`}
                              </span>
                            ) : (
                              <span
                                style={{
                                  backgroundColor: "rgba(128, 128, 128,0.1)",
                                  color: "rgba(128, 128, 128, 0.85)",
                                }}
                              >
                                <LineOutlined />
                                {` ${financeCLIpercentage}%`}
                              </span>
                            )}
                            <span style={{ paddingLeft: "5px" }}>
                              vs. FYE{FinanceYear}
                            </span>
                          </div>
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          background:
                            "linear-gradient(180deg, #997AFC 0%, #7F4AFA 100%)",
                          color: "#ffffff",
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                          Cash Line-i
                        </span>
                      </Card>
                    </div>
                  </Card>
                </Spin>
                <Card style={{ width: "100%", height: "100%" }}>
                  <Spin spinning={financePieloading}>
                    <PieMultiChart
                      previous_year_total_PFIQ={previous_year_total_PFIQ}
                      previous_year_total_CLIQ={previous_year_total_CLIQ}
                      previous_year_total_LTQ={previous_year_total_LTQ}
                      current_year_total_LTQ={current_year_total_LTQ}
                      current_year_total_CLIQ={current_year_total_CLIQ}
                      current_year_total_PFIQ={current_year_total_PFIQ}
                      StartFinanceYearQ={StartFinanceYearQ}
                      EndFinanceYearQ={EndFinanceYearQ}
                      title="Transaction & Volume by Types"
                    />
                  </Spin>
                </Card>
              </Space>
            </div>
          </div>
        </>
      ) : (
        <>
          {selectedSubItem === "Money Lending" ? (
            <div className="generic-container" style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <Card>
                  <Spin spinning={MLcollectionmonthstatisticloading}>
                    <span style={{ paddingBottom: "30px" }}>
                      Month to date
                      <DatePicker
                        size={"small"}
                        defaultValue={dayjs()
                          .subtract(1, "month")
                          .startOf("month")} // Set to the previous month
                        value={selectedDate}
                        disabledDate={disabledDate}
                        picker="month"
                        style={{
                          marginLeft: "10px",
                        }}
                        onChange={(date: any) => handleSelectedDate(date)} // Handle date change
                        allowClear={false}
                        format={customFormat}
                      ></DatePicker>
                    </span>
                    <Row gutter={8}>
                      <Col span={2}>
                        <DollarOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Due to receive"
                          value={`RM ${Math.ceil(
                            monthly_due_to_receive
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collected"
                          // value={monthly_collected}
                          value={`RM ${Math.ceil(
                            monthly_collected
                          ).toLocaleString(undefined)}`}
                          formatter={(value) => (
                            <span>
                              {value}{" "}
                              {monthly_collected_percentage > 0 ? (
                                <span
                                  style={{
                                    backgroundColor: "#D8FFE1CC",
                                    color: "rgba(40, 167, 69, 0.85)",
                                  }}
                                >
                                  <RiseOutlined />
                                  {`+${monthly_collected_percentage.toFixed(
                                    2
                                  )}%`}
                                </span>
                              ) : monthly_collected_percentage < 0 ? (
                                <span
                                  style={{
                                    backgroundColor: "#FDAEAE",
                                    color: "rgb(229, 56, 61,0.85)",
                                  }}
                                >
                                  <FallOutlined />
                                  {`${monthly_collected_percentage.toFixed(
                                    2
                                  )}%`}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: "rgba(128, 128, 128,0.1)",
                                    color: "rgba(128, 128, 128, 0.85)",
                                  }}
                                >
                                  <LineOutlined />
                                  {` ${monthly_collected_percentage}%`}
                                </span>
                              )}
                            </span>
                          )}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collection rate"
                          value={`${monthly_collection_rate.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}%`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Advance"
                          value={`RM ${Math.ceil(
                            monthly_advance
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Full Settlement"
                          value={`RM ${Math.ceil(
                            monthly_full_settlement
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                    </Row>
                  </Spin>
                </Card>

                <Card>
                  <Spin spinning={MLcollectionmonthcategoryloading}>
                    <PlotlyBarChart
                      title="Collection by category"
                      height={700}
                      data={monthly_collection_by_category}
                    />
                  </Spin>
                </Card>

                <Card>
                  <Spin spinning={MLcollectionmonthagingbucketloading}>
                    <PlotlyAgingBuckets
                      title="Aging Buckets"
                      x={[
                        "Upcoming",
                        "Past due 1-30 days",
                        "Past due 31-60 days",
                        "Past due 61-90 days",
                        "Past due more than 91 days",
                      ]}
                      y={monthly_aging_buckets}
                      height={700}
                    />
                  </Spin>
                </Card>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <Card>
                  <Spin spinning={MLcollectionyearstatisticloading}>
                    <span style={{ paddingBottom: "30px" }}>
                      Year to date{" "}
                      <DatePicker
                        size={"small"}
                        disabledDate={disabledDate}
                        picker="year"
                        defaultValue={dayjs(`${selectedYear}-01-01`)}
                        onChange={handleYearChange}
                        style={{ marginLeft: 16 }}
                      />
                    </span>
                    <Row gutter={8}>
                      <Col span={2}>
                        <DollarOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Due to receive"
                          value={`RM ${Math.ceil(
                            yearly_due_to_receive
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collected"
                          // value={yearly_collected}
                          value={`RM ${Math.ceil(
                            yearly_collected
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collection rate"
                          value={`${yearly_collection_rate.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}%`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={8} style={{ height: "48px" }}></Col>
                    </Row>
                  </Spin>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    // height: "850px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Card style={{ flex: 1 }}>
                      <Spin spinning={MLcollectionyearcollectionrateloading}>
                        <PlotlyScatterPlot
                          title="Current collection rate"
                          x={yearly_current_collection_rate.map((item: any) =>
                            item.month.slice(0, 3)
                          )}
                          y={yearly_current_collection_rate.map(
                            (item: any) => item.ratio
                          )}
                          height={600}
                        />
                      </Spin>
                    </Card>
                  </div>
                  {selectedSubItem === "Money Lending" && (
                    <div
                      style={{
                        display: "flex",
                        height: "400px",
                      }}
                    >
                      <Card style={{ flex: 1 }}>
                        <Spin spinning={MLcollectionyearmonthcollectionloading}>
                          <BarStack
                            barstackData={yearly_monthly_collection}
                            selectedItem={selectedItem}
                            title="Monthly collection"
                            height={600}
                          />
                        </Spin>
                      </Card>
                    </div>
                  )}
                  {selectedSubItem === "Money Lending" && (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Card style={{ flex: 1 }}>
                        <Spin
                          spinning={MLcollectionyearnonperformingloanloading}
                        >
                          <PlotlyBarLineChart
                            title="Non-Performing Loan"
                            x={yearly_non_performing_loan.map((item: any) =>
                              item.month.slice(0, 3)
                            )}
                            y1={yearly_non_performing_loan.map((item: any) =>
                              Math.round(item.receivable_total).toLocaleString()
                            )}
                            y2={yearly_non_performing_loan.map(
                              (item: any) => item.ratio
                            )}
                            height={600}
                          />
                        </Spin>
                      </Card>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          ) : (
            <div
              className="generic-container"
              style={{ width: "100%", height: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <Card>
                  <Spin spinning={HPcollectionmonthstatisticloading}>
                    <span style={{ paddingBottom: "30px" }}>
                      Month to date
                      <DatePicker
                        size={"small"}
                        defaultValue={dayjs()
                          .subtract(1, "month")
                          .startOf("month")} // Set to the previous month
                        value={selectedDate}
                        disabledDate={disabledDate}
                        picker="month"
                        style={{
                          marginLeft: "10px",
                        }}
                        onChange={(date: any) => handleSelectedDate(date)} // Handle date change
                        allowClear={false}
                        format={customFormat}
                      ></DatePicker>
                    </span>
                    <Row gutter={8}>
                      <Col span={2}>
                        <DollarOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Due to receive"
                          value={`RM ${Math.ceil(
                            monthly_due_to_receive
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collected"
                          // value={monthly_collected}
                          value={`RM ${Math.ceil(
                            monthly_collected
                          ).toLocaleString(undefined)}`}
                          formatter={(value) => (
                            <span>
                              {value}{" "}
                              {monthly_collected_percentage > 0 ? (
                                <span
                                  style={{
                                    backgroundColor: "#D8FFE1CC",
                                    color: "rgba(40, 167, 69, 0.85)",
                                  }}
                                >
                                  <RiseOutlined />
                                  {`+${monthly_collected_percentage.toFixed(
                                    2
                                  )}%`}
                                </span>
                              ) : monthly_collected_percentage < 0 ? (
                                <span
                                  style={{
                                    backgroundColor: "#FDAEAE",
                                    color: "rgb(229, 56, 61,0.85)",
                                  }}
                                >
                                  <FallOutlined />
                                  {`${monthly_collected_percentage.toFixed(
                                    2
                                  )}%`}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: "rgba(128, 128, 128,0.1)",
                                    color: "rgba(128, 128, 128, 0.85)",
                                  }}
                                >
                                  <LineOutlined />
                                  {` ${monthly_collected_percentage}%`}
                                </span>
                              )}
                            </span>
                          )}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collection rate"
                          value={`${monthly_collection_rate.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}%`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Advance"
                          value={`RM ${Math.ceil(
                            monthly_advance
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Full Settlement"
                          value={`RM ${Math.ceil(
                            monthly_full_settlement
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                    </Row>
                  </Spin>
                </Card>

                <Card>
                  <Spin spinning={HPcollectionmonthcategoryloading}>
                    <PlotlyBarChart
                      title="Collection by category"
                      height={700}
                      data={monthly_collection_by_category}
                    />
                  </Spin>
                </Card>

                <Card>
                  <Spin spinning={HPcollectionmonthagingbucketloading}>
                    <PlotlyAgingBuckets
                      title="Aging Buckets"
                      x={[
                        "Upcoming",
                        "Past due 1-30 days",
                        "Past due 31-60 days",
                        "Past due 61-90 days",
                        "Past due more than 91 days",
                      ]}
                      y={monthly_aging_buckets}
                      height={700}
                    />
                  </Spin>
                </Card>
              </div>
              <div style={{ width: "100%", height: "100%" }}>
                <Card>
                  <Spin spinning={HPcollectionyearstatisticloading}>
                    <span style={{ paddingBottom: "30px" }}>
                      Year to date{" "}
                      <DatePicker
                        size={"small"}
                        disabledDate={disabledDate}
                        picker="year"
                        defaultValue={dayjs(`${selectedYear}-01-01`)}
                        onChange={handleYearChange}
                        style={{ marginLeft: 16 }}
                      />
                    </span>
                    <Row gutter={8}>
                      <Col span={2}>
                        <DollarOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Due to receive"
                          value={`RM ${Math.ceil(
                            yearly_due_to_receive
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collected"
                          // value={yearly_collected}
                          value={`RM ${Math.ceil(
                            yearly_collected
                          ).toLocaleString(undefined)}`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={2}>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "45px",
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <Statistic
                          title="Collection rate"
                          value={`${yearly_collection_rate.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}%`}
                          valueStyle={{ fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={8} style={{ height: "48px" }}></Col>
                    </Row>
                  </Spin>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    // height: "850px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Card style={{ flex: 1 }}>
                      <Spin spinning={HPcollectionyearcollectionrateloading}>
                        <PlotlyScatterPlot
                          title="Current collection rate"
                          // x={["Jan", "Feb"]}
                          // y={[0.4271, 0.4247]}
                          x={yearly_current_collection_rate.map((item: any) =>
                            item.month.slice(0, 3)
                          )}
                          y={yearly_current_collection_rate.map(
                            (item: any) => item.ratio
                          )}
                          height={600}
                        />
                      </Spin>
                    </Card>
                  </div>
                  {selectedSubItem === "Money Lending" && (
                    <div
                      style={{
                        display: "flex",
                        // height: "400px",
                      }}
                    >
                      <Card style={{ flex: 1 }}>
                        <Spin spinning={MLcollectionyearmonthcollectionloading}>
                          <BarStack
                            barstackData={yearly_monthly_collection}
                            selectedItem={selectedItem}
                            title="Monthly collection"
                            height={600}
                          />
                        </Spin>
                      </Card>
                    </div>
                  )}
                  {selectedSubItem === "Money Lending" && (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Card style={{ flex: 1 }}>
                        <Spin
                          spinning={MLcollectionyearnonperformingloanloading}
                        >
                          <PlotlyBarLineChart
                            title="Non-Performing Loan"
                            x={yearly_non_performing_loan.map((item: any) =>
                              item.month.slice(0, 3)
                            )}
                            y1={yearly_non_performing_loan.map((item: any) =>
                              Math.round(item.receivable_total).toLocaleString()
                            )}
                            y2={yearly_non_performing_loan.map(
                              (item: any) => item.ratio
                            )}
                            height={600}
                          />
                        </Spin>
                      </Card>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ExplorerContainer;
