import axios from "axios";
import {
  url,
  AuthHeader,
  serverConnection,
  validateAndHandleToken,
} from "../../utils/_exports";
const apiURL = "loan";

// policy GET route
export const getLoanList = async () => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}/`, { ...AuthHeader })
        .then((_data: any) => {
          if (_data?.data.data.data && _data.data.data.data.length) {
            resolve(_data?.data.data.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy GET route by policy id
export const getLoanListById = async (policy_id: string) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}/`, {
          params: { policy_id },
          ...AuthHeader,
        })
        .then((_data: any) => {
          if (_data?.data.data.data && _data.data.data.data.length) {
            resolve(_data?.data.data.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// loan GET route by selected field
export const getLoanListByField = async (param: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}`, {
          params: param,
          ...AuthHeader,
        })
        .then((_data: any) => {
          if (_data?.data) {
            resolve(_data?.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

export const getLoanListFilter = async (params: any, signal?: AbortSignal) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    const { filter, project } = params;
    const queryParams = new URLSearchParams({
      filter: JSON.stringify(filter),
      project: JSON.stringify(project),
    });
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}`, {
          params: queryParams,
          ...AuthHeader,
          signal,
        })
        .then((_data: any) => {
          if (_data?.data) {
            resolve(_data?.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          // Handle cancellation specifically
          if (axios.isCancel(e)) {
            // console.log("Request cancelled");
            reject(new Error("Request cancelled"));
          } else {
            reject(e);
          }
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy POST route
export const addLoanItem = async (data: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
        .then((_data: any) => {
          resolve(_data?.data.data.data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// to save policy file
export const updateLoanFileItem = async (_ids: Array<any>, data: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    const _data = {
      // query: { _id: { $in: _ids } },
      query: { _id: _ids[0] },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axios
        // .put(`${url}/${apiURL}/`, _data, AuthHeader)
        .put(`${serverConnection}/${apiURL}/saveFile`, _data, AuthHeader)
        .then((_data: any) => {
          //console.log(_data);
          resolve(_data?.data?.data?.data);
        })
        .catch((e: any) => {
          //console.log(e);
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy PUT route
export const updateLoanItem = async (_ids: Array<any>, data: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    const _data = {
      query: { _id: _ids[0] },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
        .then((_data: any) => {
          resolve(_data?.data.data.data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy DELETE route
export const deleteLoanItem = async (_id: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .delete(`${serverConnection}/${apiURL}`, {
          params: _id,
          ...AuthHeader,
        })
        .then((_data: any) => {
          resolve(_data?.data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// loan POST selected route
export const generateLoanReport = async (date: any, data: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${serverConnection}/generate_excel/excel_report`, data, {
          ...AuthHeader,
          responseType: "blob",
        })
        .then((response: any) => {
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${data.report_title}.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          resolve();
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};
