import React, { useEffect, useState } from "react";

import { Button, Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";

const SearchButton = (props: any) => {
  const [searchState, setSearchState] = useState<any>(false);
  const [searchValue, setSearchValue] = useState<any>(null);

  useEffect(() => {
    if (props.resetSearch === true) {
      setSearchValue(null);
    }
  }, [props.resetSearch]);

  const handleSearch = (e: any) => {
    if (e.type == "change") {
      setSearchValue(e.target.value);
      e.stopPropagation();
    } else {
      e.stopPropagation();
      props.handleSearch({
        columnKey: props.columnKey,
        value: "",
      });
      setSearchValue(null);
      setSearchState(false);
    }
  };

  const handleSearchEnter = (e: any) => {
    e.stopPropagation();
    props.handleSearch({
      columnKey: props.columnKey,
      value: e.target.value,
    });
    setSearchState(false);
  };

  return searchState ? (
    <Input
      className="input-clearable"
      onChange={handleSearch}
      onBlur={handleSearchEnter}
      onPressEnter={handleSearchEnter}
      value={searchValue}
      allowClear
      autoFocus
      prefix={<SearchOutlined />}
    />
  ) : (
    <Button
      onClick={(e: any) => {
        e.stopPropagation();
        setSearchState(true);
      }}
      style={{
        border: "none",
        boxShadow: "none",
        background: "transparent",
      }}
      icon={<SearchOutlined />}
    >
      {props.headerName}{" "}
      {searchValue != null && searchValue !== "" && `(${searchValue})`}
    </Button>
  );
};

export default SearchButton;
