import { Datum } from "plotly.js";
import Plot from "react-plotly.js";
import { getRange } from "../../utils/utils";

type PlotlyBarLineChartProps = {
  title?: string;
  height?: number;
  x?: Datum[];
  y1?: Datum[];
  y2?: Datum[];
  tickStep?: number;
};

const PlotlyBarLineChart = ({
  title = "Title",
  height,
  x = [],
  y1 = [],
  y2 = [],
  tickStep = 500000,
}: PlotlyBarLineChartProps) => {
  const [y1Min, y1Max] = getRange(...(y1 as number[]));
  const [_, y2Max] = getRange(...(y2 as number[]));
  return (
    <Plot
      data={[
        {
          x,
          y: y1,
          type: "bar",
          textposition: "outside",
          text: y1 as string[],
          name: "Receivables",
        },
        {
          x,
          y: y2,
          type: "scatter",
          mode: "text+lines",
          yaxis: "y2",
          text: y2.map((val) => `${((val as number) * 100).toFixed(2)}%`),
          textposition: "top center",
          line: { width: 4, smoothing: 10 },
          name: "NPL",
        },
      ]}
      layout={{
        title: {
          text: title,
          font: {
            family: "PP Neue Machina",
            size: 16,
          },
        },
        showlegend: true,
        // margin: { l: 0, r: 0, b: 0, t: 0 },
        legend: {
          x: 1,
          y: 1.25,
          xanchor: "left",
          yanchor: "top",
          itemclick: false,
          itemdoubleclick: false,
        },
        height,
        yaxis: {
          tickformat: ",",
          range: [
            y1Min - (y1Min % tickStep) - tickStep,
            y1Max - (y1Max % tickStep) + tickStep,
          ],
          tick0: 60000000,
          showgrid: false,
          zeroline: true,
        },
        yaxis2: {
          overlaying: "y",
          range: [-0.02, y2Max - (y2Max % 0.01) + 0.01],
          tickformat: ".2%",
          side: "right", // Place y2 on the right
          tick0: 0,
          dtick: 0.01,
          zerolinecolor: "#eee",
        },
        bargap: 0.5,
        font: {
          family: "Motiva Sans",
        },
      }}
      config={{
        displaylogo: false, // Remove the Plotly logo
        responsive: true,
      }}
    />
  );
};

export default PlotlyBarLineChart;
