import React from "react";
import Plot from "react-plotly.js";

const PieMultiChart = (props: any) => {
  const previousvalues = [];
  const previouslabels = [];
  const previouscolors = [];

  if (props.previous_year_total_LTQ !== 0) {
    previousvalues.push(props.previous_year_total_LTQ);
    previouslabels.push("Loan Term (LT)");
    previouscolors.push("#3e879b"); // Set color for Loan Term (LT)
  }
  if (props.previous_year_total_PFIQ !== 0) {
    previousvalues.push(props.previous_year_total_PFIQ);
    previouslabels.push("Personal Financing-i (PF-i)");
    previouscolors.push("#c454b0"); // Set color for Personal Financing-i (PFI)
  }
  if (props.previous_year_total_CLIQ !== 0) {
    previousvalues.push(props.previous_year_total_CLIQ);
    previouslabels.push("Cash Line-i (CL-i)");
    previouscolors.push("#9573fb"); // Set color for Cash Line-i (CLI)
  }

  const currentvalues = [];
  const currentlabels = [];
  const currentcolors = [];

  if (props.current_year_total_LTQ !== 0) {
    currentvalues.push(props.current_year_total_LTQ);
    currentlabels.push("Loan Term (LT)");
    currentcolors.push("#3e879b"); // Set color for Loan Term (LT)
  }
  if (props.current_year_total_PFIQ !== 0) {
    currentvalues.push(props.current_year_total_PFIQ);
    currentlabels.push("Personal Financing-i (PF-i)");
    currentcolors.push("#c454b0"); // Set color for Personal Financing-i (PFI)
  }
  if (props.current_year_total_CLIQ !== 0) {
    currentvalues.push(props.current_year_total_CLIQ);
    currentlabels.push("Cash Line-i (CL-i)");
    currentcolors.push("#9573fb"); // Set color for Cash Line-i (CLI)
  }

  return (
    <Plot
      data={[
        {
          labels: previouslabels,
          values: previousvalues,
          marker: {
            colors: previouscolors, // Apply colors to segments
          },
          type: "pie",
          hole: 0.7,
          name: "",
          domain: {
            y: [0.6, 1],
          },
          textinfo: "label+percent",
          hovertemplate: "%{label}<br>RM%{value}<br>%{percent}<extra></extra>", // Add RM and percentage to the hover text
          texttemplate: "%{label}<br>RM%{value}", // Add RM to the value
          automargin: true,
          title: {
            text: props.EndFinanceYearQ,
            font: {
              family: "PP Neue Machina",
              size: 16,
            },
            position: "middle center",
          },
        },
        {
          labels: currentlabels,
          values: currentvalues,
          marker: {
            colors: currentcolors, // Apply colors to segments
          },
          type: "pie",
          domain: {
            y: [0, 0.4],
          },
          hole: 0.7,
          name: "",
          textinfo: "label+percent",
          hovertemplate: "%{label}<br>RM%{value}<br>%{percent}<extra></extra>", // Add RM and percentage to the hover text
          texttemplate: "%{label}<br>RM%{value}", // Add RM to the value
          automargin: true,
          title: {
            text: props.StartFinanceYearQ,
            font: {
              family: "PP Neue Machina",
              size: 16,
            },
            position: "middle center",
          },
        },
      ]}
      layout={{
        title: {
          text: props.title,
          font: {
            family: "PP Neue Machina",
            size: 16,
          },
        },
        height: 500,
        margin: { t: 50, b: 0, l: 5, r: 0 },

        showlegend: false,
        font: {
          family: "Motiva Sans",
        },
      }}
      config={{
        displaylogo: false, // Remove the Plotly logo
        responsive: true,
      }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default PieMultiChart;
