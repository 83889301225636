import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { accessToken, validateAndHandleToken } from "../utils/_exports";

interface ProtectedRoutePros extends PropsWithChildren {
  isLogin: boolean; // Add isLogin prop
}

const ProtectedRoute = ({ children, isLogin }: ProtectedRoutePros) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin || !accessToken) {
      // Redirect to the login page if not logged in or no token
      navigate("/", { replace: true });
    } else {
      // Validate the token
      validateAndHandleToken();
    }
  }, [navigate, isLogin]);

  if (!isLogin || !accessToken) {
    return null; // Return nothing while redirecting
  }

  return <>{children}</>;
};

export default ProtectedRoute;
