import axios from "axios";
import { AuthHeader, url, validateAndHandleToken,FileAuthHeader } from "../../utils/_exports";
const apiURL = "file_op";

// Get file
export const fetchFile = async (filename: any, uuid: any, folder_name: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      // console.log(filename, uuid, folder_name);
      axios
        .get(
          `${url}/${apiURL}/download?filename=${filename}&uuid=${uuid}&folder_name=${folder_name}`,
          {
            ...AuthHeader,
            responseType: "blob",
          }
        )
        .then((response) => {
          try {
            const url = window.URL.createObjectURL(response.data);
            // window.open(url, "__blank__");
            resolve(url);
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// // Download file
// export const downloadFile = (filename: any, uuid: any, folder_name: any) => {
//   return new Promise<void>((resolve, reject) => {
//     axios
//       .get(
//         `${url}/${apiURL}/download?filename=${filename}&uuid=${uuid}&folder_name=${folder_name}`,
//         {
//           ...AuthHeader,
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const url = window.URL.createObjectURL(response.data);
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = filename;
//         document.body.appendChild(a);
//         a.click();
//         document.body.removeChild(a);
//         window.URL.revokeObjectURL(url);
//         resolve();
//       })
//       .catch((e: any) => {
//         reject(e);
//       });
//   });
// };

// Download file
export const downloadFile = async (filename: any, queryParams: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise<void>((resolve, reject) => {
      const queryString = new URLSearchParams(queryParams).toString();
      axios
        .get(`${url}/${apiURL}/download?${queryString}`, {
          ...AuthHeader,
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          resolve();
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// Upload file
export const uploadFile = async (formData: any, queryParams: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      // console.log("formData", formData);
      // const queryString = new URLSearchParams(queryParams).toString();
      // const requestUrl = `${url}/${apiURL}/upload?${queryString}`;
      const requestUrl = `${url}/${apiURL}/upload`;

      axios
        .post(requestUrl, formData, FileAuthHeader)
        .then((_data: any) => {
          //console.log(_data);
          resolve(_data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// Delete file
export const deleteFile = async (uuid: any, folder_name: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url}/${apiURL}?uuid=${uuid}&folder_name=${folder_name}`, {
          ...AuthHeader,
        })
        .then((_data: any) => {
          resolve(_data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};
