import axios from "axios";
import {
  url,
  AuthHeader,
  serverConnection,
  validateAndHandleToken,
} from "../../utils/_exports";
const apiURL = "excel_file";

// policy GET route
export const getExcelList = async () => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}`, { ...AuthHeader })
        .then((_data: any) => {
          if (_data?.data && _data.data.length) {
            resolve(_data?.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy GET route by policy id
export const getExcelListById = async (_id: string) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}/`, {
          params: _id,
          ...AuthHeader,
        })
        .then((_data: any) => {
          if (_data?.data.data.data && _data.data.data.data.length) {
            resolve(_data?.data.data.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// loan GET route by selected field
export const getExcelListByField = async (param: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${serverConnection}/${apiURL}`, {
          params: param,
          ...AuthHeader,
        })

        .then((_data: any) => {
          if (_data?.data) {
            resolve(_data?.data);
          } else {
            resolve(null);
          }
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy POST route
export const addExcelItem = async (data: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
        .then((_data: any) => {
          resolve(_data?.data.data.data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// to save policy file
export const updateExcelFileItem = async (_ids: Array<any>, data: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    const _data = {
      query: { _id: _ids[0] },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axios
        // .put(`${url}/${apiURL}/`, _data, AuthHeader)
        .put(`${serverConnection}/${apiURL}/saveFile`, _data, AuthHeader)
        .then((_data: any) => {
          //console.log(_data);
          resolve(_data?.data?.data?.data);
        })
        .catch((e: any) => {
          //console.log(e);
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy PUT route
export const updateExcelItem = async (data: Array<any>) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .put(`${serverConnection}/${apiURL}`, data, AuthHeader)
        .then((_data: any) => {
          resolve(_data?.data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

// policy DELETE route
export const deleteExcelItem = async (_id: any) => {
  try {
    const isValid = await validateAndHandleToken();
    if (!isValid) {
      // If token validation fails, log the user out (handled in validateAndHandleToken)
      return null; // Stop execution
    }
    return new Promise((resolve, reject) => {
      axios
        .delete(`${serverConnection}/custom_crud/remove_excel`, {
          params: _id,
          ...AuthHeader,
        })
        .then((_data: any) => {
          resolve(_data?.data);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  } catch (error) {
    console.error("Error fetching Excel list:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};
