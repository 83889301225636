/**
 * 	Terra's main page, contains the Layout, Header, Footer, and Side Menu.
 *  Any other content will be available in the <Outlet/> element, which renders
 * 	components based on the current endpoint in the URL
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { MainContext } from "../contexts/context";
import { Breadcrumb, Dropdown, Image, Input, Layout, Space, Spin } from "antd";
import { Outlet, useNavigate } from "react-router";
// import elementalLogo from "../assets/image/elementalLogo.png";
import rexLogo from "../assets/image/rex_technology_logo.png";
import MainMenu from "../services/MainMenu";
import Emitter from "../utils/emitter";
import file from "../../package.json";
import { isMobile } from "react-device-detect";

const { Header, Content, Footer } = Layout;

export const HomeContext = React.createContext({});

const Home = (props: any) => {
  const navigate = useNavigate();
  const userInfo = props?.userInfo.userRole[0];
  const formUpdatingRef = useRef<boolean>(false);
  const [menuProps, setMenuProps] = useState<any>([]);
  const [breadcrumb, setBreadcrumb] = useState<any>([
    {
      title: (
        <a onClick={() => handleBreadCrumbNavigate("/report_generation")}>
          Home
        </a>
      ),
    },
  ]);
  const [isLoading, setLoading] = useState<any>(false);
  const [selectedKey, setSelectedKey] = useState<any>([]);
  const [isCollapsed, setCollapsed] = useState<boolean>(true);

  const [pageChange, setPageChange] = useState<boolean>(false);

  const handleBreadCrumbNavigate = async (link: any) => {
    try {
      await handlePageChange();
      navigate(link);
    } catch {}
  };

  useEffect(() => {
    Emitter.on("menuChange", (menuProps: any) => {
      if (
        localStorage.getItem("selectedFilters") &&
        menuProps.container !== "projectsOverview"
      ) {
        localStorage.removeItem("selectedFilters");
      }
      setMenuProps(menuProps);
    });

    Emitter.on("loading", () => {
      setLoading(true);
    });

    Emitter.on("finish-loading", () => {
      setLoading(false);
    });

    // Emitter on tab container (sorry Irham)
    Emitter.on("configuration", (directory: any) => {
      let breadcrumbList = breadcrumb.slice(0, 1);
      breadcrumbList.push({
        title: (
          <a onClick={() => handleBreadCrumbNavigate("/report_generation")}>
            Report generation
          </a>
        ),
      });
      breadcrumbList.push({ title: directory });
      setBreadcrumb(breadcrumbList);
    });
  }, []);

  useEffect(() => {
    let menu = props.menu;
    let currentlocation = "";
    currentlocation = props.currentLocation.split("/");

    let menuItem = menu.find((element: any) => {
      // Previous Menu key checks, now to set it up differently
      // let route = element?.item?.to.split("/").pop();
      // return route === currentlocation;

      // Temporary check for current menu item location
      let route: any = element?.item?.to.split("/").pop();
      return currentlocation?.includes(route);
    });
    if (menuItem) {
      if (menuItem?.item?.requires_admin == true) {
        setMenuProps([{ title: "Administration" }, menuItem?.item]);
      } else {
        setMenuProps([menuItem?.item]);
      }

      setSelectedKey(menuItem.item.key);
    }
  }, [props.currentLocation]);

  useEffect(() => {
    let breadcrumbList: any;
    if (userInfo !== "admin") {
      breadcrumbList = [
        { title: <span style={{ color: "#00000073" }}>Home</span> },
      ];
    } else {
      breadcrumbList = [
        {
          title: (
            <a onClick={() => handleBreadCrumbNavigate("/report_generation")}>
              Home
            </a>
          ),
        },
      ];
    }

    // Set default breadcrumb for project config
    let locationArray = props.currentLocation.split("/");
    if (locationArray.includes("project-config")) {
      breadcrumbList.push({
        title: (
          <a onClick={() => handleBreadCrumbNavigate("/projects")}>Projects</a>
        ),
      });
    }

    menuProps.forEach((menuItem: any, index: any) => {
      let breadcrumbItem: any = {};
      if (index == menuProps.length - 1 || !menuItem.to) {
        breadcrumbItem.title = menuItem.propTitle;
      } else {
        breadcrumbItem.title = <a href={menuItem.to}>{menuItem.propTitle}</a>;
      }
      breadcrumbList.push(breadcrumbItem);
    });
  }, [menuProps]);

  let locations: string[] = window.location.pathname.split("/");
  locations.shift();

  let showBreadcrumb = true;

  const onCollapse = (collapseState: any) => {
    setCollapsed(collapseState);
  };

  const handleFormUpdating = (bool: any) => {
    setPageChange(false);
    formUpdatingRef.current = bool;
  };

  const handlePageChange = () => {
    return new Promise((resolve, reject) => {
      setPageChange(true);
      if (formUpdatingRef.current) {
        Emitter.on("leave", () => {
          resolve(true);
          formUpdatingRef.current = false;
          setPageChange(false);
          Emitter.remove("leave");
          Emitter.remove("stay");
        });
        Emitter.on("stay", () => {
          reject(false);
          setPageChange(false);
          Emitter.remove("leave");
          Emitter.remove("stay");
        });
      } else {
        resolve(true);
        setPageChange(false);
      }
    });
  };

  return (
    <MainContext.Consumer>
      {({ menuOption }) => {
        return (
          <HomeContext.Provider
            value={{
              handleFormUpdating: handleFormUpdating,
              handlePageChange: handlePageChange,
            }}
          >
            <Layout style={{ flexDirection: "row" }} className="site-whole">
              <MainMenu
                collapsed={isCollapsed}
                onCollapse={(collapseState: boolean) => {
                  onCollapse(collapseState);
                }}
                selectedKey={selectedKey}
                option={menuOption}
                roles={props.userInfo.userRole}
              ></MainMenu>
              <Layout className="site-layout">
                <Header className="site-header">
                  {isMobile ? (
                    <>
                      <Breadcrumb
                        items={breadcrumb}
                        style={{
                          paddingLeft: "8%",
                          width: "auto",
                          // fontSize: "16px",
                          // fontFamily: "PP Neue Machina",
                          // fontWeight: "normal",
                        }}
                      />
                    </>
                  ) : (
                    <Breadcrumb
                      items={breadcrumb}
                      style={
                        {
                          // fontSize: "16px",
                          // fontFamily: "PP Neue Machina",
                          // fontWeight: "normal",
                        }
                      }
                    />
                  )}
                </Header>
                <Content className="site-content">
                  <Spin
                    style={{ position: "fixed" }}
                    size="large"
                    tip={
                      <div
                        style={{
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></div>
                    }
                    spinning={isLoading}
                  >
                    <Outlet />
                  </Spin>
                </Content>
                {isMobile ? null : (
                  <Footer className="site-footer">
                    <span>Version {file.version}</span>
                    <Space size={15}>
                      <span>
                        &copy; Copyright 2024 Rex Technologies. All Rights
                        Reserved.
                      </span>
                      <Image
                        preview={false}
                        width="20px"
                        height="20px"
                        src={rexLogo}
                      />
                    </Space>
                  </Footer>
                )}
              </Layout>
            </Layout>
          </HomeContext.Provider>
        );
      }}
    </MainContext.Consumer>
  );
};

Home.propTypes = {
  userInfo: PropTypes.any,
  currentLocation: PropTypes.any,
  menu: PropTypes.any,
};

export default Home;
