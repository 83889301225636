import React, { useContext, useState, useRef } from "react";
import { Space, Upload, Progress, Modal, Button, Alert } from "antd";
import type { UploadProps } from "antd";
import LookupManager from "./LookupManager";
import { HomeContext } from "./Home";
import { InboxOutlined, InfoCircleFilled } from "@ant-design/icons";
import { getExcelListByField } from "../services/api-server/excel_file";
import { uploadFile } from "../services/api-server/filetransfer";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { socket } from "../utils/socket";
import { blake2b } from "blakejs";
import { permissions_access } from "../utils/_exports";

const { Dragger } = Upload;

function GenericTabContainer(props: any) {
  const role = props.params?.userRole[0];
  const context: any = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [isCoverModalVisible, setisCoverModalVisible] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkingPercent, setCheckingPercent] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [processFiles, setProcessFiles] = useState(0);
  const [isCheckingModalVisible, setIsCheckingModalVisible] = useState(false);
  const [duplicateFileModalVisible, setDuplicateFileModalVisible] =
    useState(false);
  const [tabkey, setTabKey] = useState("lookup-manager");
  const [fileToUpload, setFileToUpload]: any = useState(null);
  const [alertmsg, setAlertmsg] = useState<any>(null);
  const [filelist, setFileList]: any = useState([]);
  const [resolveDuplicate, setResolveDuplicate] = useState<
    ((response: boolean) => void) | null
  >(null);
  const [triggerUpload, setTriggerUpload] = useState(false); // Control when to trigger upload

  let timeout: NodeJS.Timeout | null = null; // Timeout to debounce
  const changeContent = () => {
    switch (tabkey) {
      case "lookup-manager":
        return (
          <LookupManager
            tabKey={"excel"}
            subTabKey={"Excel file"}
            containerTitle={""}
          />
        );
      default:
        return <></>;
    }
  };

  const handleDuplicateFileConfirmation = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (!duplicateFileModalVisible) {
        setResolveDuplicate(() => resolve); // Store the resolve function
        setDuplicateFileModalVisible(true); // Show modal
      }
    });
  };

  const updateCheckingPercent = (fileListLength: any) => {
    if (fileListLength <= 10) {
      return 3000; // 3 seconds
    } else if (fileListLength > 10 && fileListLength <= 30) {
      return 5000; // 5 seconds
    } else if (fileListLength > 30 && fileListLength < 50) {
      return 7000; // 5 seconds
    }
    return 10000; // Default for larger lists, if needed
  };

  const handleUpload = async (fileList: File[] | any) => {
    if (permissions_access?.includes("report upload raw data")) {
      if (triggerUpload) return; // Prevent re-triggering while upload is in progress
      setTriggerUpload(true);

      const duplicateFiles: any[] = []; // Store duplicate files
      const nonDuplicateFiles: any[] = []; // Store non-duplicate files

      // Step 1: Check all files for duplicates
      const processFiles = async () => {
        setIsCheckingModalVisible(true);
        setCheckingPercent(0); // Reset progress bar
        // Simulate file upload and progress
        const intervalDuration = updateCheckingPercent(fileList.length);
        for (const file of fileList) {
          const fakeUpload = setInterval(() => {
            setCheckingPercent((prev) => {
              const newPercent = prev + 0.1; // Adjust increment as needed
              if (newPercent >= 100) {
                clearInterval(fakeUpload);
                return 100; // Ensure the percentage doesn't exceed 100
              }
              return newPercent;
            });
          }, intervalDuration); // Adjust interval duration as needed
          const filename = file.name;

          // Check for duplicate files
          const find_duplicate = {
            blake_hash: hashString(filename),
          };
          const data: any = await getExcelListByField(find_duplicate);

          if (data.length > 0) {
            duplicateFiles.push(file); // Add duplicate files to the array
          } else {
            nonDuplicateFiles.push(file); // Add non-duplicate files to the array
          }
        }
        setCheckingPercent(100); // Reset progress bar
        setIsCheckingModalVisible(false);
      };

      // Step 2: Handle duplicate files
      const handleDuplicates = async () => {
        if (duplicateFiles.length > 0) {
          setFileToUpload(duplicateFiles); // Store duplicate files
          const shouldContinue = await handleDuplicateFileConfirmation(); // Wait for user confirmation

          if (!shouldContinue) {
            // User canceled; skip all duplicates
            throw new Error("User canceled duplicate file upload");
          }
        }
      };

      // Step 3: Upload files
      const uploadFiles = async () => {
        const filesToUpload = [...nonDuplicateFiles, ...duplicateFiles];
        let processedFiles = 0; // Local counter for clarity
        setisCoverModalVisible(true);
        setTotalFiles(filesToUpload.length);
        for (let file of filesToUpload) {
          setProcessFiles((prev) => prev + 1); // Increment processFiles state
          processedFiles++;
          try {
            await uploadExcelFile(file);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        }
      };

      try {
        // Execute steps in sequence
        await processFiles(); // Step 1
        await handleDuplicates(); // Step 2
        await uploadFiles(); // Step 3
        return;
      } catch (error: any) {
        console.error("Error in handleUpload:", error.message);
      } finally {
        setFileList([]);
        socket.emit(
          "excel_datatable_update",
          `Hello from React frontend! ${socket.id}`
        );
        setisCoverModalVisible(false);
        setTotalFiles(0);
        setProcessFiles(0);
        setTimeout(() => {
          setTriggerUpload(false); // Allow triggering after a delay
        }, 5000); // Prevent triggering for 5 seconds
      }
    } else {
      setAlertmsg({
        type: "warning",
        message: `No permission to upload raw data`,
        top: true,
        closeable: true,
      });
      // Auto-close the alert after 3 seconds
      setTimeout(() => {
        setAlertmsg(null); // Clear the alert message
      }, 3000);
    }
  };

  const uploadExcelFile = (file: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      const UploadformData = new FormData();
      const imgUUID = uuidv4() + "." + file.name.split(".").pop();
      UploadformData.append("file", file.originFileObj);
      UploadformData.append("filename", file.name);
      UploadformData.append("uuid", imgUUID);
      UploadformData.append("size", file.size.toString());
      UploadformData.append("upload_date", moment().toISOString());
      setLoading(true);
      setUploadPercent(0); // Reset progress bar
      setIsModalVisible(true);

      // Simulate file upload and progress
      const fakeUpload = setInterval(() => {
        setUploadPercent((prev) => {
          const newPercent = prev + 0.1;
          if (newPercent >= 100) {
            clearInterval(fakeUpload);
            return 100; // Ensure the percentage doesn't exceed 100
          }
          return newPercent;
        });
      }, 3000); // Simulate upload progress

      uploadFile(UploadformData, {
        upload_path: "../katimas_upload_excel",
      })
        .then((data: any) => {
          setLoading(false);
          setUploadPercent(100); // Upload complete
          // Wait for 5 seconds before continuing
          setTimeout(() => {
            setIsModalVisible(false); // Hide modal after 5 seconds
            setAlertmsg({
              type: "success",
              message: `${data?.data?.results?.filename} has been successfully uploaded.`,
              top: true,
              closeable: true,
            });
            // Auto-close the alert after 3 seconds
            setTimeout(() => {
              setAlertmsg(null); // Clear the alert message
            }, 3000);
            resolve(); // Resolve the promise on success
          }, 1000); // Delay for 5 seconds before hiding the modal
        })
        .catch((error) => {
          if (
            error?.response?.data?.detail === "No date part in the filename."
          ) {
            setAlertmsg({
              type: "warning",
              message: `Upload failed. Please ensure the file name includes the date in [YYYY-MM] format`,
              top: true,
              closeable: true,
            });
            setTimeout(() => {
              setAlertmsg(null); // Clear the alert message
            }, 3000);
          } else {
            setAlertmsg({
              type: "warning",
              message: `Excel upload failed. Please try again.`,
              top: true,
              closeable: true,
            });
            setTimeout(() => {
              setAlertmsg(null); // Clear the alert message
            }, 3000);
          }
          setLoading(false);
          setUploadPercent(0); // Reset on error
          setIsModalVisible(false); // Hide modal when upload finishes
          reject(error); // Reject the promise on failure
        })
        .finally(() => {});
    });
  };

  const hashString = (input: string) => {
    const inputBuffer = new TextEncoder().encode(input); // Convert string to Uint8Array
    const hashBuffer = blake2b(inputBuffer); // Generate Blake2b hash
    return Array.from(hashBuffer)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join(""); // Convert hash to hex string
  };

  const handleChange: UploadProps["onChange"] = (info: any) => {
    // Clear the previous timeout and set a new one
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      setFileList((finalList: any) => {
        handleUpload(info); // Trigger upload only once
        return info;
      });
    }, 3000); // Debounce interval (300ms)
  };

  return (
    <>
      {alertmsg && (
        <Alert
          className={
            alertmsg?.top ? "alert-message-box-top" : "alert-message-box"
          }
          type={alertmsg?.type}
          message={alertmsg?.message}
          description={alertmsg?.description}
          showIcon
          closable={alertmsg?.closeable}
          afterClose={() => setAlertmsg(null)}
          style={{
            fontFamily: "Motiva Sans",
          }}
        />
      )}
      <div className="generic-container" style={{ gap: "20px" }}>
        <Space direction="vertical">
          <span
            style={{
              fontSize: "24px",
              fontFamily: "PP Neue Machina",
              fontWeight: "bold",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {props.params.propTitle}
          </span>
          <span style={{ fontSize: "14px" }}>
            Upload your Excel files here to easily generate detailed reports.
            File names must include the date [YYYY-MM], e.g., CLI
            [2024-09].xlsx.
          </span>
        </Space>
        <Upload.Dragger
          fileList={filelist}
          name="file"
          multiple
          accept=".xlsx, .xls"
          // onChange={(info: any) => handleUpload(info.fileList)}
          customRequest={() => {
            // Override default upload behavior. Do nothing here to prevent default POST action.
          }}
          onChange={(info: any) => handleChange(info.fileList)}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // const files = Array.from(e.dataTransfer.files);
            // console.log("Dropped files:", files);
            // handleUpload(files); // Optional: Handle dropped files as a batch
          }}
          itemRender={() => null} // Hide the upload item list
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text" style={{ fontSize: "14px" }}>
            Drag file to this area to upload or{" "}
            <span style={{ color: "#7F61FF" }}>upload file</span>
          </p>
          <p className="ant-upload-hint" style={{ fontSize: "14px" }}>
            Supports both single and bulk uploads, accepting only Excel files
            (.xlsx format).
          </p>
        </Upload.Dragger>
        <Modal
          title={
            <div
              style={{
                padding: "15px",
                backgroundColor: "#2C378E",
                color: "white",
                minHeight: "25px",
                maxHeight: "25px",
              }}
            >
              File Upload
            </div>
          }
          footer={null}
          centered
          closable={false} // Disable closing during upload
          maskClosable={false} // Disable closing on outside click
          open={isCoverModalVisible}
        >
          <p
            style={{
              display: "inline",
              marginRight: "10px",
              marginLeft: "15px",
            }}
          >
            Uploading {processFiles} / {totalFiles} files in progress. Please
            wait.
          </p>
          {isModalVisible && (
            <div
              style={{
                padding: "20px",
                backgroundColor: "white",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Progress
                style={{ marginRight: "100px, 0", padding: "0 20px 0 20px" }}
                percent={parseFloat(uploadPercent.toFixed(2))}
                status={uploadPercent < 100 ? "active" : "success"}
              />
            </div>
          )}
        </Modal>

        <Modal
          footer={null}
          title={
            <div
              style={{
                padding: "15px",
                backgroundColor: "#2C378E",
                color: "white",
                minHeight: "25px",
                maxHeight: "25px",
              }}
            >
              Checking files
            </div>
          }
          open={isCheckingModalVisible}
          // onCancel={handleCancel}
          centered
          closable={false} // Disable closing during upload
          maskClosable={false} // Disable closing on outside click
        >
          <p
            style={{
              display: "inline",
              marginRight: "10px",
              marginLeft: "15px",
            }}
          >
            Checking {checkingPercent.toFixed(2)}% complete...
          </p>
          <Progress
            style={{ marginRight: "100px, 0", padding: "0 20px 0 20px" }}
            percent={parseFloat(checkingPercent.toFixed(2))}
            status={checkingPercent < 100 ? "active" : "success"}
          />
        </Modal>
        <Modal
          centered
          width={400}
          maskClosable={false}
          open={duplicateFileModalVisible}
          title={
            <div style={{ padding: "10px 0px 0px 10px" }}>
              File already exists
            </div>
          }
          closable={false}
          className="modal-report"
          footer={
            <div style={{ padding: "16px" }}>
              <Button
                key="back"
                onClick={() => {
                  if (resolveDuplicate) resolveDuplicate(false); // Resolve with 'false' for cancel
                  setDuplicateFileModalVisible(false); // Close modal
                }}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                style={{
                  color: "white",
                  borderWidth: "1px 0px 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#7f61ff",
                }}
                className="normal-login-button"
                onClick={() => {
                  if (resolveDuplicate) resolveDuplicate(true); // Resolve with 'true' for continue upload
                  setDuplicateFileModalVisible(false); // Close modal
                }}
              >
                Continue upload
              </Button>
            </div>
          }
        >
          <div style={{ display: "flex" }}>
            <InfoCircleFilled
              style={{
                color: "#FAAD14",
                paddingLeft: "10px",
                borderColor: "red",
                marginBottom: "25px",
              }}
            />
            <p
              style={{
                display: "inline",
                marginRight: "10px",
                marginLeft: "15px",
              }}
            >
              Uploading this file will replace the existing data in the
              database. Do you want to proceed with the upload?
            </p>
          </div>
        </Modal>
        {changeContent()}
      </div>
    </>
  );
}

export default GenericTabContainer;
